import React, { ReactElement } from "react";
import useFetch from "use-http";

interface Props {
  onUpload: (url: string) => void;
}

export default function EFileUpload({ onUpload }: Props): ReactElement {
  const { post } = useFetch(
    "/files?fields=private_hash&t=" + new Date().valueOf(),
    {
      cache: "no-cache",
    }
  );
  const ref = React.useRef<HTMLInputElement>(null);
  const uploadFile = async (file?: File | null) => {
    const data = new FormData();
    if (file) data.append("file", file);
    console.log(file);
    const res = await post(data);
    onUpload(`_/assets/${res.data.private_hash}`);
    if (ref.current) ref.current!.value = "";
  };
  return (
    <div>
      <div className="form-group">
        <input
          ref={ref}
          type="file"
          name="imageFile"
          onChange={(e) => {
            uploadFile(e.currentTarget.files?.item(0));
          }}
          id="imageFile"
          placeholder="Upload Image"
        />
      </div>
    </div>
  );
}
