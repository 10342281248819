import React, { ReactElement } from "react";
import {
  PencilSquare,
  Plus,
  Trash,
  TrashFill,
  XCircleFill,
} from "react-bootstrap-icons";
import useFetch from "use-http";
import AddEditModal from "../../common/components/AddEditModal";
import ETable from "../../common/components/ETable/ETable";
import { IFilter, Protector } from "../../common/models/models";
import moment from "moment";
import EditWeaponModal from "./EditWeaponModal";
import { LoadingScreen } from "./../../common/components/LoadingScreen";
import AddEditModalProtector from "./AddEditModalProtector";
import { type } from "os";

export default function ProtectorsPage(): ReactElement {
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState<Protector | undefined>();
  const [showDeleteModel, setShowDeleteModel] = React.useState(false);
  const [delItemID, setDelItemID] = React.useState<any>();
  const [delItemType, setDelItemType] =
    React.useState<"all" | "unassigned">("all");

  const { data: uData, get } =
    useFetch<{ data: Protector[]; meta: any }>(`items/protector`);
  const { data: dataAssigned, get: getAssigned } = useFetch<{
    data: any[];
    meta: any;
  }>(`items/protector_assignment`);
  const [showWeapon, setShowWeapon] = React.useState(false);
  const [selectedPA, setSelectedPA] = React.useState<any>();
  const { patch: patchPA, del: deleteAssigned } = useFetch(
    `items/protector_assignment`
  );
  const [data, setData] = React.useState<any>();
  const { data: dataFormations } = useFetch("items/formation", {}, []);
  const { data: dataRanks } = useFetch("items/rank_type?sort=priority", {}, []);
  const { data: dataHeights } = useFetch("items/height", {}, []);
  const { data: dataUnits, get: getUnits } = useFetch("items/unit");
  const { data: dataTrainings } = useFetch("items/training", {}, []);

  const filterAllSecurity : IFilter[] = [
    {
      value: "protector.name",
      label: "NAME"
    },
    {
      value: "protectee.name",
      label: "PROTECTEE NAME"
    },
    {
      value: "protector.mobile_no",
      label: "MOBILE NO"
    },
    {
      value: "protector.belt_no",
      label: "BELT NO"
    },
    {
      value: "protector.rank.title",
      label: "RANK"
    },
    {
      value: "protector.unit.title",
      label: "	UNIT"
    },
    {
      value: "deployment_type.title",
      label: "	DEPLOYED AS"
    },
  ]

  const filterUnAssignedSecurity : IFilter[] = [
    {
      value: "name",
      label: "NAME"
    },
    {
      value: "mobile_no",
      label: "MOBILE NO"
    },
    {
      value: "belt_no",
      label: "BELT NO"
    },
    {
      value: "rank.title",
      label: "RANK"
    },
    {
      value: "unit.title",
      label: "	UNIT"
    },
    {
      value: "dob",
      label: "DATE OF BIRTH"
    },
  ]
  React.useEffect(() => {
    getAssigned(
      "?fields=*.*.*&limit=10&meta=filter_count&filter[protector][nnull]&status=assigned"
    );
  }, [getAssigned]);
  React.useEffect(() => {
    get("?fields=*.*&limit=10&meta=filter_count&filter[status]=unassigned");
  }, [get]);
  React.useEffect(() => {
    if (uData) {
      setData(uData?.data)
      // setData(
      //   uData.data.flatMap((d) => {
      //     d.dob = moment(d.dob, "YYYY-MM-DD").format("DD-MM-YYYY");
      //     return d;
      //   })
      // );
    }
  }, [uData, setData]);
  const [selectedTab, setSelectedTab] = React.useState<0 | 1>(0);
  const { post } = useFetch("items/protector");
  const { patch, del } = useFetch("items/protector");
  const { del: delTrainings, get: getTrainings } = useFetch(
    "items/protector_training"
  );

  if (uData) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Security Personnel</h1>
          </div>
          <div>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setSelected(undefined);
                getUnits(`?filter[formation]=${"resetUnit"}`);
                setShow(true);
              }}
            >
              <Plus /> Add Personnel
            </button>
          </div>
        </div>
        <div className="main-body">
          {/* TABS */}
          <div>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  role="button"
                  onClick={() => setSelectedTab(0)}
                  className={"nav-link " + (selectedTab === 0 ? "active" : "")}
                >
                  All
                </a>
              </li>
              <li className="nav-item">
                <a
                  role="button"
                  onClick={() => setSelectedTab(1)}
                  className={"nav-link " + (selectedTab === 1 ? "active" : "")}
                >
                  Un-Assigned
                </a>
              </li>
            </ul>
          </div>

          {selectedTab === 1 && (

            <div className="card h-auto">
              <div className="card-body">

                <ETable<Protector>
                  columns={[
                    { key: "id", label: "Id" },
                    { key: "name", label: "Name" },
                    { key: "mobile_no", label: "Mobile No" },
                    { key: "dob", label: "Date of Birth" },
                    { key: "belt_no", label: "Belt No" },
                    { key: "rank.title", label: "Rank" },
                    { key: "unit.title", label: "Unit" },
                  ]}
                  selectedby={filterUnAssignedSecurity}
                  onChange={(query, sortKey, isSortAsc, page, status, selectKeyRow) => {
                    let v = `?fields=*.*&offset=${(page - 1) * 10
                      }&limit=10&meta=filter_count&filter[status]=unassigned`;
                    if (query.length > 0) {
                      v += `&filter[${selectKeyRow}][like]=${query}&offset=${(page - 1) * 10
                        }`;
                    }
                    if (sortKey) {
                      v += `&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                    }
                    get(v);
                  }}
                  totalPages={Math.ceil((uData?.meta?.filter_count || 10) / 10)}
                  items={uData?.data || []}
                  actionButtons={[
                    {
                      title: "",
                      icon: <PencilSquare size={16} />,
                      type: "",
                      onClick: (item) => {
                        setSelected(item);
                        getUnits(`?filter[formation]=${item.unit.formation}`);
                        setShow(true);
                      },
                    },
                    {
                      title: "",
                      icon: <TrashFill size={16} />,
                      type: "danger",
                      onClick: async (item) => {
                        setShowDeleteModel(true);
                        setDelItemType("unassigned");
                        setDelItemID(item?.id?.toString());
                      },
                    },
                  ]}

                />
              </div>
            </div>

          )}

          {selectedTab === 0 && (
            <div className="card h-auto">
              <div className="card-body">
                <ETable<any>

                  columns={[
                    { key: "protector.id", label: "Id" },
                    { key: "protector.name", label: "Name" },
                    { key: "protectee.name", label: "Protectee Name" },
                    { key: "protector.mobile_no", label: "Mobile No" },
                    // { key: "protectee.category.title", label: "Basis" },
                    { key: "protector.belt_no", label: "Belt No" },
                    { key: "protector.rank.title", label: "Rank" },
                    { key: "deployment_type.title", label: "Deployed As" },
                    { key: "protector.unit.title", label: "Unit" },
                  ]}
                  selectedby ={ filterAllSecurity }
                  status={[
                    "assigned",
                    "assignment pending",
                    "assignment request",
                    "withdrawal request",
                    "withdrawal pending",
                  ]}
                  // customColumns={[
                  //   {
                  //     heading: "Status",
                  //     renderer: (item) => (
                  //       <span className="badge text-uppercase badge-primary">
                  //         {item.status === "assignment pending"
                  //           ? "assignment pending (" +
                  //             (item.unit_restriction?.title ||
                  //               item.formation_restriction?.title) +
                  //             ")"
                  //           : item.status}
                  //       </span>
                  //     ),
                  //   },
                  //   {
                  //     heading: "Updated On",
                  //     renderer: (item) =>
                  //       item.updated_on && (
                  //         <span>
                  //           {moment(item.updated_on).format(
                  //             "DD-MM-YYYY @ HH:mm"
                  //           )}
                  //         </span>
                  //       ),
                  //   },
                  // ]}
                  onChange={(query, sortKey, isSortAsc, page, status, selectKeyRow, ) => {
                    let v = `?filter[protector.id][neq]=null&fields=*.*.*&offset=${(page - 1) * 10
                      }&limit=10&meta=filter_count`;
                    if (query.length > 0) {
                      v += `&filter[protector.id][neq]=null&filter[${selectKeyRow}][like]=${query}&offset=${
                        (page - 1) * 10
                        }`;          
                      // v += `&q=${query}&offset=${
                      //   (page - 1) * 10
                      // }`;
                    }
                  

                    if (sortKey) {
                      v += `&filter[protector.id][neq]=null&&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                    }
                    if (status) {
                      v += `&filter[protector.id][neq]=null&&filter[status][in]=${status}`;
                    }
                    getAssigned(v);
                  }}
                  totalPages={Math.ceil(
                    (dataAssigned?.meta?.filter_count || 10) / 10
                  )}
                  items={dataAssigned?.data || []}
                  actionButtons={(item) => {
                    if (item.status === "assigned")
                      // return [
                      //   {
                      //     title: "",
                      //     icon: <PencilSquare size={16} />,
                      //     onClick: (item) => {
                      //       setSelectedPA({ ...item });
                      //       setShowWeapon(true);
                      //     },
                      //   },
                      // ];
                      return [
                        {
                          title: "",
                          icon: <PencilSquare size={16} />,
                          onClick: (item) => {
                            setSelected(item.protector);
                            getUnits(
                              `?filter[formation]=${item.protector.unit.formation}`
                            );
                            setShow(true);
                          },
                        },
                      ];
                    else if (item.status !== "assignment pending")
                      return [
                        {
                          title: "",
                          icon: <PencilSquare size={16} />,
                          type: "",
                          onClick: (item) => {
                            setSelected(item.protector);
                            getUnits(
                              `?filter[formation]=${item.protector.unit.formation}`
                            );
                            setShow(true);
                          },
                        },
                        {
                          title: "",
                          icon: <TrashFill size={16} />,
                          type: "danger",
                          onClick: async (item) => {
                            setShowDeleteModel(true);
                            setDelItemID(item?.id?.toString());
                            setDelItemType("all");
                          },
                        },
                      ];
                    else return [];
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {showWeapon && (
          <EditWeaponModal
            item={selectedPA}
            onClose={() => setShowWeapon(false)}
            onSave={async (item) => {
              if (item.weapon_type?.id && item.no_of_rounds)
                await patchPA(item.id.toString(), {
                  weapon_type: item.weapon_type.id,
                  no_of_rounds: item.no_of_rounds,
                });
              window.location.reload();
            }}
          />
        )}
        {show && (
          <AddEditModalProtector<Protector>
            defaultItem={selected}
            onClose={() => {
              setShow(false);
            }}
            title="Security Personnel"
            fields={[
              {
                key: "name",
                label: "Name",
                type: "text",
              },
              {
                key: "belt_no",
                label: "Id No. (RMC/Belt No)",
                type: "text",
              },

              {
                key: "mobile_no",
                label: "Mobile No",
                type: "text",
              },
              {
                key: "dob",
                label: "Date of Birth",
                type: "date",
                inputOptions: {
                  defaultValue: moment(selected?.dob, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  ),
                },
              },
              {
                key: "blood_group",
                label: "Blood Group",
                type: "text",
              },
              {
                key: "formation",
                label: "Formation",
                required: true,
                selectItems: {
                  items: dataFormations?.data || [],
                  labelKey: "title",
                },
                onChange: (v) => {
                  getUnits(`?filter[formation]=${v}`);
                },
                type: "select",
                isDummy: true,
              },
              {
                key: "unit",
                label: "Unit/District",
                selectItems: {
                  items: dataUnits?.data || [],
                  labelKey: "title",
                },
                type: "custom-select",
                customSelectOptions: {
                  filterKey: "title",
                  renderItem: (item) => <p>{item.title}</p>,
                },
              },
              {
                key: "rank",
                label: "Rank",
                selectItems: {
                  items: dataRanks?.data || [],
                  labelKey: "title",
                },
                type: "select",
              },
              {
                key: "height",
                label: "Height",
                selectItems: {
                  items: dataHeights?.data || [],
                  labelKey: "title",
                },
                type: "select",
              },
              {
                key: "annual_medical",
                label: "Annual Medical",
                type: "checkbox",
              },
              {
                type: "multiple-checkboxes",
                key: "trainings",
                label: "Trainings",
                multipleCheckboxesOptions: {
                  labelKey: "title",
                  items: dataTrainings?.data || [],
                  defaultSelected:
                    selected?.trainings?.flatMap((a) =>
                      dataTrainings.data.find((t) => t.id === a.training)
                    ) || [],
                },
              },
            ]}
            onSubmit={async (protector, isEdit) => {
              if (isEdit) {
                const {
                  id,
                  created_on,
                  rank,
                  unit,
                  height,
                  trainings,
                  dob,
                  ...p
                } = protector;
                const d = {
                  ...p,
                  dob: moment(dob).format("YYYY-MM-DD"),
                  rank: rank?.id,
                  unit: unit?.id,
                  trainings: trainings?.flatMap((a) => {
                    return { training: { id: a.id } };
                  }),
                  height: height?.id,
                };
                const r = await getTrainings(
                  `?filter[protector]=${id}&fields=id`
                );
                const promises: Promise<any>[] = [];
                r?.data.forEach((a) => {
                  promises.push(delTrainings(a.id.toString()));
                });
                await Promise.all(promises);
                await patch(`${id}?fields=*.*.*.*`, d);
              } else {
                await post({
                  ...protector,
                  trainings: protector.trainings?.flatMap((a) => {
                    return { training: { id: a.id } };
                  }),
                });
              }
              window.location.reload();
            }}
          />
        )}
        {/* Delete Confirmation Model */}
        {showDeleteModel && (
          <>
            <div className="modal-backdrop"></div>
            <div
              className="modal"
              id="modelId"
              tabIndex={-1}
              role="dialog"
              style={{ display: "block" }}
              aria-labelledby="modelTitleId"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-scrollable modal-sm"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm Delete !!</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => setShowDeleteModel(false)}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to delete?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-muted"
                      onClick={() => setShowDeleteModel(false)}
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-muted"
                      onClick={async () => {
                        if (delItemType === "all") {
                          await deleteAssigned(delItemID);
                        } else {
                          await del(delItemID);
                        }
                        window.location.reload();
                      }}
                      data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
