import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import useFetch from "use-http";
import html2pdf from "html2pdf.js";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
interface Props {}

const border = {
  border: "2px solid #999",
};
// const calcTotalSecurity = (org) => {
//   let keys = Object.keys(org);
//   return keys?.reduce(
//     (acc, key) => (key !== "org_name" ? acc + org[key] : acc),
//     0
//   );
// };
const groupByKey = (list, key) => {
  return (
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    ) || {}
  );
};
const CategoryReport = (props: Props) => {
  const fontSm = {
    fontSize: "0.7rem",
  };
  const [data, setData] = useState([]);
  const categoryReportRef = useRef<any>();
  const { get, loading } = useFetch(`custom/reports/groupByFormation`);
  console.log(data);
  useEffect(() => {
    get().then((res) => setData(groupByKey(res?.data, "Category")));
  }, []);

  const preferenceRef = useRef<HTMLDivElement | null>(null);
  const saveToPDF = () => {
    var opt = {
      margin: 0,
      filename: "Category_Report.pdf",
      image: { type: "jpeg", quality: 1 },
      jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
    };
    html2pdf(categoryReportRef?.current, opt);
  };

  return (
    <React.Fragment>
      <ReactToPrint
        removeAfterPrint={true}
        trigger={() => (
          <button className="ml-4 btn btn-secondary" disabled={loading}>
            Print
          </button>
        )}
        content={() => categoryReportRef.current}
      />
      <button
        className="ml-4 btn btn-secondary"
        disabled={loading}
        onClick={() => saveToPDF()}
      >
        Download as Pdf
      </button>
      {loading ? (
        <LoadingScreen text="Loading Report..." />
      ) : (
        <div
          className="bg-white"
          style={{ marginLeft: "2.5em", marginTop: "2.5em" }}
          ref={categoryReportRef}
        >
          <h3 className="text-center my-2">Category wise report</h3>
          <div
            ref={preferenceRef}
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              marginLeft: "5%",
            }}
          >
            <table
              className="table table-sm"
              style={{ fontSize: "0.8em", fontWeight: 600 }}
            >
              <thead style={{}}>
                <tr className="text-bold h5" style={border}>
                  <td>S.No.</td>
                  <td style={{ width: "10%" }}>Category</td>
                  <tr className="row mx-auto" style={{ padding: "10px" }}>
                    <td className="col-3" style={fontSm}>
                      Organization
                    </td>
                    <td className="col-1" style={fontSm}>
                      Total Protectees
                    </td>
                    <td className="col-1" style={fontSm}>
                      Total Deployed
                    </td>
                    <td className="col-1" style={fontSm}>
                      By District
                    </td>
                    <td className="col-1" style={fontSm}>
                      By CDO
                    </td>
                    <td className="col-1" style={fontSm}>
                      By Armed
                    </td>
                    <td className="col-1" style={fontSm}>
                      PHGs
                    </td>
                    <td className="col-1" style={fontSm}>
                      By Center
                    </td>
                    <td className="col-2" style={fontSm}>
                      Vehicles
                    </td>
                  </tr>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data)?.map((each, index) => (
                  <>
                    <tr className="m-4" style={border}>
                      <td style={{ borderRight: "2px solid #999" }}>
                        {index + 1}
                      </td>
                      <td style={{ width: "10%" }}>{each}</td>
                      {data[each]?.map((org, idx) => (
                        <React.Fragment key={idx}>
                          <tr
                            className="row mx-auto"
                            style={
                              idx > 0
                                ? {
                                    borderLeft: "2px solid #999",
                                    borderTop: "2px solid #999",
                                  }
                                : {
                                    borderLeft: "2px solid #999",
                                  }
                            }
                          >
                            <td className="col-3">{org.Organisation}</td>
                            <td className="col-1">{org["Total Protectees"]}</td>
                            <td className="col-1">{org["Total Deployed"]}</td>
                            <td className="col-1">
                              {org["By Police District"]}
                            </td>
                            <td className="col-1">{org["By CDO"]}</td>
                            <td className="col-1">{org["By Armed"]}</td>
                            <td className="col-1">{org.PHGs}</td>
                            <td className="col-1">
                              {org["Center Deployment"]}
                            </td>
                            <td className="col-2">{org.Vehicles}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tr>
                    <tr className="text-bold" style={border}>
                      <td>Total</td>
                      <td></td>
                      <tr className="row mx-auto">
                        <td className="col-3"></td>
                        <td className="col-1">
                          {data[each].reduce(
                            (acc, { "Total Protectees": t }) => acc + Number(t),
                            0
                          )}
                        </td>
                        <td className="col-1">
                          {data[each].reduce(
                            (acc, { "Total Deployed": t }) => acc + Number(t),
                            0
                          )}
                        </td>
                        <td className="col-1">
                          {data[each].reduce(
                            (acc, { "By Police District": t }) =>
                              acc + Number(t),
                            0
                          )}
                        </td>
                        <td className="col-1">
                          {data[each].reduce(
                            (acc, { "By CDO": t }) => acc + Number(t),
                            0
                          )}
                        </td>
                        <td className="col-1">
                          {data[each].reduce(
                            (acc, { "By Armed": t }) => acc + Number(t),
                            0
                          )}
                        </td>
                        <td className="col-1">
                          {data[each].reduce(
                            (acc, { PHGs }) => acc + Number(PHGs),
                            0
                          )}
                        </td>
                        <td className="col-1">
                          {data[each].reduce(
                            (acc, { "Center Deployment": t }) =>
                              acc + Number(t),
                            0
                          )}
                        </td>
                        <td className="col-2">
                          {data[each].reduce(
                            (acc, { Vehicles }) => acc + Number(Vehicles),
                            0
                          )}
                        </td>
                      </tr>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CategoryReport;
