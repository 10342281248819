import React, { useRef, useState } from "react";
import ProtecteeReport from "./components/ProtecteeReport";
import CategoryReport from "./components/CategoryReport";
import PersonnelReportByRank from "./components/PersonnelReportByRank";
import ReactToPrint from "react-to-print";
import {
  ClipboardData,
  Diagram2Fill,
  FilePerson,
  PersonBoundingBox,
  PieChart,
  PieChartFill,
} from "react-bootstrap-icons";
import { Category } from "./../../common/models/models";
import PersonnelReport from "./components/PersonnelReport";

interface Props {}

const ReportsView = (props: Props) => {
  const [active, setActive] = useState<number>(-1);
  return (
    <React.Fragment>
      <div className="p-4 d-flex justify-content-around">
        <div
          className={`card card--report  ${
            active === 0 ? "border-secondary shadow-lg" : ""
          }`}
          style={{ width: "23%" }}
          onClick={() => setActive(0)}
        >
          <div className="card-body">
            <div className="icon">
              <FilePerson />
            </div>
            <div className="label">Protectee Report</div>
          </div>
        </div>
        <div
          className={`card card--report ${
            active === 1 ? "border-secondary shadow-lg" : ""
          }`}
          style={{ width: "23%" }}
          onClick={() => setActive(1)}
        >
          <div className="card-body">
            <div className="icon">
              <ClipboardData />
            </div>
            <div className="label">Category Report</div>
          </div>
        </div>
        <div
          className={`card card--report ${
            active === 2 ? "border-secondary shadow-lg" : ""
          }`}
          style={{ width: "23%" }}
          onClick={() => setActive(2)}
        >
          <div className="card-body">
            <div className="icon">
              <PieChartFill />
            </div>
            <div className="label">Category Report II</div>
          </div>
        </div>
        <div
          className={`card card--report ${
            active === 3 ? "border-secondary shadow-lg" : ""
          }`}
          style={{ width: "23%" }}
          onClick={() => setActive(3)}
        >
          <div className="card-body">
            <div className="icon">
              <PersonBoundingBox />
            </div>
            <div className="label">Personnel Report</div>
          </div>
        </div>
      </div>
      <div className="">
        {active === 0 ? (
          <ProtecteeReport />
        ) : active === 1 ? (
          <CategoryReport />
        ) : active === 2 ? (
          <PersonnelReportByRank />
        ) : active === 3 ? (
          <PersonnelReport />
        ) : (
          <p className="w-100 text-center text-bold border-top">
            Please Select a report above
          </p>
        )}
      </div>
    </React.Fragment>
  );
};

export default ReportsView;
