import React, { ReactElement } from "react";

interface Props {
  onSave: (orderDate, orderNo) => void;
  onClose: () => void;
  isWithdrawal?: boolean;
}

export default function ApproveModal({ onSave, onClose, isWithdrawal=false }: Props): ReactElement {
  const [orderNo, setOrderNo] = React.useState<string>("");
  const [orderDate, setOrderDate] = React.useState<string>("");
  return (
    <div>
        <div className="modal-backdrop"></div>
      <div
        className="modal"
        style={{ display: "block" }}
        id="modelId"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isWithdrawal?'Approve Withdrawal':'Approve Assignment'}</h5>
              <button
                type="button"
                onClick={() => onClose()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="orderNo">Order No</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setOrderNo(e.target.value)}
                  name="orderNo"
                  id="orderNo"
                  placeholder="Order No"
                />
              </div>
              <div className="form-group">
                <label htmlFor="orderDate">Order Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setOrderDate(e.target.value)}
                  name="orderDate"
                  id="orderDate"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-muted"
                onClick={() => onClose()}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  onSave(orderDate, orderNo);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
