import React, { ReactElement, useState } from "react";
import useFetch from "use-http";
import { ESelect } from "../../../common/library";

interface Props {
  onClose: () => void;
  onSave: ({ noOfAssignments, orderNo, orderDate, deploymentType }) => void;
}

export default function NewAssignModal({
  onClose,
  onSave,
}: Props): ReactElement {
  const [noOfPersonnels, setNoOfPersonnels] = useState<string>("");
  const [deploymentType, setDeploymentType] = useState<any>();
  const [orderNo, setOrderNo] = useState<string>("");
  const [orderDate, setOrderDate] = useState<string>("");
  const { data } = useFetch(`items/deployment_type`, {}, []);
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        style={{ display: "block" }}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Security Personnals</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => onClose()}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ESelect<any>
                filterKey="title"
                items={data?.data || []}
                label="Deployment Type"
                renderItem={(item)=><div>{item.title}</div>}
                onValuesChange={(items) => {
                  if (items[0]?.id) {
                    setDeploymentType(items[0]);
                  }
                }}
              />
              <div className="form-group">
                <label htmlFor="noOfPersonnels">No of Personnels</label>
                <input
                  type="number"
                  className="form-control"
                  name="noOfPersonnels"
                  onChange={(e) => setNoOfPersonnels(e.target.value)}
                  id="noOfPersonnels"
                  aria-describedby="helpId"
                  placeholder="No of personnels"
                />
              </div>
              <div className="form-group">
                <label htmlFor="orderno">Order No.</label>
                <input
                  type="text"
                  onChange={(e) => setOrderNo(e.target.value)}
                  className="form-control"
                  id="orderno"
                  placeholder="Order No"
                />
              </div>
              <div className="form-group">
                <label htmlFor="orderdate">Order Date</label>
                <input
                  type="date"
                  onChange={(e) => setOrderDate(e.target.value)}
                  className="form-control"
                  id="orderdate"
                  placeholder="orderdate"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => onClose()}
                type="button"
                className="btn btn-muted"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => {
                  onSave({
                    noOfAssignments: noOfPersonnels,
                    orderNo: orderNo,
                    orderDate: orderDate,
                    deploymentType
                  });
                }}
                type="button"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
