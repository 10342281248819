import "../../scss/style.scss";
import logo from "../../assets/images/logo.png";

interface props {
  text?: string;
}
export const LoadingScreen = ({ text }: props) => {
  return (
    <div>
      <div className="loading-screen">
        <img src={logo} alt="Punjab Police"></img>
      </div>
      {text && <p className="w-100 mt-4 text-center text-bold">{text}</p>}
    </div>
  );
};
