import React, { ReactElement } from "react";
import { Trash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import { API_URL } from "../../..";
import EFileUpload from "../../../common/components/EFileUpload/EFileUpload";

interface Props {
  onClose: () => void;
}

export default function CommentAddModal({ onClose }: Props): ReactElement {
  const protectee = useSelector((state: any) => state.protectee);
  const [attachment_url, setAttachment_url] = React.useState<string>();
  const { post } = useFetch(`items/comment?fields=*.*`);
  const [comment, setComment] = React.useState("");
  const [type, setType] = React.useState("");
  return (
    <>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        tabIndex={-1}
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Comment</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => onClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Type</label>
                  <input
                    type="text"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="form-control"
                    id=""
                    placeholder="Cyber"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Comments</label>
                  <textarea
                    className="form-control"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    id=""
                    style={{ height: 128 }}
                    placeholder="Comments"
                  ></textarea>
                </div>
                {/* ATTACHMENTS */}
                {attachment_url ? (
                  <div className="mt-5">
                    <h5 className="text-semibold text-faded">Attachment</h5>
                    <a href={API_URL+attachment_url} target="_blank" rel="noreferrer">{attachment_url}</a>
                  </div>
                ) : (
                  <EFileUpload onUpload={(url)=>setAttachment_url(url)} />
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-muted"
                data-dismiss="modal"
                onClick={() => onClose()}
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  await post({
                    protectee: protectee.id,
                    type: type,
                    comment: comment,
                    attachment_url: attachment_url
                  });
                  onClose();
                  window.location.reload();
                }}
                type="button"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
