import React, { ReactElement, useState } from "react";
import { ArrowRight, ArrowRightShort, Circle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "use-http";
import Assign from "./components/assign";
import Personal from "./components/personal";
import Security from "./components/security";
import Steps from "./components/steps";
import TPR from "./components/tpr";
import moment from "moment";
import { protecteeSlice } from "../../reducers/protectee";
import { PATHS } from "../../reducers/routes";
import { useParams } from "react-router-dom";
import { LoadingScreen } from "../../common/components/LoadingScreen";

const labels = [
  "Personal Details",
  "Security Information",
  "Assign Personnel",
  "Threat Assessment",
];
export default function ProtecteePage(): ReactElement {
  const [loaded, setLoaded] = useState(false);
  const protectee = useSelector((state: any) => state.protectee);
  const { id } = useParams<{ id: string }>();
  const { post, get, loading: loadingProtectee } = useFetch("/items/protectee");
  const [disabled, setdisabled] = React.useState(false);
  const {
    post: postAddress,
    get: getAddress,
    del: delAddress,
    loading: loadingAddress,
  } = useFetch("/items/address");
  const {
    post: postVehicle,
    get: getVehicle,
    del: delVehicle,
    loading: loadingVehicle,
  } = useFetch("/items/vehicle_assignment");
  const {
    post: postAssignment,
    get: getAssignment,
    loading: loadingProtector,
  } = useFetch("/items/protector_assignment");
  const { patch } = useFetch("/items/protectee");
  const dispatch = useDispatch();
  React.useEffect(() => {
    async function initialize() {
      let res = await get(id + "?fields=*.*");
      let resAddress = await getAddress(`?fields=*.*&filter[protectee]=${id}`);
      let resVehicle = await getVehicle(`?fields=*.*&filter[protectee]=${id}`);
      let resAssignment = await getAssignment(
        `?filter[protectee]=${id}&filter[status][nin]=withdrawn`
      );
      console.log("From reducer ", resAssignment.data);
      const d = {};
      resAssignment.data.forEach((pa) => {
        if (pa.status !== "unassigned") {
          if (!d[pa.deployment_type]) {
            d[pa.deployment_type] = 1;
          } else {
            d[pa.deployment_type] += 1;
          }
        }
      });
      let step = Number(localStorage.getItem("currStep")) || 0;
      if (res.data.status === "personal") {
        step = 1;
      } else if (res.data.status === "security") {
        step = 2;
      } else if (res.data.status === "assignment") {
        step = 3;
      }
      const initialState = {
        id: id,
        currentStep: step,
        status: res.data.status,
        isAssigned: false,
        isAdding: false,
        personal: {
          full_name: res.data.name,
          category: res.data.designation,
          organisation: res.data.organisation,
          type: res.data.category,
          subType: res.data.sub_category,
          review_after_in_months: res.data.review_after_in_months,
          high_value_target: res.data.high_value_target,
          photo: res.data.photo,
          district: res.data.district,
          relation_protectee: res.data.relation_protectee,
          relation: res.data.relation,
          text: res.data.text,
          norms: res.data.norms,
        },
        security: {
          addresses: resAddress.data,
          vehicles: resVehicle.data.map((a) => {
            return { ...a.vehicle, limit_in_ltrs: a.limit_in_ltrs };
          }),
          area_security: res.data.area_security,
          area_security_district: res.data.area_security_district,
          contact: {
            phone: res.data.phone,
            email: res.data.email,
            mobile: res.data.mobile,
          },
          personnel_allocation: d,
        },
      };
      dispatch(protecteeSlice.actions.setFullState(initialState));
      setLoaded(true);
    }
    if (id) {
      initialize();
    } else {
      if (!protectee.isAdding) {
        dispatch(protecteeSlice.actions.setInitialState());
      }
      setLoaded(true);
    }
  }, [
    id,
    getVehicle,
    getAddress,
    get,
    dispatch,
    getAssignment,
    protectee.isAdding,
  ]);
  async function submitForm() {
    switch (protectee.currentStep) {
      case 0:
        const data = {
          name: protectee.personal.full_name,
          photo: protectee.personal.photo || "/_/assets/bj97i4dynqgo88g0",
          designation: protectee.personal.category?.id,
          organisation: protectee.personal.organisation?.id,
          category: protectee.personal.type?.id,
          sub_category: protectee.personal.subType?.id,
          review_after_in_months: protectee.personal.review_after_in_months,
          status: "personal",
          relation_protectee: protectee.personal.relation_protectee?.id,
          relation: protectee.personal.relation,
          text: protectee.personal.text,
          norms: protectee.personal.norms,
          district: protectee.personal.district?.id,
          high_value_target: protectee.personal.high_value_target,
        };
        if (data.review_after_in_months > 0) {
          data["review_date"] = moment()
            .add(protectee.personal.review_after_in_months, "months")
            .toISOString();
        }
        let res;
        if (!id) {
          res = await post(data);
          dispatch(protecteeSlice.actions.setIsAdding(true));
          dispatch(protecteeSlice.actions.setStatus("personal"));
        } else {
          res = await patch(id, data);
        }
        dispatch(protecteeSlice.actions.setId(res.data.id));
        break;
      case 1:
        if (!protectee.isAssigned) {
          let promises: Promise<any>[] = [];
          // let resAddress = await getAddress(
          //   `?fields=id&filter[protectee]=${id}`
          // );
          // let resVehicle = await getVehicle(
          //   `?fields=id&filter[protectee]=${id}`
          // );
          // resAddress?.data.forEach((a) =>
          //   promises.push(delAddress(a.id.toString()))
          // );
          // resVehicle?.data.forEach((a) =>
          //   promises.push(delVehicle(a.id.toString()))
          // );
          await Promise.all(promises);
          promises = [];
          promises.push(
            patch(protectee.id.toString(), {
              email: protectee.security.contact.email,
              phone: protectee.security.contact.phone,
              mobile: protectee.security.contact.mobile,
              status: "security",
            })
          );
          // for (
          //   let index = 0;
          //   index < protectee.security.addresses.length;
          //   index++
          // ) {
          //   const element = protectee.security.addresses[index];
          //   promises.push(
          //     postAddress({
          //       address_type: element.type,
          //       address: element.address,
          //       protectee: protectee.id,
          //     })
          //   );
          // }
          // for (
          //   let index = 0;
          //   index < protectee.security.vehicles.length;
          //   index++
          // ) {
          //   const element = protectee.security.vehicles[index];
          //   promises.push(
          //     postVehicle({
          //       vehicle: element.id,
          //       protectee: protectee.id,
          //       limit_in_ltrs: element.limit_in_ltrs,
          //     })
          //   );
          // }
          if (protectee.security.area_security) {
            promises.push(
              patch(protectee.id.toString(), {
                area_security: true,
                area_security_district:
                  protectee.security.area_security_district?.id,
              })
            );
          } else {
            const keys = Object.keys(protectee.security.personnel_allocation);
            if (keys.length === 0) {
              for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                const value =
                  protectee.security.personnel_allocation[keys[index]];
                for (let j = 0; j < value; j++) {
                  promises.push(
                    postAssignment({
                      protectee: protectee.id,
                      deployment_type: key,
                      status: "unassigned",
                    })
                  );
                }
              }
            }
          }
          await Promise.all(promises);
          dispatch(protecteeSlice.actions.setIsAssigned(true));
          if (protectee.status === "personal") {
            dispatch(protecteeSlice.actions.setStatus("security"));
          }
        }
        break;
      case 2:
        await patch(protectee.id.toString(), {
          status: "assignment",
        });
        if (
          protectee.status === "personal" ||
          protectee.status === "security"
        ) {
          dispatch(protecteeSlice.actions.setStatus("assignment"));
        }
        break;
      case 3:
        await patch(protectee.id.toString(), {
          status: "tpr",
        });
        if (
          protectee.status === "personal" ||
          protectee.status === "security" ||
          protectee.status === "assignment"
        ) {
          dispatch(protecteeSlice.actions.setStatus("tpr"));
        }
        break;
    }
    setdisabled(false);
    dispatch(protecteeSlice.actions.setCurrentStep(protectee.currentStep + 1));
    localStorage.setItem("currStep", protectee.currentStep + 1);
  }
  if (
    !loadingProtectee &&
    !loadingProtector &&
    !loadingAddress &&
    !loadingVehicle
  ) {
    return (
      <React.Fragment>
        <div className="main-header">
          <div>
            <h1 className="mb-0">Manage Protectee</h1>
            {/* <p className="text-faded">
            Please fill in the protectee details below
          </p> */}
          </div>
        </div>
        <div className="main-body">
          {loaded && (
            <Steps
              labels={labels}
              canSkip={Boolean(id)}
              contents={[<Personal />, <Security />, <Assign />, <TPR />]}
            />
          )}{" "}
        </div>
        <div className="main-footer">
          <div className="w-100">
            <div className="row">
              <div className="col-lg-6">
                {/* <div className="summary">
                <p className="small text-uppercase text-bold text-faded mb-0">
                  Summary
                </p>
                <p className="mb-0 text-bold">Summary Details here</p>
              </div> */}
              </div>
              <div className="col-lg-6 text-lg-right">
                {/* {currentStep !== 0 && (
                <button
                  className="btn btn-light mr-md-2"
                  onClick={() => setCurrentStep((a) => a - 1)}
                >
                  Go Back
                </button>
              )} */}
                {protectee.currentStep !== labels.length - 1 && (
                  <button
                    onClick={async () => {
                      if (
                        protectee.currentStep === 0 &&
                        (!protectee.personal.type ||
                          !protectee.personal.full_name ||
                          !protectee.personal.category ||
                          !protectee.personal.organisation ||
                          !protectee.personal.district)
                      ) {
                        alert("Please Fill all the required fields");
                      } else {
                        setdisabled(true);
                        await submitForm();
                      }
                    }}
                    className="btn btn-secondary"
                    disabled={disabled}
                  >
                    Next Step <ArrowRightShort />
                  </button>
                )}
                {protectee.currentStep === labels.length - 1 && (
                  <button
                    onClick={async () => {
                      await patch(protectee.id.toString(), {
                        status: "complete",
                        last_review_date: moment().format("YYYY-MM-DD"),
                      });

                      dispatch(protecteeSlice.actions.setInitialState());
                      window.location.href = PATHS.protectees;
                    }}
                    className="btn btn-secondary"
                  >
                    Finish
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
