import React, { ReactElement } from "react";
import { PencilSquare } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import AddEditModal from "../../../common/components/AddEditModal";
import ETable from "../../../common/components/ETable/ETable";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import { Protector } from "../../../common/models/models";
import EditWeaponModal from "../../protectors/EditWeaponModal";

export default function PersonnelAssignment(): ReactElement {
  const userDetail = useSelector((state: any) => state.user.userDetail);
  const [show, setShow] = React.useState(false);
  const { patch } = useFetch(`items/protector_assignment`);
  const { patch: assignProtector } = useFetch(`items/protector`);
  const { data: dataProtectors, get: getProtectors } = useFetch(
    `items/protector`
  );
  const { data: dataWeapons } = useFetch(`items/weapon_type`, {}, []);
  const [showWeapon, setShowWeapon] = React.useState(false);
  const [selectedPA, setSelectedPA] = React.useState<any>();
  const { patch: patchPA } = useFetch(`items/protector_assignment`);

  const [selected, setSelected] = React.useState<Protector | undefined>();
  const { get, data } = useFetch(`items/protector_assignment`);
  React.useEffect(() => {
    let url = `?fields=*.*.*&limit=10&meta=filter_count&filter[unit.formation]=${userDetail.formation.id}&filter[status]=unassigned`;
    if (userDetail.unit) {
      url = `?fields=*.*.*&limit=10&meta=filter_count&filter[unit]=${userDetail.unit.id}&filter[status]=unassigned`;
    }
    getProtectors(url);
  }, [getProtectors, userDetail]);

  React.useEffect(() => {
    let url = `?fields=*.*.*&limit=10&meta=filter_count&filter[formation_restriction]=${userDetail.formation.id}&filter[status]=assignment pending,withdrawal pending&filter[is_temporary]=0`;
    if (userDetail.unit) {
      url = `?fields=*.*.*&limit=10&meta=filter_count&filter[unit_restriction]=${userDetail.unit.id}&filter[status][in]=assignment pending,withdrawal pending&filter[is_temporary]=0`;
    }
    get(url);
  }, [get, userDetail]);
  if (dataProtectors) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Security Personnel Assignment</h1>
          </div>
        </div>
        <div className="main-body">
          <div className="card h-auto">
            <div className="card-body">
              <ETable<any>
                status={["assignment pending", "withdrawal pending"]}
                columns={[
                  { key: "id", label: "Id" },
                  { key: "protectee.name", label: "Protectee Name" },
                  { key: "protectee.designation.title", label: "Category" },
                  {
                    key: "protectee.organisation.title",
                    label: "Organisation",
                  },
                  { key: "deployment_type.title", label: "Deployed As" },
                ]}
                onChange={(query, sortKey, isSortAsc, page, status) => {
                  let url = `?fields=*.*.*&limit=10&meta=filter_count&filter[formation_restriction]=${userDetail.formation.id}&filter[is_temporary]=0`;
                  if (userDetail.unit) {
                    url = `?fields=*.*.*&limit=10&meta=filter_count&filter[unit_restriction]=${userDetail.unit.id}&filter[is_temporary]=0`;
                  }
                  let v = `${url}&offset=${(page - 1) * 10}&limit=10`;
                  if (query.length > 0) {
                    v += `&filter[protector.name][like]=${query}&offset=${
                      (page - 1) * 10
                    }`;
                  }
                  if (sortKey) {
                    v += `&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                  }
                  if (status) {
                    v += `&filter[status]=${status}`;
                  }
                  get(v);
                }}
                totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                items={data?.data || []}
                actionButtons={(item) => {
                  if (item.status === "assigned")
                    return [
                      {
                        title: "",
                        icon: <PencilSquare size={16} />,
                        onClick: (item) => {
                          setSelectedPA({ ...item });
                          setShowWeapon(true);
                        },
                      },
                    ];
                  else if (item.status === "withdrawal pending")
                    return [
                      {
                        title: "Withdraw",
                        type: "",
                        onClick: async (item) => {
                          await assignProtector(item.protector.id.toString(), {
                            status: "unassigned",
                          });
                          await patch(item.id.toString(), {
                            status: "withdrawn",
                          });
                          window.location.reload();
                        },
                      },
                    ];
                  else if (item.status === "assignment pending")
                    return [
                      {
                        title: "Assign Personnel",
                        type: "",
                        onClick: (item) => {
                          setSelected(item);
                          setShow(true);
                        },
                      },
                    ];
                  else return [];
                }}
              />
            </div>
          </div>
        </div>
        {showWeapon && (
          <EditWeaponModal
            item={selectedPA}
            onClose={() => setShowWeapon(false)}
            onSave={async (item) => {
              if (item.weapon_type?.id && item.no_of_rounds)
                await patchPA(item.id.toString(), {
                  weapon_type: item.weapon_type.id,
                  no_of_rounds: item.no_of_rounds,
                });
              window.location.reload();
            }}
          />
        )}
        {show && (
          <AddEditModal
            onClose={() => setShow(false)}
            title="Personnel"
            onSubmit={async (item: any) => {
              await patch(selected!.id!.toString(), {
                protector: item.protector?.id,
                authority: item.authority,
                weapon_type: item.weapon_type?.id,
                no_of_rounds: item.no_of_rounds,
                order_no: item.order_no,
                order_date: item.order_date,
                to_date: item.to_date,
                unit_restriction: item.unit_restriction,
                formation_restriction: item.formation_restriction,
                status: "assigned",
              });
              if (item.protector?.id) {
                await assignProtector(item.protector.id.toString(), {
                  status: "assigned",
                });
              }
              window.location.reload();
            }}
            fields={[
              {
                key: "protector",
                label: "Personnel",
                type: "custom-select",
                customSelectOptions: {
                  onQuery: async (q, setItems) => {
                    let url = `?fields=*.*&limit=10&meta=filter_count&filter[unit.formation]=${userDetail.formation.id}&filter[status]=unassigned`;
                    if (userDetail.unit) {
                      url = `?fields=*.*&limit=10&meta=filter_count&filter[unit]=${userDetail.unit.id}&filter[status]=unassigned`;
                    }
                    const d = await getProtectors(`${url}&q=${q}`);
                    setItems(d.data || []);
                  },
                  filterKey: "belt_no",
                  renderItem: (item) => (
                    <div>
                      {item.name} ({item.belt_no}/{item.bn})
                    </div>
                  ),
                },
                selectItems: {
                  labelKey: "name",
                  items: dataProtectors?.data,
                },
              },
              {
                key: "authority",
                type: "text",
                label: "Authority",
              },
              {
                key: "weapon_type",
                label: "Weapon Type",
                type: "custom-select",
                customSelectOptions: {
                  filterKey: "title",
                  renderItem: (item) => <p>{item.title}</p>,
                },
                selectItems: {
                  labelKey: "title",
                  items: dataWeapons?.data,
                },
              },
              {
                key: "no_of_rounds",
                type: "number",
                label: "No of Rounds",
              },
              {
                key: "order_no",
                type: "text",
                label: "Order No",
              },
              {
                key: "order_date",
                type: "date",
                label: "Order Date",
              },
              {
                key: "to_date",
                type: "date",
                label: "To Date",
              },
            ]}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
