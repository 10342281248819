import React, { ReactElement, useState } from "react";
import {
  PeopleFill,
  PersonBoundingBox,
  PieChartFill,
  Truck,
} from "react-bootstrap-icons";
import useFetch from "use-http";
import FilterForm from "./components/FilterForm";
import { Parser } from "json2csv";
import UnitSelectModal from "./components/UnitSelectModal";
import { useSelector } from "react-redux";

interface Props {}

export default function NonAdminReportsPage({}: Props): ReactElement {
  const { get: getProtecteeByUnit } = useFetch(
    `custom/reports/protecteeByUnit`
  );
  const userDetail = useSelector((state: any) => state.user.userDetail);
  const { get } = useFetch(`custom/reports/protecteeReportForUnit`);
  function downloadReport(data) {
    if (data?.data?.length > 0) {
      const parser = new Parser({
        fields: Object.keys(data?.data[0]),
      });
      save("report.csv", parser.parse(data?.data));
    }
  }
  function save(filename, data) {
    var blob = new Blob([data], { type: "text/csv" });
    if (typeof window.navigator.msSaveOrOpenBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
  return (
    <>
      <div className="main-header d-flex justify-content-between">
        <div>
          <h1 className="mb-0">Reports &amp; Analysis</h1>
        </div>
      </div>
      <div className="main-body">
        <h2 className="mb-4">Quick Reports</h2>
        <div className="report-container">
          <div
            onClick={async () => {
              downloadReport(await get(`?unit=${userDetail.unit.id}`));
            }}
            className="card card--report"
          >
            <div className="card-body">
              <div className="icon">
                <PersonBoundingBox />
              </div>
              <div className="label">Deployment Report</div>
              {/* <small className="text-faded">Weekly Report</small> */}
            </div>
          </div>
          <div
            className="card card--report"
            onClick={async () => {
              downloadReport(
                await getProtecteeByUnit(`?unit=${userDetail.unit.id}`)
              );
            }}
          >
            <div className="card-body">
              <div className="icon">
                <Truck />
              </div>
              <div className="label">Personnel Report</div>
              {/* <small className="text-faded">Weekly Report</small> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
