import moment from "moment";
import React, { ReactElement } from "react";
import useFetch from "use-http";
import { API_URL } from "../../..";

interface Props {
  protecteeId: string;
  onClose: () => void;
}

export default function PreviousTPRModal({
  onClose,
  protecteeId,
}: Props): ReactElement {
  const { data } = useFetch(
    `items/tpr_protectee?filter[protectee]=${protecteeId}`,
    {
      cache: "no-cache",
    },
    []
  );
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        style={{ display: "block" }}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Previous TPRs</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                {data?.data.flatMap((tpr, index) => (
                  <tr key={index}>
                    <td className="text-right">{index + 1}</td>
                    <td>
                      Submitted On:{" "}
                      {moment(tpr.created_on).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {tpr.ssp_report && (
                        <a
                          href={API_URL + tpr.ssp_report}
                          target="_blank"
                          rel="noreferrer"
                        >
                          SSP Report
                        </a>
                      )}
                    </td>
                    <td>
                      {tpr.intelligence_report && (
                        <a
                          href={API_URL + tpr.intelligence_report}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Intelligence Report
                        </a>
                      )}
                    </td>
                    <td>
                      {tpr.ib_report && (
                        <a
                          href={API_URL + tpr.ib_report}
                          target="_blank"
                          rel="noreferrer"
                        >
                          IB Report
                        </a>
                      )}
                    </td>
                    <td>
                      {tpr.other_report && (
                        <a
                          href={API_URL + tpr.other_report}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {tpr.other_title || "Other Report"}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-muted"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
