import { useState, useEffect, useRef } from 'react'

export default function useInputState<T>(
  initialValue: T,
  validationFn: (value: T) => 'valid' | 'invalid' | 'unset'
): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T>>,
  'valid' | 'invalid' | 'unset'
] {
  const [value, setValue] = useState<T>(initialValue)
  const [isValid, setIsValid] = useState<'valid' | 'invalid' | 'unset'>('unset')
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (!firstUpdate.current) {
      setIsValid(validationFn(value!))
    }
    firstUpdate.current = false
  }, [value])

  return [value, setValue, isValid]
}
