import moment from "moment";
import React, { ReactElement } from "react";
import useFetch from "use-http";
import ETable from "../../common/components/ETable/ETable";
import { LoadingScreen } from "./../../common/components/LoadingScreen";

export default function BulkWithdrawPage(): ReactElement {
  const { get, data } = useFetch(`items/bulk_withdrawal_request`, {
    cache: "no-cache",
  });
  React.useEffect(() => {
    let url = `?fields=*.*&limit=10&meta=filter_count`;
    get(url);
  }, [get]);

  if (data) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Bulk Withdrawal Request</h1>
          </div>
        </div>
        <div className="main-body">
          <div className="card h-auto">
            <div className="card-body">
              <ETable<any>
                actionButtons={[]}
                columns={[
                  { key: "id", label: "Id" },
                  { key: "protectee.name", label: "Protectee Name" },
                  { key: "no_of_protectors", label: "No of Personnels" },
                  { key: "order_no", label: "Order No" },
                  { key: "order_date", label: "Order Date" },
                  { key: "unit.title", label: "Unit" },
                  { key: "formation.title", label: "Formation" },
                ]}
                status={["pending", "completed"]}
                onChange={(query, sortKey, isSortAsc, page, status) => {
                  let url = `?fields=*.*&limit=10&meta=filter_count`;
                  let v = `${url}&offset=${
                    (page - 1) * 10
                  }&limit=10&meta=filter_count`;
                  if (query.length > 0) {
                    v += `&filter[protector.name][like]=${query}&offset=${
                      (page - 1) * 10
                    }`;
                  }
                  if (sortKey) {
                    v += `&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                  }
                  if (status) {
                    v += `&filter[status][in]=${status}`;
                  }
                  get(v);
                }}
                customColumns={[
                  {
                    heading: "Status",
                    renderer: (item) => (
                      <span className="badge badge-primary text-uppercase">
                        {item.status}
                      </span>
                    ),
                  },
                  {
                    heading: "Updated On",
                    renderer: (item) =>
                      item.modified_on && (
                        <span>
                          {moment(item.modified_on).format(
                            "DD-MM-YYYY @ HH:mm"
                          )}
                        </span>
                      ),
                  },
                ]}
                totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                items={data?.data || []}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
