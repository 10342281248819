import React, { ReactElement, useState, useRef } from "react";
import {
  ClipboardData,
  FilePerson,
  PeopleFill,
  PersonBoundingBox,
  PieChartFill,
  Tags,
  Truck,
} from "react-bootstrap-icons";
import useFetch from "use-http";
import FilterForm from "./components/FilterForm";
import { Parser } from "json2csv";
import UnitSelectModal from "./components/UnitSelectModal";
import ReactToPrint from "react-to-print";
import CategoryReport from "./components/CategoryReport";
import ProtecteeReport from "./components/ProtecteeReport";
import PersonnelReportByRank from "./components/PersonnelReportByRank";
import { useHistory } from "react-router";

interface Props {}

export default function ReportsPage({}: Props): ReactElement {
  const history = useHistory();
  const { get } = useFetch(`custom/reports/protectee`);
  const { get: getProtecteeByUnit } = useFetch(
    `custom/reports/protecteeByUnit`
  );
  const { get: getFullReport } = useFetch(`custom/reports/grandReport`);

  const { get: getProtecteeByRank } = useFetch(
    `custom/reports/protecteeByRank`
  );
  const { get: getProtecteeByDT } = useFetch(
    `custom/reports/protecteeByDeploymentType`
  );
  const [showModalReport, setShowModalReport] = useState<boolean>(false);
  function downloadReport(data) {
    if (data?.data?.length > 0) {
      const parser = new Parser({
        fields: Object.keys(data?.data[0]),
      });
      save("report.csv", parser.parse(data?.data));
    }
  }
  function save(filename, data) {
    var blob = new Blob([data], { type: "text/csv" });
    if (typeof window.navigator.msSaveOrOpenBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
  return (
    <>
      <div className="main-header d-flex justify-content-between">
        <div>
          <h1 className="mb-0">Reports &amp; Analysis</h1>
        </div>
      </div>
      <div className="main-body">
        <h2 className="mb-4">Quick Reports</h2>
        <div className="report-container">
          <div
            onClick={async () => {
              downloadReport(await get());
            }}
            className="card card--report"
          >
            <div className="card-body">
              <div className="icon">
                <PersonBoundingBox />
              </div>
              <div className="label">Personnel Report</div>
              {/* <small className="text-faded">Weekly Report</small> */}
            </div>
          </div>
          <div
            onClick={() => {
              setShowModalReport(true);
            }}
            className="card card--report"
          >
            <div className="card-body">
              <div className="icon">
                <PeopleFill />
              </div>
              <div className="label">Units Report</div>
              {/* <small className="text-faded">Weekly Report</small> */}
            </div>
          </div>
          <div
            className="card card--report"
            onClick={async () => {
              downloadReport(await getFullReport());
            }}
          >
            <div className="card-body">
              <div className="icon">
                <Truck />
              </div>
              <div className="label">Full Report</div>
              {/* <small className="text-faded">Weekly Report</small> */}
            </div>
          </div>
          <div
            className="card card--report"
            onClick={async () => {
              downloadReport(await getProtecteeByRank());
            }}
          >
            <div className="card-body">
              <div className="icon">
                <PieChartFill />
              </div>
              <div className="label">Rank Report</div>
              {/* <small className="text-faded">Weekly Report</small> */}
            </div>
          </div>
          <div
            className="card card--report"
            onClick={async () => {
              downloadReport(await getProtecteeByDT());
            }}
          >
            <div className="card-body">
              <div className="icon">
                <PieChartFill />
              </div>
              <div className="label">Deployment Type</div>
              {/* <small className="text-faded">Weekly Report</small> */}
            </div>
          </div>
          <div
            className="card card--report"
            onClick={async () => {
              // downloadReport(await getProtecteeByRank());
              history.push(`/reports/view`);
            }}
          >
            <div className="card-body">
              <div className="icon">
                <ClipboardData />
              </div>
              <div className="label h5">View and Print Reports</div>
            </div>
          </div>
        </div>

        <div className="spacer spacer--lg spacer--border"></div>
        <h2 className="mb-4">Custom Reports</h2>
        <FilterForm />
        {showModalReport && (
          <UnitSelectModal
            onClose={() => setShowModalReport(false)}
            onGenerate={async (item) => {
              downloadReport(await getProtecteeByUnit(`?unit=${item}`));
              setShowModalReport(false);
            }}
          />
        )}
      </div>
    </>
  );
}
