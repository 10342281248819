import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import useFetch from "use-http";
import Empty from "../../../common/components/Empty";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import { Parser } from "json2csv";
import { type } from "os";

interface Props {}
const border = {
  border: "2px solid #999",
};
const sortInSeq = (list: any[], seq: string[]) => {
  const data: any[] = [];
  console.group();
  console.log("Param list: ", list);
  console.log("Param seq: ", seq);
  console.groupEnd();
  seq?.forEach((e) => {
    let filteredData = list?.filter((li) => li?.category === e) || [];
    data?.push(...filteredData);
  });
  console.log("Filtered data: ", data);
  return data;
};
const getDatainarr = (data) => {
  return data?.map((e) => e?.title);
};

const ProtecteeReport = (props: Props) => {
  const categoryReportRef = useRef<any>();
  const [data, setData] = useState<any[]>([]);
  const [filterCategory, setFilterCategory] = useState<string>("all");
  const [filterOrg, setFilterOrg] = useState<string>("all");
  const { get, loading } = useFetch(`custom/reports/groupByProtectee`);
  const { data: datalist, loading: loadingSeq } = useFetch(
    `items/designation?fields=title`,
    {},
    []
  );

  useEffect(() => {
    const listSeq = datalist?.data && getDatainarr(datalist?.data);
    data.length === 0 &&
      get().then((res) => {
        console.log("Data is  :", sortInSeq(res?.data, listSeq));
        setData(sortInSeq(res?.data, listSeq));
      });
  }, [datalist, get]);

  const filteredData = useMemo(() => {
    // const temp = data;
    if (data.length > 0) {
      return data
        .filter((d) =>
          filterCategory === "all" ? d : d.category === filterCategory
        )
        .filter((d) => (filterOrg === "all" ? d : d.org === filterOrg));
    }
  }, [filterCategory, filterOrg, data]);

  // Download json to csv
  function downloadReport(sddata) {
    if (sddata?.length > 0) {
      const toplevelKey = Object.keys(sddata[0]);
      const parentkys = Object.keys(sddata[0]?.assigned_personal[0]);
      const concatArray = toplevelKey.concat(parentkys);
      const toplevelData = sddata.map((each) => {
        let arr = each.assigned_personal.map((assignedP) =>
          Object.assign({
            name: assignedP?.name ?? "",
            rank: assignedP?.rank ?? "",
            belt_no: assignedP?.belt_no ?? "",
            unit: assignedP?.unit ?? "",
            mobile: assignedP?.mobile ?? "000000000",
            deployed: assignedP?.deployed ?? "",
            weapon: assignedP?.weapons ?? "",
            order: assignedP?.order ?? "",
            auth: assignedP?.auth ?? "",
            category: each?.category ?? "",
            org: each?.org ?? "",
            protectee_id: each?.protectee_id ?? "",
            protectee_name: each?.protectee_name ?? "",
            total_security: each?.total_security ?? "",
            total_vehicles: each?.total_vehicles ?? "",
            vehicles: each?.vehicles ?? "",
          })
        );
        return arr;
      });
      const flatData = toplevelData.flat();
      const parser = new Parser({
        fields: concatArray,
        data: sddata,
      });
      save("Protectee_Report.csv", parser.parse(flatData));
    }
  }
  function save(filename, data) {
    var blob = new Blob([data], { type: "text/csv" });
    if (typeof window.navigator.msSaveOrOpenBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mx-2 mr-4">
        <ReactToPrint
          removeAfterPrint={true}
          trigger={() => (
            <button
              className="ml-4 btn btn-secondary"
              disabled={loading || loadingSeq}
            >
              Print
            </button>
          )}
          content={() => categoryReportRef.current}
        />
        <form className="d-flex">
          <div className="form-group">
            <label htmlFor="category" className="">
              Category
            </label>
            <select
              name=""
              id="category"
              className="form-control mr-3"
              value={filterCategory}
              style={{
                width: "300px",
                maxWidth: "fit-content",
                // minWidth: "max-content",
              }}
              onChange={(e) => {
                setFilterCategory(e.target.value);
              }}
            >
              <option value="all">All</option>
              {Array.from(new Set(data?.map((d) => d.category)))?.map(
                (each) => (
                  <option value={each}>{each}</option>
                )
              )}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="org" className="form-label">
              Organization
            </label>
            <select
              name=""
              id="org"
              className="form-control"
              value={filterOrg}
              style={{
                width: "300px",
                maxWidth: "fit-content",
                // minWidth: "max-content",
              }}
              onChange={(e) => {
                setFilterOrg(e.target.value);
              }}
            >
              <option value="all">All</option>
              {Array.from(new Set(data?.map((d) => d.org)))?.map((each) => (
                <option value={each}>{each}</option>
              ))}
            </select>
          </div>
        </form>
      </div>
      {loading || loadingSeq ? (
        <LoadingScreen text={"Loading Report..."} />
      ) : filteredData?.length === 0 ? (
        <div className="">
          <Empty
            title="No Data Found"
            subtitle="Please select different filter"
            onPress={() => {
              setFilterCategory("all");
              setFilterOrg("all");
            }}
            actionButtonText="Clear Filter"
          />
        </div>
      ) : (
        <div
          className="bg-white mx-4"
          style={{ marginTop: "2.5em" }}
          ref={categoryReportRef}
        >
          <div className="d-flex justify-content-between ">
            <p></p>
            <h3 className="text-center my-2 ">
              DETAILS OF SECUIRTY PERSONNEL PROVIDED TO VARIOUS PROTECTEES
            </h3>
            <button
              title="download"
              onClick={async () => {
                downloadReport(filteredData);
              }}
            >
              Download Report
            </button>
          </div>
          {filteredData?.map((each, index: number) => (
            <div className="">
              <div
                className="d-flex my-4 justify-content-around align-items-center py-3"
                style={{ background: "#999" }}
              >
                <h4>Name Of Protectee: {each?.protectee_name}</h4>
                <h4>Category: {each?.category}</h4>
                <h4>Organization: {each?.org}</h4>
                <h4>Total Security: {each?.total_security}</h4>
                <h4>Total Vehicle: {each?.total_vehicles || 0}</h4>
              </div>
              <table
                className="table  table-bordered overflow-hidden "
                style={{ fontSize: "0.8em", fontWeight: 600 }}
              >
                <thead>
                  <tr style={border} className="p-4">
                    <td>S.No</td>
                    <td>Security Personal</td>
                    <td>Rank</td>
                    <td>Belt No.</td>
                    <td>Unit</td>
                    <td>Mobile No.</td>
                    <td>Deployed</td>
                    <td>Weapon</td>
                    <td>Order</td>
                    <td>Authority</td>
                    <td>Vehicle</td>
                  </tr>
                </thead>
                <tbody>
                  {each?.assigned_personal?.map((assigned, index) => (
                    <tr style={border}>
                      <td>{index + 1}.</td>
                      <td>{assigned.name}</td>
                      <td>{assigned.rank}</td>
                      <td>{assigned.belt_no}</td>
                      <td>{assigned.unit}</td>
                      <td>{assigned.mobile}</td>
                      <td>{assigned.deployed}</td>
                      <td>{assigned.weapon}</td>
                      <td>{assigned.order}</td>
                      <td>{assigned.auth}</td>
                      {index === 0 && <td rowSpan={10}>{each?.vehicles}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
          <div
            className="d-flex my-4 justify-content-around align-items-center py-3"
            style={{ background: "#999" }}
          >
            <h4>Total Protectees: {filteredData?.length}</h4>
            <h4>
              Total Security:{" "}
              {filteredData?.reduce(
                (acc: number, item: any) => acc + (+item?.total_security || 0),
                0
              )}
            </h4>
            <h4>
              Total Vehicle:{" "}
              {filteredData?.reduce(
                (acc: number, item: any) => acc + (+item?.total_vehicles || 0),
                0
              )}
            </h4>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProtecteeReport;
