import moment from "moment";
import React, { ReactElement } from "react";
import useFetch from "use-http";

interface Props {
  onClose: () => void;
  onGenerate: (from?: string, to?: string, selectedGroup?: string) => void;
  title?: string;
  type: 0 | 1 | 2;
}

export default function GroupReportModal({
  onClose,
  title,
  type,
  onGenerate,
}: Props): ReactElement {
  const { data: dataGroups, get } = useFetch(`items/designation_group`);
  const [selectedGroup, setSelectedGroup] = React.useState<string>();
  const [from, setFrom] = React.useState<string>();
  const [to, setTo] = React.useState<string>();
  React.useEffect(() => {
    if (type === 1) {
      get();
    }
  }, [type, get]);
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        role="dialog"
        style={{ display: "block" }}
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title || `Report`}</h5>
              <button
                type="button"
                onClick={() => onClose()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>{selectedGroup}</div>
            <div className="modal-body">
              {type === 1 && (
                <div className="form-group">
                  <label htmlFor="group">Group</label>
                  <select
                    onChange={(e) => setSelectedGroup(e.target.value)}
                    className="form-control"
                    name="group"
                    id="group"
                  >
                    {dataGroups?.data.flatMap((g, i) => (
                      <option key={i} value={g.id}>
                        {g.title}
                      </option>
                    ))}
                  </select>
                </div>
              )}{" "}
              <div className="form-group">
                <label htmlFor="fromDate">From</label>
                <input
                  type="date"
                  onChange={(e) =>
                    setFrom(moment(e.target.valueAsDate).format("YYYY-MM-DD"))
                  }
                  className="form-control"
                  name="fromDate"
                  id="fromDate"
                  placeholder="Select Date"
                />
              </div>
              <div className="form-group">
                <label htmlFor="toDate">To</label>
                <input
                  type="date"
                  onChange={(e) =>
                    setTo(moment(e.target.valueAsDate).format("YYYY-MM-DD"))
                  }
                  className="form-control"
                  name="toDate"
                  id="toDate"
                  placeholder="Select Date"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onClose()}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => {
                  if (type === 1) onGenerate(from, to, selectedGroup);
                  else onGenerate(from, to);
                }}
                type="button"
                className="btn btn-primary"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
