import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import AddEditModal from "../../../common/components/AddEditModal";
import ETable from "../../../common/components/ETable/ETable";

export default function VehiclesTable(): ReactElement {
  const protectee = useSelector((state: any) => state.protectee);
  const { data: dataVA, get } = useFetch(
    `items/vehicle_assignment?filter[protectee]=${
      protectee.id
    }&fields=*.*&time=${new Date().valueOf()}`,
    {
      cache: "no-cache",
    },
    []
  );
  const { post, del } = useFetch(`items/vehicle_assignment`, {
    cache: "no-cache",
  });
  const { data: dataV, get: getV } = useFetch("items/vehicle", {
    cache: "no-cache",
  });
  const { patch: patchV } = useFetch(`items/vehicle`, {
    cache: "no-cache",
  });
  React.useEffect(() => {
    getV(`?filter[status]=unassigned`);
  }, [getV]);
  const [show, setShow] = React.useState<boolean>(false);
  return (
    <div>
      <ETable
        showFilterBar={false}
        items={dataVA?.data || []}
        showPagesText={false}
        actionButtons={[
          {
            title: "Unassign",
            type: "danger",
            onClick: async (item: any) => {
              await patchV(`${item.vehicle.id}`, {
                status: "unassigned",
              });
              await del(`${item.id}`);
              getV(`?filter[status]=unassigned&time=${new Date().valueOf()}`);
              get();
            },
          },
        ]}
        columns={[
          {
            key: "vehicle.make",
            label: "Make",
          },
          {
            key: "vehicle.model",
            label: "Model",
          },
          {
            key: "vehicle.registration_number",
            label: "Registration No.",
          },
          {
            key: "limit_in_ltrs",
            label: "Limit in Ltrs",
          },
          {
            key: "driver",
            label: "Driver",
          },
        ]}
      />
      <div className="mt-2">
        <button
          onClick={() => {
            setShow(true);
          }}
          className="btn btn-link"
        >
          + Assign Vehicle
        </button>
      </div>
      {show && (
        <AddEditModal
          fields={[
            {
              key: "vehicle",
              label: "Vehicle",
              type: "custom-select",
              customSelectOptions: {
                filterKey: "registration_number",
                renderItem: (item) => (
                  <div>
                    {item.make}-{item.registration_number}
                  </div>
                ),
                onQuery: async (q, setItems) => {
                  const d = await getV(`?filter[status]=unassigned&q=${q}`);
                  setItems(d.data || []);
                },
              },
              selectItems: {
                items: dataV?.data || [],
                labelKey: "registration_no",
              },
            },
            {
              key: "limit_in_ltrs",
              label: "Limit in Ltrs",
              type: "number",
            },
            {
              key: "driver",
              label: "Driver",
              type: "text-area",
            },
          ]}
          title="Vehicle Assignment"
          onSubmit={async (item: any) => {
            const d = {
              vehicle: item.vehicle.id,
              limit_in_ltrs: item.limit_in_ltrs,
              driver: item.driver,
            };
            await post({ ...d, protectee: protectee.id });
            await patchV(`${item.vehicle.id}`, {
              status: "assigned",
            });
            getV(`?filter[status]=unassigned&time=${new Date().valueOf()}`);
            get();
          }}
          onClose={() => setShow(false)}
        />
      )}
    </div>
  );
}
