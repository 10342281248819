import React, { ReactElement, useState } from "react";
import useFetch from "use-http";
import { API_URL } from "../../..";
import Avatar from '../../../assets/images/placeholder-avatar.jpg';
interface Props {
  defaultImage?: string;
  onUpload: (url: string) => void;
}

export default function EImageUpload({
  defaultImage,
  onUpload,
}: Props): ReactElement {
  const [imgSrc, setImgSrc] = useState<string | undefined>(defaultImage);

  const { post } = useFetch("/files?fields=private_hash");

  const uploadFile = async (file?: File | null) => {
    const data = new FormData();
    if (file) data.append("file", file);
    const res = await post(data);
    onUpload(`_/assets/${res.data.private_hash}`);
    setImgSrc(`${API_URL}_/assets/${res.data.private_hash}`);
  };
  return (
    <div className="e-image-upload">
      <div className="image">
      <img src={imgSrc?imgSrc:Avatar} />
      </div>
      <div className="form-group">
        <input
          type="file"
          name="imageFile"
          onChange={(e) => {
            uploadFile(e.currentTarget.files?.item(0));
          }}
          id="imageFile"
          placeholder="Upload Image"
        />
      </div>
    </div>
  );
}
