import React, { ReactElement } from "react";
import ETable from "../../common/components/ETable/ETable";
import moment from "moment";
import useFetch from "use-http";
import { PATHS } from "../../reducers/routes";
import { Plus } from "react-bootstrap-icons";
import { TPRModal } from "../protectee/components/tpr-modal";
import { LoadingScreen } from "./../../common/components/LoadingScreen";
import { IFilter } from "../../common/models/models";

const filterProtectee : IFilter[] = [
  {
    value: "name",
    label: " PROTECTEE NAME"
  },
  {
    value: "designation.title",
    label: "CATEGORY"
  },
  {
    value: "organisation.title",
    label: "ORGANISATION"
  },
  {
    value: "district.title",
    label: "DISTRICT"
  },
  {
    value: "text",
    label: "TEXT"
  },
 
]

interface Props {}

export default function ProtecteeListPage({}: Props): ReactElement {
  const [state, setState] = React.useState<{
    page: number;
    query: string;
    sortBy: string;
    isAsc: boolean;
    selectKeyRow:string;
  }>({
    page: 1,
    query: "",
    selectKeyRow: "",
    sortBy: "id",
    isAsc: true,
    
  });
  const [show, setShow] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>(false);
  const { get, data } = useFetch<{ data: any[]; meta: any; }>(`items/protectee`);
  React.useEffect(() => {
    let url = `?fields=*.*&offset=${(state.page - 1) * 10
    }&limit=10&meta=filter_count`
    if (state.query.length > 0 ){
      url += `&filter[${state.selectKeyRow}][like]=${state.query}&offset=${(state.page -1) * 10}`
    }
      // url += `&filter[name][contains]=${state.query}&filter[organisation.title][logical]=or&filter[organisation.title][contains]=${state.query}&filter[designation.title][logical]=or&filter[designation.title][contains]=${state.query}`;
  
    if (state?.sortBy) {
      url += `&sort=${state.isAsc ? state.sortBy : "-" + state.sortBy}`;
    }
    get(url);
  }, [get, state]);

  if (data) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Protectees</h1>
          </div>
          <div>
            <button
              onClick={() => (window.location.href = PATHS.protectee)}
              className="btn btn-secondary"
            >
              <Plus /> Add Protectee
            </button>
          </div>
        </div>
        <div className="main-body">
          <div className="card h-auto">
            <div className="card-body">
              <ETable<any>
                columns={[
                  { key: "id", label: "Id" },
                  { key: "name", label: "Protectee Name" },
                  { key: "designation.title", label: "Category" },
                  { key: "organisation.title", label: "Organisation" },
                  { key: "district.title", label: "District" },
                  { key: "text", label: "Text" },
                ]}
                customColumns={[
                  {
                    heading: "Status",
                    renderer: (item) => {
                      if (item.status !== "complete") {
                        return (
                          <span className="badge badge-warning">
                            {item.status.toUpperCase()}
                          </span>
                        );
                      } else if (moment(item.review_date).isBefore())
                        return (
                          <span className="badge badge-danger">OVERDUE</span>
                        );
                      else if (
                        moment(item.review_date).diff(moment(), "days") < 30
                      ) {
                        return (
                          <span className="badge badge-warning">PENDING</span>
                        );
                      } else {
                        return (
                          <span className="badge badge-success">COMPLETE</span>
                        );
                      }
                    },
                  },
                ]}
                selectedby={filterProtectee}
                onChange={(query, sortKey, isSortAsc, page, status,selectKeyRow) => {
                  setState({
                    query,
                    sortBy: sortKey,
                    isAsc: isSortAsc,
                    page,
                    selectKeyRow,
                    
                    
                  });
                }}
                totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                keepPageNum={true}
                items={data?.data || []}
                actionButtons={(i) => {
                  const r: any[] = [
                    {
                      title: "Request TPR",
                      onClick: (item) => {
                        setSelected(item);
                        setShow(true);
                      },
                      type: "dark",
                    },
                  ];
                  if (i.status === "complete") {
                    r.push({
                      title: "Manage",
                      onClick: (item) => {
                        if (
                          moment(item.review_date).isBefore() ||
                          moment(item.review_date).diff(moment(), "days") < 30
                        ) {
                          // window.location.href="/protectee/"+item.id;
                        }
                        window.location.href = "/protectee/" + item.id;
                      },
                      type: "primary",
                      // moment(i.review_date).isBefore() ||
                      // moment(i.review_date).diff(moment(), "days") < 30
                      //   ? "primary"
                      //   : "muted",
                    });

                    r.push({
                      title: "Profile",
                      onClick: (item) => {
                        window.location.href = PATHS.protectees + "/" + item.id;
                      },
                    });
                  } else
                    r.push({
                      title: "Continue Application",
                      onClick: (item) => {
                        window.location.href = "/protectee/" + item.id;
                      },
                    });
                  return r;
                }}
              />
            </div>
          </div>
        </div>
        {show && (
          <TPRModal
            info={{
              designation: selected.designation?.title,
              name: selected.name,
              organisation: selected.organisation?.title,
            }}
            onHide={() => setShow(false)}
            visible={show}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
