import React, { ButtonHTMLAttributes, Fragment, ReactElement } from 'react'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

export default function EButton({ isLoading, ...props }: Props): ReactElement {
  return (
    <button {...props} disabled={isLoading || props.disabled}>
      {isLoading ? (
        <Fragment>
          <span
            className='spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'
          />
          <span className='sr-only'>Loading...</span>
        </Fragment>
      ) : (
        props.children
      )}
    </button>
  )
}
