import React, { useEffect, useRef, useState } from "react";
import { Diagram2Fill, Tags } from "react-bootstrap-icons";
import ReactToPrint from "react-to-print";
import useFetch from "use-http";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import html2pdf from "html2pdf.js";
const border = {
  border: "2px solid #999",
};

const groupByKey = (list, key) => {
  return (
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    ) || {}
  );
};

const rankToCountConversion = (list) => {
  const groupedByOrg = groupByKey(list, "organisation");
  return Object.keys(groupedByOrg).map((each) => {
    let obj = {
      category: groupedByOrg[each][0]?.category,
      org: groupedByOrg[each][0]?.organisation,
    };
    groupedByOrg[each].forEach(({ rank, count }) => {
      obj[rank] = count;
    });
    return obj;
  });
};
interface Props {}

const PersonnelReportByRank = (props: Props) => {
  const personnelReportRef = useRef<any>();
  const [data, setData] = useState([]);
  const { get, loading } = useFetch(`custom/reports/groupByRank`);
  console.log(rankToCountConversion(data["MLA"]));
  useEffect(() => {
    get().then((res) => setData(groupByKey(res?.data, "category")));
  }, []);

  const saveToPDF = () => {
    var opt = {
      margin: 0,
      filename: "personnel_Report_By_Rank.pdf",
      image: { type: "jpeg", quality: 1 },
      jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
    };
    html2pdf(personnelReportRef?.current, opt);
  };
  return (
    <React.Fragment>
      <ReactToPrint
        removeAfterPrint={true}
        trigger={() => (
          <button className="ml-4 btn btn-secondary" disabled={loading}>
            Print
          </button>
        )}
        content={() => personnelReportRef.current}
      />
      <button
        className="ml-4 btn btn-secondary"
        disabled={loading}
        onClick={() => saveToPDF()}
      >
        Download as Pdf
      </button>
      {loading ? (
        <LoadingScreen text="Loading Report..." />
      ) : (
        <div
          className="  p-4 mt-4"
          style={{ marginTop: "2.5em" }}
          ref={personnelReportRef}
        >
          <h3 className="text-center my-2 ">Personnel assigned by Rank</h3>
          <div>
            <table
              className="table table-responsive overflow-hidden bg-white"
              style={{ fontSize: "0.8em", fontWeight: 600 }}
            >
              <thead>
                <tr className="text-bold h6 mt-4 " style={border}>
                  <td className="">S.No.</td>
                  <td className="" style={{ width: "10%" }}>
                    Category
                  </td>
                  <tr
                    className="row"
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <td className="col-2">Organizations</td>
                    <td className="col-1">INSPs</td>
                    <td className="col-1">SIs</td>
                    <td className="col-1">ASIs</td>
                    <td className="col-1">ASI/LRs</td>
                    <td className="col-1">HCs</td>
                    <td className="col-1">HC/PR</td>
                    <td className="col-1">Cts</td>
                    <td className="col-1">C-II</td>
                    <td className="col-1">PHGs</td>
                    <td className="col-1 text-bold">Total Security</td>
                  </tr>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data)?.map((each, index) => (
                  <>
                    <tr className="m-4" style={border}>
                      <td
                        className=""
                        style={{ borderRight: "2px solid #999" }}
                      >
                        {index + 1}
                      </td>
                      <td className="" style={{ width: "10%" }}>
                        {each}
                      </td>
                      {rankToCountConversion(data[each])?.map((org, idx) => (
                        <tr
                          className="row"
                          key={idx}
                          style={
                            idx > 0
                              ? {
                                  width: "100%",
                                  borderLeft: "2px solid #999",
                                  borderTop: "2px solid #999",
                                }
                              : {
                                  width: "100%",
                                  borderLeft: "2px solid #999",
                                }
                          }
                        >
                          <td className="col-2">{org.org}</td>
                          <td className="col-1">{org["Inspector"] || 0}</td>
                          <td className="col-1">{org["SI"] || 0}</td>
                          <td className="col-1">{org["ASI"] || 0}</td>
                          <td className="col-1">{org["ASI/LR"] || 0}</td>
                          <td className="col-1">
                            {org["Head Constable"] || 0}
                          </td>
                          <td className="col-1">
                            {org["Head Constable/PR"] || 0}
                          </td>
                          <td className="col-1">{org["Constable"] || 0}</td>
                          <td className="col-1">{org["C-2"] || 0}</td>
                          <td className="col-1">{org["PHG"] || 0}</td>
                          <td className="col-1 text-bold">
                            {Number(org["Inspector"] || 0) +
                              Number(org["SI"] || 0) +
                              Number(org["ASI"] || 0) +
                              Number(org["ASI/LR"] || 0) +
                              Number(org["Head Constable"] || 0) +
                              Number(org["Head Constable/PR"] || 0) +
                              Number(org["Constable"] || 0) +
                              Number(org["C-2"] || 0) +
                              Number(org["PHG"] || 0)}
                          </td>
                        </tr>
                      ))}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PersonnelReportByRank;
