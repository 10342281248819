import React, { ReactElement } from "react";
import useFetch from "use-http";
import { ESelect } from "../../../common/library";

interface Props {
  item: any;
  onClose: () => void;
  onSave: (item) => void;
}

export default function EditWeaponModalUnit({
  onClose,
  onSave,
  item,
}: Props): ReactElement {
  const { data } = useFetch(`items/weapon_type`, {}, []);
  const {
    get,
    data: dataPA,
    loading,
  } = useFetch(
    `items/protector_assignment?fields=*.*&filter[status]=assigned&filter[protector]=${item.id}`,
    {},
    []
  );
  React.useEffect(() => {
    get();
  }, []);
  console.log(dataPA?.data);
  const { patch } = useFetch(`items/protector_assignment`);
  return (
    <div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        style={{ display: "block" }}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Weapon Details</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {loading ? (
              <div className="modal-body">Loading Data...</div>
            ) : (
              <>
                <div className="modal-body">
                  {dataPA?.data.length > 0 ? (
                    <>
                      <ESelect
                        filterKey="title"
                        items={data?.data || []}
                        defaultValues={[dataPA.data[0]?.weapon_type] || []}
                        label="Weapon"
                        renderItem={(item) => <div>{item.title}</div>}
                        onValuesChange={(items) => {
                          if (items[0] && items[0]?.id) {
                            dataPA.data[0].weapon_type = items[0];
                          }
                        }}
                      />
                      <div className="form-group">
                        <label htmlFor="noofrounds">No of rounds</label>
                        <input
                          type="number"
                          defaultValue={dataPA.data[0].no_of_rounds}
                          onChange={(e) => {
                            dataPA.data[0].no_of_rounds =
                              e.target.valueAsNumber;
                          }}
                          className="form-control"
                          name="noofrounds"
                          id="noofrounds"
                          aria-describedby="helpId"
                          placeholder="No of Rounds"
                        />
                      </div>
                    </>
                  ) : (
                    <>No Assignment</>
                  )}
                </div>
              </>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-muted"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
              >
                Close
              </button>
              {dataPA?.data.length > 0 && (
                <button
                  type="button"
                  onClick={async () => {
                    await patch(`${dataPA?.data[0].id}`, {
                      weapon_type: dataPA.data[0].weapon_type.id,
                      no_of_rounds: dataPA.data[0].no_of_rounds,
                    });
                    onSave(item);
                  }}
                  className="btn btn-primary"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
