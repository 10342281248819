import React, { ReactElement } from "react";
import { PlusCircleFill, XCircleFill, Download } from "react-bootstrap-icons";
import useFetch from "use-http";
import { Parser } from "json2csv";
import { FieldMapping, FieldFunction, FieldReverse } from "./mapping";
interface Props {}

export default function FilterForm({}: Props): ReactElement {
  const [base, setBase] = React.useState("");
  const [filters, setFilters] = React.useState<
    {
      field: string;
      filter: string;
      value: string;
    }[]
  >([
    {
      field: "-1",
      filter: "-1",
      value: "",
    },
  ]);
  const { get: getFields, data: dataFields } = useFetch("fields");
  const { get } = useFetch();
  async function downloadReport() {
    let url = base + "?";
    filters.forEach((filter) => {
      if (FieldReverse[filter.field]) {
        filter.filter = filter.filter === "lte" ? "gte" : "lte";
      }
      url += `filter[${filter.field}][${filter.filter}]=${filter.value}&`;
    });
    const data = await get(url + "fields=*.*");
    if (data?.data) {
      const refinedData = data?.data.flatMap((d) => {
        Object.keys(d).forEach((k) => {
          if (d[k] && typeof d[k] === "object") {
            d[k] = d[k].title || d[k].name || d[k].id;
          }
        });
        return d;
      });
      const parser = new Parser({
        fields: dataFields?.data.map((f) => f.field),
      });
      save("report.csv", parser.parse(refinedData));
    }
  }
  function save(filename, data) {
    var blob = new Blob([data], { type: "text/csv" });
    if (typeof window.navigator.msSaveOrOpenBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="report-filter-container">
            <div className="form-group">
              <label>Select Report Type & Filters</label>
              <select
                defaultValue="-1"
                onChange={(e) => {
                  if (e.currentTarget && e.currentTarget.value !== "-1") {
                    setBase("items/" + e.currentTarget.value);
                    getFields(e.currentTarget.value);
                    setFilters([
                      {
                        field: "-1",
                        filter: "-1",
                        value: "",
                      },
                    ]);
                  }
                }}
                className="form-control w-auto"
                id="sort"
              >
                <option value="-1" disabled>
                  Type
                </option>
                <option value="vehicle">Vehicle</option>
                <option value="protector">Security Personnel</option>
                <option value="protectee">Protectee</option>
              </select>
            </div>
            {dataFields?.data &&
              filters.flatMap((filter, idx) => (
                <div key={idx} className="report-filter-row">
                  <select
                    defaultValue={-1}
                    className="form-control text-capitalize w-auto"
                    name="sort"
                    onChange={(e) => {
                      const f = [...filters];
                      f[idx].field = e.currentTarget.value;
                      setFilters(f);
                    }}
                    id="sort"
                  >
                    <option value={-1} disabled>
                      Fields
                    </option>
                    {dataFields?.data.flatMap((field, index) => (
                      <option
                        key={index}
                        className="text-capitalize"
                        value={field.field}
                      >
                        {FieldMapping[field.field]
                          ? FieldMapping[field.field]
                          : field.field.split("_").join(" ")}
                      </option>
                    ))}
                  </select>
                  <select
                    defaultValue={-1}
                    className="form-control text-capitalize  w-auto"
                    name="sort"
                    onChange={(e) => {
                      const f = [...filters];
                      f[idx].filter = e.currentTarget.value;
                      setFilters(f);
                    }}
                    id="sort"
                  >
                    <option value={-1} disabled>
                      Filter
                    </option>
                    <option value="eq">Equal to</option>
                    <option value="neq">Not equal to</option>
                    <option value="lte">Less than or equal to</option>
                    <option value="gte">Greater than or equal to</option>
                    <option value="contains">Contains</option>
                    <option value="ncontains">Does not contain</option>
                  </select>
                  <input
                    type="text"
                    onChange={(e) => {
                      const f = [...filters];
                      f[idx].value = FieldFunction[f[idx].field]
                        ? FieldFunction[f[idx].field](e.currentTarget.value)
                        : e.currentTarget.value;
                      setFilters(f);
                    }}
                    className="form-control  w-auto"
                    placeholder="Value"
                  />
                  {idx === filters.length - 1 && (
                    <>
                      <button
                        onClick={() => {
                          setFilters([
                            ...filters,
                            {
                              field: "-1",
                              filter: "-1",
                              value: "",
                            },
                          ]);
                        }}
                        className="btn btn-light w-auto"
                      >
                        <PlusCircleFill />
                      </button>
                      {filters.length > 1 && (
                        <button
                          onClick={() => {
                            const f = [...filters];
                            f.splice(f.length - 2, 1);
                            setFilters(f);
                          }}
                          className="btn btn-light w-auto"
                        >
                          <XCircleFill />
                        </button>
                      )}
                    </>
                  )}
                </div>
              ))}
            <button
              onClick={() => downloadReport()}
              className="btn btn-secondary w-auto"
            >
              <Download /> Download Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
