import React, { ReactElement, useState } from "react";
import useFetch from "use-http";
import { ESelect } from "../../../common/library";

interface Props {
  onClose: () => void;
  onSave: ({ noOfPersonnels, orderNo, orderDate, formation,unit }) => void;
}

export default function BulkWithdrawModal({
  onClose,
  onSave,
}: Props): ReactElement {
  const [noOfPersonnels, setNoOfPersonnels] = useState<string>("");
  const [formation, setFormation] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const [orderNo, setOrderNo] = useState<string>("");
  const [orderDate, setOrderDate] = useState<string>("");
  const { data: dataFormations } = useFetch(`items/formation`, {}, []);
  const { data: dataUnits } = useFetch(`items/unit`, {}, []);
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        style={{ display: "block" }}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Bulk Withdrawal</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => onClose()}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ESelect<any>
                filterKey="title"
                items={dataFormations?.data || []}
                label="Formation"
                renderItem={(item)=><div>{item.title}</div>}
                onValuesChange={(items) => {
                  if (items[0]?.id) {
                    setFormation(items[0]);
                  }
                }}
              />
              <ESelect<any>
                filterKey="title"
                items={dataUnits?.data || []}
                label="Unit"
                renderItem={(item)=><div>{item.title}</div>}
                onValuesChange={(items) => {
                  if (items[0]?.id) {
                    setUnit(items[0]);
                  }
                }}
              />
              <div className="form-group">
                <label htmlFor="noOfPersonnels">No of Personnels</label>
                <input
                  type="number"
                  className="form-control"
                  name="noOfPersonnels"
                  onChange={(e) => setNoOfPersonnels(e.target.value)}
                  id="noOfPersonnels"
                  aria-describedby="helpId"
                  placeholder="No of personnels"
                />
              </div>
              <div className="form-group">
                <label htmlFor="orderno">Order No.</label>
                <input
                  type="text"
                  onChange={(e) => setOrderNo(e.target.value)}
                  className="form-control"
                  id="orderno"
                  placeholder="Order No"
                />
              </div>
              <div className="form-group">
                <label htmlFor="orderdate">Order Date</label>
                <input
                  type="date"
                  onChange={(e) => setOrderDate(e.target.value)}
                  className="form-control"
                  id="orderdate"
                  placeholder="orderdate"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => onClose()}
                type="button"
                className="btn btn-muted"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => {
                  onSave({
                    noOfPersonnels,
                    orderNo,
                    orderDate,
                    formation,
                    unit
                  });
                }}
                type="button"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
