import moment from "moment";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "use-http";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import ApproveModal from "../../approval/approveModal";
import NewAssignModal from "../../protectee/components/NewAssignModal";

interface IProtecteeDistrictPageProps {}

const ProtecteeDistrictPage: React.FunctionComponent<IProtecteeDistrictPageProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const [showAssign, setShowAssign] = useState(false);
  const [refinedData, setRefinedData] = useState<any>();
  const { data, get: getAssignment, loading } = useFetch(
    `items/protector_assignment?filter[protectee]=${id}&fields=*.*&filter[status][neq]=withdrawn`,
    {},
    []
  );
  const { data: dataProtectee, loading: loadingProtecteeData } = useFetch(
    `items/protectee/${id}`,
    {},
    []
  );
  const { post: postAssignment } = useFetch("/items/protector_assignment");
  const { patch } = useFetch(`items/protector_assignment`);
  const { patch: patchProtector } = useFetch(`items/protector`);
  const [selected, setSelected] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const sortObj = (obj) => {
    const sortSeq = [
      "Security Incharge",
      "PSO",
      "Escort Duty",
      "House Guard",
      "Driver",
      "Technical Team",
    ];
    let sortedObj = {};
    sortSeq.map((e) => {
      if (obj[e]) sortedObj[e] = obj[e];
    });
    return sortedObj;
  };
  useEffect(() => {
    const d: any = {};
    if (data?.data.length > 0) {
      data?.data.forEach((protector) => {
        if (!d[protector.deployment_type.title]) {
          d[protector.deployment_type.title] = [protector];
        } else {
          d[protector.deployment_type.title].push(protector);
        }
      });
      setRefinedData(sortObj(d));
    }
  }, [data, setRefinedData]);
  if (!loading && !loadingProtecteeData) {
    return (
      <div className="card">
        <div className="card-body">
          {/* In-Charge */}
          {dataProtectee?.data.area_security ? (
            <div>
              The protectee is under security of{" "}
              {dataProtectee?.data.area_security_district.title} District
            </div>
          ) : data?.data.length === 0 ? (
            <div>This protectee has no Assignment data.</div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                {refinedData &&
                  Object.keys(refinedData).flatMap((key, index) => (
                    <React.Fragment key={index}>
                      <h2>
                        {key} (x{refinedData[key].length})
                      </h2>
                      <p className="text-muted">
                        You can allocate {refinedData[key].length} {key}{" "}
                        Personnel
                      </p>
                      <table className="table has-action-buttons">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Belt No./RMC</th>
                            <th scope="col">Weapon Type</th>
                            <th scope="col">Order No.</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Assigned Date</th>
                            {/* <th scope="col"></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {refinedData[key].flatMap(
                            (pa, i) =>
                              pa.protector && (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{pa.protector?.name}</td>
                                  <td>{pa.protector?.belt_no}</td>
                                  <td>{pa.weapon_type?.title}</td>
                                  <td>{pa.order_no}</td>
                                  <td>{pa.order_date}</td>
                                  <td>{moment(pa.updated_on, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
                                  {/* <td>
                                      <button
                                        onClick={() => {
                                          setSelected(pa);
                                          setShowModal(true);
                                        }}
                                        type="button"
                                        className="btn btn-link btn-sm"
                                      >
                                        <span className="text-secondary">
                                          Withdraw
                                        </span>
                                      </button>
                                    </td> */}
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                      <div className="spacer spacer--sm"></div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          )}
        </div>
        {showAssign && (
          <NewAssignModal
            onClose={() => setShowAssign(false)}
            onSave={async ({
              noOfAssignments,
              orderDate,
              orderNo,
              deploymentType,
            }) => {
              const promises: Promise<any>[] = [];
              for (let i = 0; i < noOfAssignments; i++) {
                promises.push(
                  postAssignment({
                    protectee: id,
                    deployment_type: deploymentType.id,
                    authority: "ADGP/Security",
                    order_no: orderNo,
                    order_date: orderDate,
                    status: "unassigned",
                  })
                );
              }
              await Promise.all(promises);
              getAssignment();
              setShowAssign(false);
            }}
          />
        )}
        {showModal && (
          <ApproveModal
            isWithdrawal
            onClose={() => setShowModal(false)}
            onSave={async (orderDate, orderNo) => {
              await patch(selected.id.toString(), {
                status: "withdrawn",
                order_no: orderNo,
                order_date: orderDate,
              });
              await patchProtector(selected.protector.id.toString(), {
                status: "unassigned",
              });
              window.location.reload();
            }}
          />
        )}
      </div>
    );
  } else {
    return <LoadingScreen />;
  }
};

export default ProtecteeDistrictPage;
