import React, { ReactElement } from "react";
import useFetch from "use-http";
import { ESelect } from "../../common/library";

interface Props {
  item: any;
  onClose: () => void;
  onSave: (item) => void;
}

export default function EditWeaponModal({
  onClose,
  onSave,
  item,
}: Props): ReactElement {
  const { data } = useFetch(`items/weapon_type`, {}, []);
  return (
    <div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        style={{ display: "block" }}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Weapon Details</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ESelect
                filterKey="title"
                items={data?.data || []}
                defaultValues={[item?.weapon_type] || []}
                label="Weapon"
                renderItem={(item) => <div>{item.title}</div>}
                onValuesChange={(items) => {
                  if (items[0] && items[0]?.id) {
                    item.weapon_type = items[0];
                  }
                }}
              />
              <div className="form-group">
                <label htmlFor="noofrounds">No of rounds</label>
                <input
                  type="number"
                  defaultValue={item.no_of_rounds}
                  onChange={(e) => {
                    item.no_of_rounds = e.target.valueAsNumber;
                  }}
                  className="form-control"
                  name="noofrounds"
                  id="noofrounds"
                  aria-describedby="helpId"
                  placeholder="No of Rounds"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-muted"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={() => {
                  onSave(item);
                }}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
