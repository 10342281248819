import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { Plus } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import AddEditModal from "../../../common/components/AddEditModal";
import ApproveModal from "../../approval/approveModal";
import BulkWithdrawModal from "./BulkWithdrawModal";
import NewAssignModal from "./NewAssignModal";

interface Props {}

export default function Assign({}: Props): ReactElement {
  const [showAssign, setShowAssign] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const protectee = useSelector((state: any) => state.protectee);
  const [refinedData, setRefinedData] = useState<any>();
  let tableIndex = 1;
  const {
    data,
    get: getAssignment,
    loading: loadingAssignment,
  } = useFetch(
    // `items/protector_assignment?filter[protectee]=${protectee.id}&fields=*.*&filter[status][nin]=unassigned`,
    `items/protector_assignment?filter[protectee]=${
      protectee.id
    }&fields=*.*&filter[status][nin]=withdrawn&a=${String(Date.now()).substr(
      -1
    )}`,
    {},
    []
  );
  const { post: postAssignment } = useFetch("/items/protector_assignment");
  const { patch, del } = useFetch(`items/protector_assignment`);
  const { patch: assignProtector } = useFetch(`items/protector`);
  const { data: dataProtectors, get: getProtectors } = useFetch(
    `items/protector`,
    {},
    []
  );
  const { post: postWithdrawal } = useFetch(`items/bulk_withdrawal_request`, {
    cache: "no-cache",
  });
  useEffect(() => {
    getProtectors(`?filter[status]=unassigned`);
  }, [getProtectors]);
  const { data: dataWeapons } = useFetch(`items/weapon_type`, {}, []);
  const { data: dataUnits } = useFetch(`items/unit`, {}, []);
  const { data: dataFormations } = useFetch(`items/formation`, {}, []);
  const [selected, setSelected] = useState<any>();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const sortObj = (obj) => {
    const sortSeq = [
      "Security Incharge",
      "PSO",
      "Escort Duty",
      "House Guard",
      "Driver",
      "Technical Team",
    ];
    let sortedObj = {};
    sortSeq.map((e) => {
      if (obj[e]) sortedObj[e] = obj[e];
    });
    return sortedObj;
  };
  useEffect(() => {
    const d: any = {};
    if (data?.data.length > 0) {
      data?.data.forEach((protector) => {
        if (!d[protector.deployment_type.title]) {
          d[protector.deployment_type.title] = [protector];
        } else {
          d[protector.deployment_type.title].push(protector);
        }
      });
      setRefinedData(sortObj(d));
    }
  }, [data, setRefinedData, loadingAssignment]);
  return (
    <div className="card">
      <div className="card-body">
        {/* In-Charge */}
        {protectee.security.area_security ? (
          <div>
            The protectee is under security of{" "}
            {protectee.security.area_security_district.title} District
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12 text-right">
              <button
                type="button"
                onClick={() => {
                  setShowAssign(true);
                }}
                className="btn btn-link"
              >
                <Plus /> Add Security Personnel
              </button>
              <button
                type="button"
                onClick={() => {
                  setShowWithdraw(true);
                }}
                className="btn ml-2 btn-link text-danger"
              >
                Bulk Withdraw
              </button>
            </div>
            <div className="col-lg-12">
              {refinedData &&
                Object.keys(refinedData).flatMap((key, index) => {
                  let tableIndex = 1;
                  return (
                    <React.Fragment key={index}>
                      <h2>
                        {key} (x
                        {refinedData[key].length -
                          refinedData[key].filter(
                            (x) => x.protector && x.status === "unassigned"
                          ).length}
                        )
                      </h2>
                      <p className="text-muted">
                        You can allocate {refinedData[key].length} {key}{" "}
                        Personnel
                      </p>
                      {loadingAssignment ? (
                        <>
                          <p style={{ textAlign: "center" }}>Loading data...</p>
                        </>
                      ) : (
                        <>
                          <table className="table has-action-buttons">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Belt No./RMC</th>
                                <th scope="col">Weapon Type</th>
                                <th scope="col">Order No.</th>
                                <th scope="col">Order Date</th>
                                <th scope="col">Assigned Date</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {refinedData[key].flatMap((pa, i) => {
                                return pa.protector ? (
                                  pa.status !== "unassigned" && (
                                    <tr key={i}>
                                      <td>{tableIndex++}</td>
                                      <td>{pa.protector?.name}</td>
                                      <td>{pa.protector?.belt_no}</td>
                                      <td>{pa.weapon_type?.title}</td>
                                      <td>{pa.order_no}</td>
                                      <td>{pa.order_date}</td>
                                      <td>{moment(pa.updated_on, "YYYY-MM-DD").format("YYYY-MM-DD")}</td>
                                      {/* <td>{pa.status}</td> */}

                                      <td>
                                        <button
                                          onClick={() => {
                                            setSelected(pa);
                                            setShowModal(true);
                                          }}
                                          disabled={
                                            pa.status === "withdrawal pending"
                                          }
                                          type="button"
                                          className="btn btn-link btn-sm"
                                        >
                                          <span className="text-secondary">
                                            {pa.status === "withdrawal pending"
                                              ? "Withdrawal Requested"
                                              : "Withdraw"}
                                          </span>
                                        </button>
                                        <button
                                          onClick={async () => {
                                            await patch(
                                              pa.protector.id.toString(),
                                              {
                                                status: "unassigned",
                                              }
                                            );
                                            await del(pa.id.toString());
                                            window.location.reload();
                                          }}
                                          type="button"
                                          className="btn btn-link btn-sm ml-2"
                                        >
                                          <span className="text-danger">
                                            Unassign
                                          </span>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                ) : (
                                  <tr key={i}>
                                    <td>{tableIndex++}</td>
                                    <td className="text-left" colSpan={5}>
                                      <button
                                        onClick={() => {
                                          setSelected(pa);
                                          setShow(true);
                                        }}
                                        className="btn btn-link"
                                      >
                                        + Assign Personnel
                                        {pa.status === "assignment pending" && (
                                          <small className="ml-2 text-danger">
                                            Restricted to{" "}
                                            {pa.unit_restriction
                                              ? pa.unit_restriction.title +
                                                " unit"
                                              : pa.formation_restriction.title +
                                                " formation"}
                                          </small>
                                        )}
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        onClick={async () => {
                                          await del(pa.id.toString());
                                          window.location.reload();
                                        }}
                                        type="button"
                                        className="btn btn-link btn-sm ml-2"
                                      >
                                        <span className="text-danger">
                                          Unassign
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </>
                      )}
                      <div className="spacer spacer--sm"></div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      {show && (
        <AddEditModal
          onClose={() => setShow(false)}
          title="Personnel"
          onSubmit={async (item: any) => {
            await patch(selected.id.toString(), {
              protector: item.protector?.id,
              authority: item.authority,
              weapon_type: item.weapon_type?.id,
              no_of_rounds: item.no_of_rounds,
              order_no: item.order_no,
              order_date: item.order_date,
              to_date: item.to_date,
              unit_restriction: item.unit_restriction,
              formation_restriction: item.formation_restriction,
              status: item.protector?.id
                ? "assigned"
                : item.unit_restriction || item.formation_restriction
                ? "assignment pending"
                : "unassigned",
            });
            if (item.protector?.id) {
              await assignProtector(item.protector.id.toString(), {
                status: "assigned",
              });
            }
            window.location.reload();
          }}
          fields={[
            {
              key: "unit_restriction",
              label: "Unit Restriction",
              type: "custom-select",
              customSelectOptions: {
                filterKey: "title",
                renderItem: (item) => <div>{item.title}</div>,
              },
              selectItems: {
                labelKey: "title",
                items: dataUnits?.data,
              },
            },
            {
              key: "formation_restriction",
              label: "Formation Restriction",
              type: "custom-select",
              customSelectOptions: {
                filterKey: "title",
                renderItem: (item) => <div>{item.title}</div>,
              },
              selectItems: {
                labelKey: "title",
                items: dataFormations?.data,
              },
            },
            {
              key: "protector",
              label: "Personnel",
              type: "custom-select",
              customSelectOptions: {
                onQuery: async (q, setItems) => {
                  const d = await getProtectors(
                    `?filter[status]=unassigned&q=${q}`
                  );
                  setItems(d.data || []);
                },
                filterKey: "belt_no",
                renderItem: (item) => (
                  <div>
                    {item.name} ({item.belt_no}/{item.bn})
                  </div>
                ),
              },
              selectItems: {
                labelKey: "name",
                items: dataProtectors?.data,
              },
            },
            {
              key: "authority",
              type: "text",
              label: "Authority",
              inputOptions: {
                disabled: true,
                defaultValue: "ADGP/Security",
              },
            },
            {
              key: "weapon_type",
              label: "Weapon Type",
              type: "custom-select",
              customSelectOptions: {
                filterKey: "title",
                renderItem: (item) => <p>{item.title}</p>,
              },
              selectItems: {
                labelKey: "title",
                items: dataWeapons?.data,
              },
            },
            {
              key: "no_of_rounds",
              type: "number",
              label: "No of Rounds",
            },
            {
              key: "order_no",
              type: "text",
              label: "Order No",
            },
            {
              key: "order_date",
              type: "date",
              label: "Order Date",
            },
            {
              key: "to_date",
              type: "date",
              label: "Assign till Date",
            },
          ]}
        />
      )}
      {showAssign && (
        <NewAssignModal
          onClose={() => setShowAssign(false)}
          onSave={async ({
            noOfAssignments,
            orderDate,
            orderNo,
            deploymentType,
          }) => {
            const promises: Promise<any>[] = [];
            for (let i = 0; i < noOfAssignments; i++) {
              promises.push(
                postAssignment({
                  protectee: protectee.id,
                  deployment_type: deploymentType.id,
                  authority: "ADGP Security",
                  order_no: orderNo,
                  order_date: orderDate,
                  status: "unassigned",
                })
              );
            }
            await Promise.all(promises);
            getAssignment();
            // window.location.reload();
            setShowAssign(false);
          }}
        />
      )}
      {showWithdraw && (
        <BulkWithdrawModal
          onClose={() => setShowWithdraw(false)}
          onSave={async ({
            formation,
            noOfPersonnels,
            orderDate,
            orderNo,
            unit,
          }) => {
            await postWithdrawal({
              protectee: protectee.id,
              order_date: orderDate,
              order_no: orderNo,
              no_of_protectors: noOfPersonnels,
              unit: unit?.id,
              formation: formation?.id,
              status: "pending",
            });
            setShowWithdraw(false);
          }}
        />
      )}
      {showModal && (
        <ApproveModal
          isWithdrawal
          onClose={() => setShowModal(false)}
          onSave={async (orderDate, orderNo) => {
            await patch(selected.id.toString(), {
              status: "withdrawal pending",
              order_no: orderNo,
              order_date: orderDate,
              unit_restriction: selected.protector.unit,
            });
            window.location.reload();
          }}
        />
      )}
    </div>
  );
}
