import moment from "moment";
import React, { ReactElement } from "react";
import useFetch from "use-http";
import ETable from "../../common/components/ETable/ETable";
import ApproveModal from "./approveModal";
import { LoadingScreen } from "./../../common/components/LoadingScreen";

export default function ApprovalPage(): ReactElement {
  const { patch } = useFetch(`items/protector_assignment`);
  const { patch: patchProtector } = useFetch(`items/protector`);

  const [selected, setSelected] = React.useState<any>();
  const [show, setShow] = React.useState<boolean>(false);
  const { get, data } = useFetch(`items/protector_assignment`, {
    cache: "no-cache",
  });

  React.useEffect(() => {
    let url = `?fields=*.*.*&limit=10&meta=filter_count&filter[status]=assignment request&filter[is_temporary]=1`;
    get(url);
  }, [get]);

  if (data) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Temporary Personnel Approval</h1>
          </div>
        </div>
        <div className="main-body">
          <div className="card h-auto">
            <div className="card-body">
              <ETable<any>
                columns={[
                  { key: "id", label: "Id" },
                  { key: "protectee.name", label: "Protectee Name" },
                  { key: "protector.name", label: "Personnel Name" },
                  { key: "protector.unit.title", label: "Unit" },
                  { key: "deployment_type.title", label: "Deployed As" },
                ]}
                status={["assignment request", "withdrawal request"]}
                onChange={(query, sortKey, isSortAsc, page, status) => {
                  let url = `?fields=*.*.*&limit=10&meta=filter_count&filter[is_temporary]=1`;
                  let v = `${url}&offset=${
                    (page - 1) * 10
                  }&limit=10&meta=filter_count`;
                  if (query.length > 0) {
                    v += `&filter[protector.name][like]=${query}&offset=${
                      (page - 1) * 10
                    }`;
                  }
                  if (sortKey) {
                    v += `&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                  }
                  if (status) {
                    v += `&filter[status][in]=${status}`;
                  }
                  get(v);
                }}
                customColumns={[
                  {
                    heading: "Updated On",
                    renderer: (item) =>
                      item.updated_on && (
                        <span>
                          {moment(item.updated_on).format("DD-MM-YYYY @ HH:mm")}
                        </span>
                      ),
                  },
                ]}
                totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                items={data?.data || []}
                actionButtons={[
                  {
                    title: "APPROVE",
                    type: "success",
                    onClick: async (item) => {
                      setSelected(item);
                      setShow(true);
                    },
                  },
                  {
                    title: "REJECT",
                    type: "danger",
                    onClick: async (item) => {
                      if (item.status === "withdrawal request") {
                        await patch(item.id.toString(), {
                          status: "assigned",
                        });
                      } else {
                        await patch(item.id.toString(), {
                          status: "rejected",
                        });
                        await patchProtector(item.protector.id.toString(), {
                          status: "unassigned",
                        });
                      }
                      window.location.reload();
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {show && (
          <ApproveModal
            onClose={() => setShow(false)}
            isWithdrawal={selected.status === "withdrawal request"}
            onSave={async (orderDate, orderNo) => {
              if (selected.status === "withdrawal request") {
                await patch(selected.id.toString(), {
                  status: "withdrawal pending",
                  order_no: orderNo,
                  order_date: orderDate,
                });
              } else {
                await patch(selected.id.toString(), {
                  status: "assigned",
                  order_no: orderNo,
                  order_date: orderDate,
                });
                await patchProtector(selected.protector.id.toString(), {
                  status: "assigned",
                });
              }
              window.location.reload();
            }}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
