import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    collapsed: false,
  },
  reducers: {
    setCollapse: (state, action) => {
        state.collapsed = action.payload
    },
  },
});

export const { setCollapse } = uiSlice.actions;

export default uiSlice.reducer;
