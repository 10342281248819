import React, { useEffect, useMemo, useRef, useState } from "react";
import { Diagram2Fill, Tags } from "react-bootstrap-icons";
import ReactToPrint from "react-to-print";
import useFetch from "use-http";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
import { Parser } from "json2csv";

const border = {
  border: "2px solid #999",
};

const sortInSeq = (list: any, seq: string[]) => {
  const data: any[] = [];
  seq?.forEach((e) => {
    let filteredData = list?.filter((li) => li?.Category === e) || [];
    data?.push(...filteredData);
  });
  return data;
};
interface Props {}

const PersonnelReport = (props: Props) => {
  const personnelReportRef = useRef<any>();
  const [data, setData] = useState<any[]>([]);
  const [filterCategory, setFilterCategory] = useState<string>("all");
  const [filterOrg, setFilterOrg] = useState<string>("all");
  const { get, loading } = useFetch(`custom/reports/protectee`);
  //   useEffect(() => {
  //     get().then((res) => {
  //       setData(res?.data);
  //       console.log(res?.data);
  //     });
  //   }, []);
  const { data: datalist, loading: loadingSeq } = useFetch(
    `items/designation?fields=title`,
    {},
    []
  );
  const getDatainarr = (data) => {
    return data?.map((e) => e?.title);
  };
  useEffect(() => {
    const listSeq = datalist?.data && getDatainarr(datalist?.data);
    if (data.length === 0) {
      get().then((res: any) => {
        setData(sortInSeq(res?.data, listSeq));
      });
    }
  }, [datalist, get]);

  const filteredData = useMemo(() => {
    // const temp = data;
    if (data.length > 0) {
      return data
        .filter((d) =>
          filterCategory === "all" ? d : d.Category === filterCategory
        )
        .filter((d) =>
          filterOrg === "all" ? d : d.Organisation === filterOrg
        );
    }
  }, [filterCategory, filterOrg, data]);
  const countTotal = (type: string) => {
    return filteredData?.reduce(
      (total, personnel) => total + Number(personnel[type]),
      0
    );
  };

  function downloadReport(data) {
    if (data?.length > 0) {
      const parser = new Parser({
        fields: Object.keys(data[0]),
      });
      save("Personnel_Report.csv", parser.parse(data));
    }
  }
  function save(filename, data) {
    var blob = new Blob([data], { type: "text/csv" });
    if (typeof window.navigator.msSaveOrOpenBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
  console.log("get Personnel Report data", data);
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mx-2 mr-4">
        <ReactToPrint
          removeAfterPrint={true}
          trigger={() => (
            <button className="ml-4 btn btn-secondary" disabled={loading}>
              Print
            </button>
          )}
          content={() => personnelReportRef.current}
        />
        <form className="d-flex">
          <div className="form-group">
            <label htmlFor="category" className="">
              Category
            </label>
            <select
              name=""
              id="category"
              className="form-control mr-3"
              value={filterCategory}
              style={{
                width: "300px",
                maxWidth: "fit-content",
                // minWidth: "max-content",
              }}
              onChange={(e) => {
                setFilterCategory(e.target.value);
              }}
            >
              <option value="all">All</option>
              {Array.from(new Set(data?.map((d) => d.Category)))?.map(
                (each) => (
                  <option value={each}>{each}</option>
                )
              )}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="org" className="form-label">
              Organization
            </label>
            <select
              name=""
              id="org"
              className="form-control"
              value={filterOrg}
              style={{
                width: "300px",
                maxWidth: "fit-content",
                // minWidth: "max-content",
              }}
              onChange={(e) => {
                setFilterOrg(e.target.value);
              }}
            >
              <option value="all">All</option>
              {Array.from(new Set(data?.map((d) => d.Organisation)))?.map(
                (each) => (
                  <option value={each}>{each}</option>
                )
              )}
            </select>
          </div>
        </form>
      </div>
      {loading ? (
        <LoadingScreen text="Loading Report..." />
      ) : (
        <div className="  p-4 mt-4 " ref={personnelReportRef}>
          <div className="d-flex justify-content-between">
            <p></p>
            <h3 className="text-center my-2 ">Personnel assigned by Rank</h3>
            <button
              title="Download as csv"
              onClick={() => downloadReport(filteredData)}
            >
              Download as csv
            </button>
          </div>
          <div>
            <table
              className="table table-bordered"
              style={{ fontSize: "0.8em" }}
            >
              <thead>
                <tr className="">
                  <td>Sr No.</td>
                  <td>Protectee Name</td>
                  <td>Category</td>
                  <td>Organisation</td>
                  <td>Text</td>
                  <td>District</td>
                  <td>Norms</td>
                  <td>Total Punjab Police Deployment</td>
                  <td>By Police District</td>
                  <td>By CDO</td>
                  <td>Center Deployment</td>
                  <td>By Armed</td>
                  <td>Other Deployment</td>
                  <td>Vehicles</td>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((personnel, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{personnel["Protectee Name"]}</td>
                    <td>{personnel["Category"]}</td>
                    <td>{personnel["Organisation"]}</td>
                    <td>{personnel["Text"]}</td>
                    <td>{personnel["District"]}</td>
                    <td>{personnel["norms"]}</td>
                    <td>{personnel["Total Punjab Police Deployment"]}</td>
                    <td>{personnel["By Police District"]}</td>
                    <td>{personnel["By CDO"]}</td>
                    <td>{personnel["Center Deployment"]}</td>
                    <td>{personnel["By Armed"]}</td>
                    <td>{personnel["Other Deployment"]}</td>
                    <td>{personnel["Vehicles"]}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={7} className="text-right text-bold">
                    Total
                  </td>
                  <td className="text-bold">
                    {countTotal("Total Punjab Police Deployment")}
                  </td>
                  <td className="text-bold">
                    {countTotal("By Police District")}
                  </td>
                  <td className="text-bold">{countTotal("By CDO")}</td>
                  <td className="text-bold">
                    {countTotal("Center Deployment")}
                  </td>
                  <td className="text-bold">{countTotal("By Armed")}</td>
                  <td className="text-bold">
                    {countTotal("Other Deployment")}
                  </td>
                  <td className="text-bold">{countTotal("Vehicles")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PersonnelReport;
