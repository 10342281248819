import React, { ReactElement } from "react";
import { ESelect } from "../library";
import MultipleCheckboxes from "./MultipleCheckboxes";

interface Props<T> {
  title?: string;
  defaultItem?: T;
  onSubmit: (item: T, isEdit: boolean) => Promise<any>;
  onClose: () => void;
  fields: {
    isDummy?: boolean;
    type:
      | "number"
      | "checkbox"
      | "select"
      | "custom-select"
      | "multiple-checkboxes"
      | "text"
      | "email"
      | "date"
      | "text-area";
    key: string;
    multipleCheckboxesOptions?: {
      items: any[];
      labelKey: string;
      defaultSelected: any[];
    };
    customSelectOptions?: {
      onQuery?: (q, setItems) => Promise<void>;
      filterKey: string;
      renderItem: (item) => JSX.Element;
      isMulti?: boolean;
      defaultValues?: any[];
    };
    inputOptions?: {
      defaultValue?: string;
      disabled?: boolean;
    };
    onChange?: (value) => void;
    label: string;
    selectItems?: {
      labelKey: string;
      items: any[];
    };
  }[];
}

export default function AddEditModal<T>({
  defaultItem,
  title,
  onSubmit,
  onClose,
  fields = [],
}: Props<T>): ReactElement {
  const [item, setItem] = React.useState<any>(defaultItem);
  React.useEffect(() => {
    console.log(item);
  }, [item]);
  return (
    <>
      <div className="modal-backdrop"></div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onSubmit(item!, Boolean(defaultItem));
          onClose();
        }}
      >
        <div
          className="modal"
          style={{ display: "block" }}
          tabIndex={-1}
          role="dialog"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">
                  {defaultItem ? "Edit" : "Add"} {title}
                </h3>
                <button
                  type="button"
                  onClick={() => onClose()}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body row">
                {fields.flatMap((field, index) => {
                  if (field.type === "custom-select") {
                    return (
                      <div key={index} className="form-group col-lg-6">
                        <ESelect<any>
                          filterKey={field.customSelectOptions!.filterKey}
                          items={field.selectItems!.items}
                          label={field.label}
                          renderItem={(item) =>
                            field.customSelectOptions!.renderItem(item)
                          }
                          defaultValues={
                            field?.customSelectOptions?.defaultValues
                              ? field?.customSelectOptions?.defaultValues
                              : defaultItem?.[field.key]
                              ? [defaultItem?.[field.key]]
                              : undefined
                          }
                          isMulti={field.customSelectOptions?.isMulti}
                          onQuery={field.customSelectOptions!.onQuery}
                          onValuesChange={(items) => {
                            if (field.customSelectOptions?.isMulti) {
                              if (items.length) {
                                if (field.onChange) field.onChange(items);
                                if (field.isDummy) {
                                  return;
                                }
                                setItem({
                                  ...item,
                                  [field.key]: items,
                                });
                              }
                            } else {
                              if (items.length && items[0]?.id) {
                                if (field.onChange) field.onChange(items);
                                if (field.isDummy) {
                                  return;
                                }
                                setItem({
                                  ...item,
                                  [field.key]: items[0],
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    );
                  } else if (field.type === "select") {
                    return (
                      <div key={index} className="form-group col-lg-6">
                        <label htmlFor="type">{field.label}</label>
                        <select
                          onChange={(e) => {
                            if (field.onChange)
                              field.onChange(Number(e.target.value));
                            if (field.isDummy) {
                              return;
                            }
                            setItem({
                              ...item,
                              [field.key]: field.selectItems?.items.find(
                                (a) => a.id === Number(e.target.value)
                              ),
                            });
                          }}
                          defaultValue={
                            defaultItem?.[field.key]
                              ? defaultItem?.[field.key].id
                              : "-1"
                          }
                          className="form-control"
                          name={field.key}
                          id={field.key}
                        >
                          <option value="-1" selected disabled>
                            Select {field.label}
                          </option>
                          {field.selectItems?.items.flatMap((item, i) => (
                            <option
                              selected={
                                defaultItem?.[field.key]
                                  ? defaultItem?.[field.key].id === item.id
                                  : false
                              }
                              key={i}
                              value={item.id}
                            >
                              {field.selectItems
                                ? item[field.selectItems.labelKey]
                                : item.id}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  } else if (field.type === "checkbox") {
                    return (
                      <div key={index} className="col-lg-6">
                        <div className="form-group  form-check">

                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={field.key}
                            onChange={(e) => {
                              if (field.onChange)
                                field.onChange(e.target.value);
                              if (field.isDummy) {
                                return;
                              }
                              setItem({
                                ...item,
                                [field.key]: e.target.checked,
                              });
                            }}
                            defaultChecked={defaultItem?.[field.key]}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={field.key}
                          >
                            {field.label}
                          </label>
                        </div>
                      </div>
                    );
                  } else if (field.type === "text-area") {
                    return (
                      <div key={index} className="form-group col-lg-12">
                        <label htmlFor={field.key}>{field.label}</label>
                        <textarea
                          className="form-control"
                          name={field.key}
                          onChange={(e) => {
                            if (field.onChange) field.onChange(e.target.value);
                            if (field.isDummy) {
                              return;
                            }
                            setItem({
                              ...item,
                              [field.key]: e.target.value,
                            });
                          }}
                          id={field.key}
                          style={{ height: 120 }}
                          defaultValue={defaultItem?.[field.key]}
                        ></textarea>
                      </div>
                    );
                  } else if (field.type === "multiple-checkboxes") {
                    return (
                      <div key={index} className="form-group form-check">
                        <label>{field.label}</label>
                        <MultipleCheckboxes
                          labelKey={field.multipleCheckboxesOptions?.labelKey || 'title'}
                          items={field.multipleCheckboxesOptions?.items || []}
                          defaultSelected={
                            field.multipleCheckboxesOptions?.defaultSelected
                              ? field.multipleCheckboxesOptions?.defaultSelected
                              : defaultItem?.[field.key] || []
                          }
                          onChange={(selected) => {
                            console.log(selected);
                            setItem({
                              ...item,
                              [field.key]: selected,
                            });
                          }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="form-group  col-lg-6">
                        <label htmlFor={field.key}>{field.label}</label>
                        <input
                          type={field.type}
                          className="form-control"
                          name={field.key}
                          id={field.key}
                          onChange={(e) => {
                            if (field.onChange) field.onChange(e.target.value);
                            if (field.isDummy) {
                              return;
                            }
                            setItem({
                              ...item,
                              [field.key]: e.target.value,
                            });
                          }}
                          defaultValue={
                            field.inputOptions?.defaultValue
                              ? field.inputOptions?.defaultValue
                              : defaultItem?.[field.key]
                          }
                          disabled={field.inputOptions?.disabled}
                          placeholder={field.label}
                        />
                      </div>
                    );
                  }
                })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-muted"
                  data-dismiss="modal"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
