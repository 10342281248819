import moment from "moment";
import React, { ReactElement } from "react";
import { Plus } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import AddEditModal from "../../../common/components/AddEditModal";
import ETable from "../../../common/components/ETable/ETable";
import { LoadingScreen } from "../../../common/components/LoadingScreen";
// import { Protector } from "../../../common/models/models";

export default function TemporaryAssignment(): ReactElement {
  const userDetail = useSelector((state: any) => state.user.userDetail);
  const [show, setShow] = React.useState(false);
  //   const { patch } = useFetch(`items/protector_assignment`);
  const { patch: assignProtector } = useFetch(`items/protector`);
  const { data: dataProtectors, get: getProtectors } = useFetch(
    `items/protector`
  );
  const { data: dataWeapons } = useFetch(`items/weapon_type`, {}, []);
  const { data: dataDT } = useFetch(`items/deployment_type`, {}, []);
  const { data: dataProtectees, get: getProtectees } = useFetch(
    `items/protectee`
  );
  React.useEffect(() => {
    let url = `?fields=id,name`;
    if (userDetail.unit?.formation.title === "Police District") {
      url += `&filter[district]=${userDetail.unit?.id}`;
    }
    getProtectees(url);
  }, [getProtectees, userDetail]);
  //   const [selected, setSelected] = React.useState<Protector | undefined>();
  const { get, data } = useFetch(`items/protector_assignment`, {
    cache: "no-cache",
  });
  const { post, patch, del } = useFetch(`items/protector_assignment`);
  React.useEffect(() => {
    let url = `?fields=*.*&limit=10&meta=filter_count&filter[unit.formation]=${userDetail.formation.id}&filter[status]=unassigned`;
    if (userDetail.unit) {
      url = `?fields=*.*&limit=10&meta=filter_count&filter[unit]=${userDetail.unit.id}&filter[status]=unassigned`;
    }
    getProtectors(url);
  }, [getProtectors, userDetail]);

  React.useEffect(() => {
    let url = `?fields=*.*.*&limit=10&meta=filter_count&filter[protector.unit.formation]=${userDetail.formation.id}&filter[status][in]=assignment request,assigned&filter[is_temporary]=1`;
    if (userDetail.unit) {
      url = `?fields=*.*.*&limit=10&meta=filter_count&filter[protector.unit]=${userDetail.unit.id}&filter[status][in]=assignment request,assigned&filter[is_temporary]=1`;
    }
    get(url);
  }, [get, userDetail]);
  if (data) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Temporary Personnel Assignment</h1>
          </div>
          <div>
            <button
              className="btn btn-secondary"
              onClick={() => {
                //   setSelected(undefined);
                setShow(true);
              }}
            >
              <Plus /> Add Temporary Assignment
            </button>
          </div>
        </div>
        <div className="main-body">
          <div className="card h-auto">
            <div className="card-body">
              <ETable<any>
                columns={[
                  { key: "id", label: "Id" },
                  { key: "protectee.name", label: "Protectee Name" },
                  { key: "protector.name", label: "Personnel Name" },
                  { key: "updated_on", label: "Updated On" },
                ]}
                status={[
                  "assigned",
                  "assignment request",
                  "withdrawal request",
                  "withdrawal pending",
                  "unassigned",
                  "rejected",
                ]}
                onChange={(query, sortKey, isSortAsc, page, status) => {
                  let url = `?fields=*.*.*&limit=10&meta=filter_count&filter[protector.unit.formation]=${userDetail.formation.id}&filter[is_temporary]=1`;
                  if (userDetail.unit) {
                    url = `?fields=*.*.*&limit=10&meta=filter_count&filter[protector.unit]=${userDetail.unit.id}&filter[is_temporary]=1`;
                  }
                  let v = `${url}&offset=${
                    (page - 1) * 10
                  }&limit=10&meta=filter_count`;
                  if (query.length > 0) {
                    v += `&filter[protector.name][like]=${query}&offset=${
                      (page - 1) * 10
                    }`;
                  }
                  if (sortKey) {
                    v += `&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                  }
                  if (status) {
                    v += `&filter[status][in]=${status}`;
                  }
                  get(v);
                }}
                customColumns={[
                  {
                    heading: "Status by PPHQ Security",
                    renderer: (item) => (
                      <span className="badge text-uppercase badge-primary">
                        {item.status}
                      </span>
                    ),
                  },
                  {
                    heading: "Updated On",
                    renderer: (item) =>
                      item.updated_on && (
                        <span>
                          {moment(item.updated_on).format("DD-MM-YYYY @ HH:mm")}
                        </span>
                      ),
                  },
                ]}
                totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                items={data?.data || []}
                actionButtons={(item) => {
                  if (item.status === "assigned")
                    return [
                      {
                        title: "Request Withdrawal",
                        type: "",
                        onClick: async (item) => {
                          await patch(item.id.toString(), {
                            status: "withdrawal request",
                          });
                          window.location.reload();
                        },
                      },
                    ];
                  else if (item.status === "withdrawal pending")
                    return [
                      {
                        title: "Withdraw",
                        type: "",
                        onClick: async (item) => {
                          await assignProtector(item.protector.id.toString(), {
                            status: "unassigned",
                          });
                          await patch(item.id.toString(), {
                            status: "withdrawn",
                          });
                          window.location.reload();
                        },
                      },
                    ];
                  else return [];
                }}
              />
            </div>
          </div>
        </div>
        {show && (
          <AddEditModal
            onClose={() => setShow(false)}
            title="Personnel"
            onSubmit={async (item: any) => {
              await post({
                protectee: item.protectee?.id,
                protector: item.protector?.id,
                authority: item.authority,
                weapon_type: item.weapon_type?.id,
                no_of_rounds: item.no_of_rounds,
                to_date: item.to_date,
                unit_restriction: item.unit_restriction,
                formation_restriction: item.formation_restriction,
                status: "assignment request",
                deployment_type: item.deployment_type.id,
                is_temporary: true,
              });
              if (item.protector?.id) {
                await assignProtector(item.protector.id.toString(), {
                  status: "assigned",
                });
              }
              window.location.reload();
            }}
            fields={[
              {
                key: "protectee",
                label: "Protectee",
                type: "custom-select",
                customSelectOptions: {
                  filterKey: "name",
                  renderItem: (item) => <div>{item.name}</div>,
                },
                selectItems: {
                  labelKey: "name",
                  items: dataProtectees?.data,
                },
              },
              {
                key: "protector",
                label: "Personnel",
                type: "custom-select",
                customSelectOptions: {
                  filterKey: "belt_no",
                  renderItem: (item) => (
                    <div>
                      {item.name} ({item.belt_no}/{item.bn})
                    </div>
                  ),
                  onQuery: async (q, setItems) => {
                    let url = `?fields=*.*&limit=10&meta=filter_count&filter[unit.formation]=${userDetail.formation.id}&filter[status]=unassigned`;
                    if (userDetail.unit) {
                      url = `?fields=*.*&limit=10&meta=filter_count&filter[unit]=${userDetail.unit.id}&filter[status]=unassigned`;
                    }
                    const d = await getProtectors(`${url}&q=${q}`);
                    setItems(d.data || []);
                  },
                },
                selectItems: {
                  labelKey: "name",
                  items: dataProtectors?.data,
                },
              },
              {
                key: "deployment_type",
                label: "Deployment Type",
                type: "custom-select",
                customSelectOptions: {
                  filterKey: "title",
                  renderItem: (item) => <div>{item.title}</div>,
                },
                selectItems: {
                  labelKey: "title",
                  items: dataDT?.data,
                },
              },
              {
                key: "authority",
                type: "text",
                label: "Authority",
              },
              {
                key: "weapon_type",
                label: "Weapon Type",
                type: "custom-select",
                customSelectOptions: {
                  filterKey: "title",
                  renderItem: (item) => <p>{item.title}</p>,
                },
                selectItems: {
                  labelKey: "title",
                  items: dataWeapons?.data,
                },
              },
              {
                key: "no_of_rounds",
                type: "number",
                label: "No of Rounds",
              },
              {
                key: "to_date",
                type: "date",
                label: "To Date",
              },
            ]}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
