import React, { ReactElement } from "react";

interface Props {
  title?: string;
  onConfirm: () => void;
  onClose: () => void;
  confirmText?: string;
  cancelText?: string;
}

export default function ConfirmModal({
  title,
  onConfirm,
  onClose,
  confirmText,
  cancelText,
}: Props): ReactElement {
  return (
    <>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        style={{ display: "block" }}
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title text-dark">{title}</h3>
              <button
                type="button"
                onClick={() => onClose()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* <div className="modal-body row"></div> */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-muted btn-outline-secondary"
                data-dismiss="modal"
                onClick={() => onClose()}
              >
                {cancelText || "No"}
              </button>
              <button
                type="button"
                onClick={() => onConfirm()}
                className="btn btn-secondary"
              >
                {confirmText || "Yes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
