import React, { ReactElement } from "react";
import moment from "moment";
import useFetch from "use-http";
import ETable from "../../../common/components/ETable/ETable";
import { ASSIGNMENT_PATHS } from "../../../reducers/routes";
import { LoadingScreen } from "../../../common/components/LoadingScreen";

export default function ProtecteeListDistrictPage(): ReactElement {
  const [state, setState] = React.useState<{
    page: number;
    query: string;
    sortBy: string;
    isAsc: boolean;
  }>({
    page: 1,
    query: "",
    sortBy: "id",
    isAsc: true,
  });
  const { get, data } = useFetch(`custom/protectee`, {
    cache: "no-cache",
  });
  React.useEffect(() => {
    let url = "?limit=10";
    if (state?.query && state.query.length > 0) url += `&query=${state.query}`;
    if (state?.page) url += `&offset=${(state.page - 1) * 10}`;
    if (state?.sortBy)
      url += `&sort=${state.isAsc ? state.sortBy : "-" + state.sortBy}`;
    get(url);
  }, [get, state]);

  if (data) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Protectees</h1>
          </div>
        </div>
        <div className="main-body">
          <div className="card h-auto">
            <div className="card-body">
              <ETable<any>
                columns={[
                  { key: "id", label: "Id" },
                  { key: "name", label: "Protectee Name" },
                  { key: "basis", label: "Security Basis" },
                  { key: "designation", label: "Category" },
                  { key: "organisation", label: "Organisation" },
                  { key: "text", label: "Text" },
                ]}
                totalPages={Math.ceil(data?.count / 10) || 0}
                showPagesText={false}
                customColumns={[
                  {
                    heading: "Status",
                    renderer: (item) => {
                      if (item.status !== "complete") {
                        return (
                          <span className="badge badge-warning">
                            {item.status.toUpperCase()}
                          </span>
                        );
                      } else if (moment(item.review_date).isBefore())
                        return (
                          <span className="badge badge-danger">OVERDUE</span>
                        );
                      else if (
                        moment(item.review_date).diff(moment(), "days") < 30
                      ) {
                        return (
                          <span className="badge badge-warning">PENDING</span>
                        );
                      } else {
                        return (
                          <span className="badge badge-success">COMPLETE</span>
                        );
                      }
                    },
                  },
                ]}
                onChange={(query, sortKey, isSortAsc, page) => {
                  setState({
                    query,
                    sortBy: sortKey,
                    isAsc: isSortAsc,
                    page,
                  });
                }}
                items={data?.data || []}
                actionButtons={(i) => {
                  const r: any[] = [];
                  if (i.status === "complete") {
                    r.push({
                      title: "Manage",
                      onClick: (item) => {
                        if (
                          moment(item.review_date).isBefore() ||
                          moment(item.review_date).diff(moment(), "days") < 30
                        ) {
                          // window.location.href="/protectee/"+item.id;
                        }
                        window.location.href = "/protectee/" + item.id;
                      },
                      type: "primary",
                      // moment(i.review_date).isBefore() ||
                      // moment(i.review_date).diff(moment(), "days") < 30
                      //   ? "primary"
                      //   : "muted",
                    });

                    r.push({
                      title: "Profile",
                      onClick: (item) => {
                        window.location.href =
                          ASSIGNMENT_PATHS.protectees + "/" + item.id;
                      },
                    });
                  }
                  return r;
                }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
