import React, { ReactElement, SyntheticEvent } from "react";
import {
  Check,
  ChevronDown,
  ChevronUp,
  PlusCircle,
  X,
} from "react-bootstrap-icons";

interface Props<T> {
  items: T[];
  style?: React.CSSProperties;
  label: string;
  filterKey: string;
  showAddItem?: boolean;
  onAddItemClick?: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
  renderItem?: (item: T, index?: number) => ReactElement;
  showDropdown?: boolean;
  isMulti?: boolean;
  labelHelp?: JSX.Element;
  defaultValues?: T[];
  onQuery?: (q: string, setItems) => Promise<void>;
  onValuesChange?: (items: T[]) => void;
}

export default function ESelect<T>({
  items = [],
  filterKey,
  label,
  renderItem = (item, i) => <div key={i}>{JSON.stringify(item)}</div>,
  showAddItem = false,
  onAddItemClick,
  onValuesChange = (items) => console.log(items),
  isMulti = false,
  defaultValues = [],
  showDropdown = false,
  labelHelp,
  onQuery,
  style,
}: Props<T>): ReactElement {
  const [mItems, setMItems] = React.useState<T[]>(items);
  const [selectedItems, setSelectedItems] = React.useState<T[]>(defaultValues?.filter(a => a !== undefined) || []);
  const [query, setQuery] = React.useState<string>("");
  const itemBackup = React.useRef() as React.MutableRefObject<T>;
  const [mShow, setMShow] = React.useState<boolean>(showDropdown);
  const selectedDiv = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const dropdownDiv = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  React.useEffect(() => {
    const values = defaultValues?.filter(a => a !== undefined);
    setMItems(items);
    if (values.length>0) {
      setSelectedItems(values);
    }
  }, [items,setSelectedItems,setMItems]);
  React.useEffect(() => {
    setMShow(showDropdown);
  }, [showDropdown]);
  React.useEffect(() => {
    if (mItems.length > 0 && mItems[0] !== undefined) {
        onValuesChange(selectedItems);
    }
  }, [selectedItems, mItems]);
  return (
    <div
      className="e-select"
      onBlur={() => {
        if (!isMulti) {
          setSelectedItems([]);
          if (!selectedItems[0] && itemBackup.current) {
            setSelectedItems([itemBackup.current]);
          }
        }
        if (dropdownDiv.current) dropdownDiv.current.style.opacity = "0";
        setTimeout(() => {
          setMShow(false);
        }, 200);
      }}
    >
      {selectedItems.length > 0 && (
        <div className="selected-items" ref={selectedDiv}>
          {isMulti ? (
            selectedItems.flatMap((item, i) => (
              <div
                onClick={() => {
                  selectedItems.splice(
                    selectedItems.findIndex(
                      (a) => a[filterKey] === item[filterKey]
                    ),
                    1
                  );
                  setSelectedItems([...selectedItems]);
                  setMShow(false);
                }}
                key={i}
                className="item"
              >
                {item[filterKey]}
                <X />
              </div>
            ))
          ) : (
              <div className="item single">{selectedItems[0]?.[filterKey]}</div>
              // <div className="item single">{JSON.stringify(selectedItems)}</div>
            )}
        </div>
      )}
      <div className="form-group">
        <label className="pr-1">{label}</label>
        {labelHelp}
        <input
          type="text"
          value={query}
          // onBlur={() => {
          //   setMShow(false)
          // }}
          placeholder={selectedItems.length === 0 ? label : ""}
          style={{
            paddingLeft:
              selectedDiv && selectedDiv.current && selectedItems.length > 0
                ? selectedDiv.current.clientWidth + 8 + "px"
                : "0.75rem",
            ...style,
          }}
          onFocus={() => {
            if (!isMulti) {
              if (selectedItems.length > 0) {
                itemBackup.current = { ...selectedItems[0] };
                setSelectedItems([]);
              }
              if (query.length === 0 && !itemBackup.current) {
                setSelectedItems([]);
              }
            }
            setMShow(true);
          }}
          onChange={async (e) => {
            setQuery(e.target.value);
            if (!onQuery) {
              try {
                setMItems(
                  items.filter(
                    (i) =>
                      !i[filterKey]
                        .toLowerCase()
                        .search(e.target.value.toLowerCase())
                  )
                );
              } catch {
                console.log("Error");
              }
            } else {
              await onQuery(e.target.value, setMItems);
            }
          }}
          className="form-control"
        />
        <div onClick={() => setMShow((a) => !a)} className="icon">
          {mShow ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>
      {mShow && (
        <div ref={dropdownDiv} className="e-select-container">
          {mItems.length > 0 ? (
            mItems.flatMap((item, i) => {
              const isSelected =
                selectedItems.findIndex(
                  (a) => a[filterKey] === item[filterKey]
                ) !== -1;
              return (
                <div
                  key={i}
                  onClick={() => {
                    if (
                      isMulti &&
                      selectedItems.findIndex(
                        (a) => a[filterKey] === item[filterKey]
                      ) === -1
                    ) {
                      setSelectedItems([...selectedItems, item]);
                    } else if (!isMulti) {
                      setSelectedItems([item]);
                    }
                    setMShow(false);
                    setQuery("");
                    setMItems([...items]);
                  }}
                  className={"e-select-item " + (isSelected ? "selected" : "")}
                >
                  {renderItem(item, i)}
                  {isSelected && (
                    <div className="icon-selected">
                      <Check />
                    </div>
                  )}
                </div>
              );
            })
          ) : showAddItem ? (
            <div className="e-select-item--add-new">
              <button className="btn btn-link" onClick={onAddItemClick}>
                <PlusCircle /> Create new {label}
              </button>
            </div>
          ) : (
                <div className="e-select-item">
                  <span className="text-muted">No items found</span>
                </div>
              )}
        </div>
      )}
    </div>
  );
}
