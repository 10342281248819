import React from "react";
import LoginPage from "./pages/login/login";
import HomePage from "./pages/home/home";
import { useSelector } from "react-redux";
import AppWrapper from "./common/components/AppWrapper";
import { IRoute, IUser } from "./reducers/types";
import logo from "./assets/images/logo.png";
import { userSlice } from "./reducers/user";
import {
  ArrowRightShort,
  Bell,
  Check2Square,
  PersonCheckFill,
  Power,
} from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ASSIGNMENT_PATHS, PATHS, routeSlice } from "./reducers/routes";
import "overlayscrollbars/css/OverlayScrollbars.css";
import ProtecteePage from "./pages/protectee/protectee";
import { API_URL } from ".";
import useFetch, { Provider } from "use-http";
import ProtecteeListPage from "./pages/protectee-list";
import ProtectorsPage from "./pages/protectors/protectors";
import ReportsPage from "./pages/reports/report";
import ProtecteeProfile from "./pages/protectee-list/components/protectee-profile";
import VehiclesPage from "./pages/vehicles/vehicles";
import PersonnelPage from "./pages/non-admin/personnel/personnel";
import PersonnelAssignment from "./pages/non-admin/personnel-assignment/personnel-assignment";
import TemporaryAssignment from "./pages/non-admin/temporary-assignment/temporary-assignment";
import ApprovalPage from "./pages/approval/approval";
import BulkWithdrawPage from "./pages/bulk_withdraw/bulk-withdraw";
import BulkWithdrawRequestPage from "./pages/non-admin/bulk_withdraw_request/bulk-withdraw-request";
import ProtecteeDistrictPage from "./pages/non-admin/protectee/protectee";
import ProtecteeProfileDistrict from "./pages/non-admin/protectee-list/components/protectee-profile";
import ProtecteeListDistrictPage from "./pages/non-admin/protectee-list";
import NonAdminReportsPage from "./pages/non-admin/reports/report";
import { spawn } from "child_process";
import ConfirmModal from "./common/components/ConfirmModal";
import ReportsView from "./pages/reports/ReportsView";

function App() {
  const [isLoggedIn, routes, user, token, userDetail]: [
    boolean,
    IRoute[],
    IUser,
    string,
    any
  ] = useSelector((state: any) => [
    state.user.isLoggedIn,
    state.routes.routes,
    state.user.user,
    state.user.token,
    state.user.userDetail,
  ]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isLoggedIn) {
      if (user.role === "3") {
        dispatch(routeSlice.actions.setRoutes("admin"));
      } else {
        dispatch(routeSlice.actions.setRoutes("assignment"));
      }
    }
  }, [isLoggedIn, dispatch, user?.role]);
  const [goInchargeName, setGoInchargeName] = React.useState(
    (userDetail?.unit.go_incharge && userDetail?.unit.go_incharge) || ""
  );
  const [editGO, setEditGO] = React.useState(false);
  const [confirmShow, setConfirmShow] = React.useState(false);
  const [editGoConfirm, setEditGoConfirm] = React.useState(false);
  const { patch } = useFetch(`${API_URL}pb-police/items/unit`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const updateGo = () => {
    patch(userDetail?.unit.id.toString(), { go_incharge: goInchargeName })
      .then((res) => {
        let d = JSON.parse(JSON.stringify(userDetail));
        d.unit.go_incharge = goInchargeName;
        dispatch(userSlice.actions.setUserDetail(d));
        setEditGO(false);
      })
      .catch((err) => {
        console.error("erorr", err);
      });
    setEditGO(false);
  };
  return (
    <Provider
      url={API_URL + "pb-police"}
      options={{
        headers: {
          Authorization: `Bearer ${token}`,
        },
        interceptors: {
          response: async ({ response }) => {
            if (response.status === 401) {
              dispatch({
                type: "logout",
              });
              window.location.href = "/";
            } else if (response.status >= 200 && response.status < 400) {
              if (!localStorage.getItem("refreshing")) {
                localStorage.setItem("refreshing", "1");
                fetch(API_URL + "pb-police/auth/refresh", {
                  body: JSON.stringify({ token: token }),
                  method: "post",
                  headers: {
                    "Content-type": "application/json",
                  },
                }).then((res) => {
                  localStorage.removeItem("refreshing");
                  res.json().then((a) => dispatch(userSlice.actions.setToken));
                });
              }
            }
            return response;
          },
        },
      }}
    >
      <>
        {isLoggedIn ? (
          <AppWrapper
            sideBar={
              <React.Fragment>
                <div className="header">
                  <div className="branding">
                    <div className="logo">
                      <img className="img-fluid" src={logo} alt="" />
                    </div>
                    <div>
                      <p className="pb-0">
                        <span className="text-bold">
                          Punjab Security
                          <br /> Allocation Portal{" "}
                        </span>
                        <br /> PSAP
                      </p>
                    </div>
                  </div>
                  <div className="username">
                    <small className="text-faded pb-0">Welcome back,</small>
                    <p className="mb-0">
                      {user.first_name + " " + user.last_name}
                    </p>
                    {userDetail && (
                      <>
                        <small>
                          {userDetail.unit
                            ? userDetail.unit.title + " Unit"
                            : userDetail.formation.title + " Formation"}
                        </small>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                            GO Incharge:
                            {editGO ? (
                              <input
                                type="text"
                                value={goInchargeName}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  setGoInchargeName(value);
                                }}
                              />
                            ) : (
                              <span>
                                {" "}
                                {userDetail.unit.go_incharge
                                  ? userDetail.unit.go_incharge
                                  : "NA"}
                              </span>
                            )}
                          </span>
                          {editGO ? (
                            <button
                              style={{
                                background: "none",
                                outline: "none",
                                border: "none",
                                color: "#fff",
                              }}
                              onClick={() => setEditGoConfirm(true)}
                            >
                              <Check2Square size={20} />
                            </button>
                          ) : (
                            <button
                              style={{
                                background: "none",
                                outline: "none",
                                border: "none",
                                color: "#fff",
                              }}
                              onClick={() => {
                                setEditGO(true);
                              }}
                            >
                              <PersonCheckFill size={20} />
                            </button>
                          )}
                        </div>
                      </>
                    )}
                    {editGoConfirm && (
                      <ConfirmModal
                        title="Are you sure you want Update GO Incharge?"
                        onConfirm={() => {
                          updateGo();
                          setEditGoConfirm(false);
                        }}
                        onClose={() => {
                          setEditGoConfirm(false);
                          setEditGO(false);
                          setGoInchargeName(
                            (userDetail?.unit.go_incharge &&
                              userDetail?.unit.go_incharge) ||
                              ""
                          );
                        }}
                        confirmText="update"
                        cancelText="cancel"
                      />
                    )}
                  </div>
                </div>
                <div className="nav">
                  {routes.flatMap((route: IRoute) => (
                    <div
                      key={route.slug}
                      onClick={() => {
                        dispatch(routeSlice.actions.setActive(route));
                        window.location.href = route.route;
                      }}
                      className={`nav--item ${route.active ? "active" : ""}`}
                    >
                      {route.label}
                      <div className="icon">
                        <ArrowRightShort />
                      </div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            }
            headerRight={
              <React.Fragment>
                <div className="item">
                  <Bell />
                </div>
                <div
                  onClick={() => {
                    setConfirmShow(true);
                    // dispatch({ type: "logout" });
                    // window.location.href = "/";
                  }}
                  className="item"
                >
                  <Power />
                </div>
                {confirmShow && (
                  <ConfirmModal
                    title="Are you sure you want to logout?"
                    onConfirm={() => {
                      dispatch({ type: "logout" });
                      window.location.href = "/";
                    }}
                    onClose={() => setConfirmShow(false)}
                  />
                )}
              </React.Fragment>
            }
          >
            <Router>
              {user?.role === "3" ? (
                <Switch>
                  <Route exact path={PATHS.dashboard}>
                    <HomePage />
                  </Route>
                  <Route exact path={PATHS.protecteeEdit}>
                    <ProtecteePage />
                  </Route>
                  <Route exact path={PATHS.protectee}>
                    <ProtecteePage />
                  </Route>
                  <Route exact path={PATHS.protecteeSummary}>
                    <ProtecteeProfile />
                  </Route>
                  <Route exact path={PATHS.protectees}>
                    <ProtecteeListPage />
                  </Route>
                  <Route exact path={PATHS.approval}>
                    <ApprovalPage />
                  </Route>
                  <Route exact path={PATHS.protector}>
                    <ProtectorsPage />
                  </Route>
                  <Route exact path={PATHS.reports}>
                    <ReportsPage />
                  </Route>
                  <Route exact path={PATHS.reportView}>
                    <ReportsView />
                  </Route>
                  <Route exact path={PATHS.vehicles}>
                    <VehiclesPage />
                  </Route>
                  <Route exact path={PATHS.withdrawal}>
                    <BulkWithdrawPage />
                  </Route>
                  <Route path="/">
                    <Redirect to={PATHS.dashboard} />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  <Route exact path={ASSIGNMENT_PATHS.protector}>
                    <PersonnelPage />
                  </Route>
                  <Route exact path={ASSIGNMENT_PATHS.protectorAssignment}>
                    <PersonnelAssignment />
                  </Route>
                  <Route exact path={ASSIGNMENT_PATHS.protecteeEdit}>
                    <ProtecteeDistrictPage />
                  </Route>
                  <Route exact path={ASSIGNMENT_PATHS.protecteeSummary}>
                    <ProtecteeProfileDistrict />
                  </Route>
                  <Route exact path={ASSIGNMENT_PATHS.protectees}>
                    <ProtecteeListDistrictPage />
                  </Route>
                  <Route exact path={ASSIGNMENT_PATHS.temporary}>
                    <TemporaryAssignment />
                  </Route>
                  <Route exact path={ASSIGNMENT_PATHS.withdrawal}>
                    <BulkWithdrawRequestPage />
                  </Route>
                  <Route exact path={ASSIGNMENT_PATHS.reports}>
                    <NonAdminReportsPage />
                  </Route>
                  <Route path="/">
                    <Redirect to={ASSIGNMENT_PATHS.protector} />
                  </Route>
                </Switch>
              )}
            </Router>
          </AppWrapper>
        ) : (
          <LoginPage />
        )}
      </>
    </Provider>
  );
}

export default App;
