import React, { ReactElement } from "react";
import { QuestionCircleFill, Printer } from "react-bootstrap-icons";
import ReactToPrint from "react-to-print";

interface Props {
  content?: JSX.Element;
  title?: string;
}

export default function HelpIconModal({
  content,
  title = "Help",
}: Props): ReactElement {
  const [show, setShow] = React.useState<boolean>(false);
  const componentRef = React.useRef() as React.MutableRefObject<any>;

  return (
    <>
      <span role="button" className="text-muted" onClick={() => setShow(true)}>
        <QuestionCircleFill />
      </span>
      {show && (
        <>
          <div className="modal-backdrop"></div>
          <div
            className="modal"
            id="modelId"
            tabIndex={-1}
            role="dialog"
            style={{ display: "block" }}
            aria-labelledby="modelTitleId"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-scrollable modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{title}</h5>
                  <ReactToPrint
                    trigger={() => (
                      <button className="btn btn-outline-primary m-auto">
                        <Printer className="mr-2" />
                        Print {title}
                      </button>
                    )}
                    content={() => componentRef.current!}
                  />
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShow(false)}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" ref={componentRef}>
                  {content}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-muted"
                    onClick={() => setShow(false)}
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
