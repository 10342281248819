import React, { ReactElement, useEffect, useState } from "react";
// import { PrinterFill } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import useFetch from "use-http";
import { API_URL } from "../../..";
import { PATHS } from "../../../reducers/routes";
import CommentItem from "../../protectee/components/comment-item";
import moment from "moment";
import { TPRModal } from "../../protectee/components/tpr-modal";
import EFileUpload from "../../../common/components/EFileUpload/EFileUpload";
import ReactToPrint from "react-to-print";
import { Printer } from "react-bootstrap-icons";
import pso from "../../../assets/icons/pso.png";
import driver from "../../../assets/icons/driver.png";
import houseguard from "../../../assets/icons/houseguard.png";
import escortduty from "../../../assets/icons/escortduty.png";
import securityincharge from "../../../assets/icons/securityincharge.png";
import { LoadingScreen } from "../../../common/components/LoadingScreen";

export default function ProtecteeProfile(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const componentRef = React.useRef() as React.MutableRefObject<any>;
  const [attachment_url, setAttachment_url] = React.useState<string>();
  const { data } = useFetch(`items/protectee/${id}?fields=*.*.*`, {}, []);
  const { data: dataRelations } = useFetch(
    `items/protectee?fields=*.*&filter[relation_protectee]=${id}`,
    {},
    []
  );
  const [relationProtectee, setRelationProtectee] = React.useState(0);
  const { get: getAssignmentRelation } = useFetch(`items/protector_assignment`);
  React.useEffect(() => {
    if (data?.data.relation_protectee)
      getAssignmentRelation(
        `?meta=filter_count&filter[protectee]=${data?.data.relation_protectee.id}&filter[status][nin]=withdrawn`
      ).then((a) => {
        setRelationProtectee(
          a?.data.filter((x) => x.status !== "unassigned")?.length || 0
        );
      });
  }, [data, getAssignmentRelation]);
  const { data: dataPendingAssignment } = useFetch(
    `items/protector_assignment?meta=filter_count&filter[protectee]=${id}&filter[status]=assignment pending`,
    {},
    []
  );
  const { data: dataTemporaryAssignment } = useFetch(
    `items/protector_assignment?meta=filter_count&filter[protectee]=${id}&filter[status][nin]=unassigned,withdrawn&filter[is_temporary]=1`,
    {},
    []
  );
  const { get: getTotalAssignments } = useFetch(`items/protector_assignment`);
  const [newRelations, setNewRelations] = useState<any>();
  useEffect(() => {
    if (dataRelations?.data) {
      const promises: any[] = [];
      dataRelations.data.forEach((data) => {
        promises.push(
          getTotalAssignments(
            `?fields=protectee&filter[protectee]=${data.id}&meta=filter_count&filter[status][nin]=withdrawn,unassigned`
          )
        );
      });
      Promise.all(promises).then((rs) => {
        const v: any = {};
        rs.forEach((r) => {
          if (r?.data[0]) {
            v[r?.data[0].protectee] = r.meta.filter_count;
          }
        });
        setNewRelations(v);
      });
    }
  }, [dataRelations, getTotalAssignments, setNewRelations]);
  let { data: dataComments } = useFetch(
    `items/comment?filter[protectee]=${id}&sort=-created_on&fields=*.*`,
    {},
    []
  );
  const [show, setShow] = React.useState<boolean>(false);

  const [comments, setComments] = useState<any>([]);
  useEffect(() => {
    setComments(dataComments?.data);
  }, [dataComments]);
  const [refinedData, setRefinedData] = useState<any>();
  const { data: dataAssignment, loading: loadingDataAssignment } = useFetch(
    `items/protector_assignment?fields=*.*.*&filter[protectee]=${id}&filter[protector.id][neq]=null&filter[status][nin]=withdrawn,unassigned&filter[is_temporary]=0`,
    {},
    []
  );
  const { data: dataAddresses } = useFetch(
    `items/address?fields=*.*&filter[protectee]=${id}`,
    {},
    []
  );
  const { data: dataVehicles } = useFetch(
    `items/vehicle_assignment?fields=*.*&filter[protectee]=${id}`,
    {},
    []
  );
  const { data: dataLogs } = useFetch(
    `items/protectee/${id}/revisions?fields=*.*,activity.action_by.first_name,activity.action,activity.action_on`,
    {},
    []
  );
  const { data: dataLogsAssignment } = useFetch(
    `custom/stats/protector/logs?protectee=${id}`,
    {},
    []
  );
  const { data: dataTPR } = useFetch(
    `items/tpr_protectee?filter[protectee]=${id}`,
    {
      cache: "no-cache",
    },
    []
  );
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { post } = useFetch(`items/comment?fields=*.*`);
  const [comment, setComment] = React.useState("");
  const [type, setType] = React.useState("");
  const sortObj = (obj) => {
    const sortSeq = [
      "Security Incharge",
      "PSO",
      "Escort Duty",
      "House Guard",
      "Driver",
      "Technical Team",
    ];
    let sortedObj = {};
    sortSeq.map((e) => {
      if (obj[e]) sortedObj[e] = obj[e];
    });
    return sortedObj;
  };
  useEffect(() => {
    const d: any = {};
    if (dataAssignment?.data.length > 0) {
      dataAssignment?.data.forEach((protector) => {
        if (!d[protector.deployment_type.title]) {
          d[protector.deployment_type.title] = [protector];
        } else {
          d[protector.deployment_type.title].push(protector);
        }
      });
      // setRefinedData(sortObj(d));
      setRefinedData(d);
    }
  }, [dataAssignment, setRefinedData]);
  console.log("refine data",refinedData)
  if (!loadingDataAssignment) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <button
              onClick={() => (window.location.href = PATHS.protectees)}
              className="btn btn-link"
            >
              &larr; Back
            </button>
            {/* <h1 className="mb-1 text-primary">Jaspreet Singh</h1> */}
            {/* <p className="text-faded">Z+</p> */}
          </div>
          <ReactToPrint
            removeAfterPrint={true}
            pageStyle="@page { size: landscape; }"
            trigger={() => (
              <button className="btn btn-outline-primary m-auto">
                <Printer className="mr-2" />
                Print Profile
              </button>
            )}
            content={() => componentRef.current!}
          />
          <div className="">
            <button
              onClick={() => setShow(true)}
              className="btn btn-outline-primary mr-3"
            >
              Request TPR
            </button>
            {/* <button className="btn btn-secondary">
            <PrinterFill /> Print Profile
          </button> */}
          </div>
        </div>
        <div className="main-body" ref={componentRef}>
          <div className="">
            <div className="">
              <div className="row d-flex align-items-center">
                <div className="col-xl-6">
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar--xl avatar--rounded  mr-4">
                      <img
                        className="img-fluid"
                        src={API_URL + data?.data?.photo}
                        alt=""
                      />
                    </div>
                    <div>
                      <h1 className="">{data?.data?.name}</h1>
                      <h2 className="mb-4">
                        <span style={{ fontSize: "1rem" }}>Basis:</span>
                        <div className="badge badge-warning mx-1">
                          {data?.data?.category.title}
                        </div>
                        <div className="badge badge-warning mr-1">
                          {data?.data?.sub_category?.title}
                        </div>
                        {data?.data?.high_value_target && (
                          <div className="badge badge-danger">
                            Highly Threatened Protectee
                          </div>
                        )}
                      </h2>

                      <div className="form-group form-view-inline d-flex mb-0">
                        <label>Category</label>
                        <p>{data?.data?.designation?.title}</p>
                      </div>
                      <div className="form-group form-view-inline d-flex mb-0">
                        <label>Organisation</label>
                        {data?.data?.organisation?.title}
                      </div>
                      <div className="form-group form-view-inline d-flex mb-0">
                        <label>Other info</label>
                        {data?.data?.text}
                      </div>
                      <div
                        style={{ whiteSpace: "pre-wrap" }}
                        className="form-group form-view-inline d-flex mb-0"
                      >
                        <label>GO Incharge</label>
                        {data?.data?.district?.go_incharge}
                      </div>
                      <div className="form-group form-view-inline d-flex mb-0">
                        <label>District</label>
                        {data?.data?.district?.title}
                      </div>
                      {/* <div className="form-group form-view-inline d-flex mb-0">
                      <label>Organisation</label>
                      <p>{data?.data.organisation.title}</p>
                    </div> */}
                      {/* <div className="form-group form-view-inline d-flex mb-0">
                      <label>Assigned PSO's</label>
                      <p>{dataAssignment?.data.length}</p>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="stats-container d-flex">
                    <div className="stat stat--profile">
                      <div className="stat">{dataAssignment?.data?.length}</div>
                      <div className="label">Assigned Personnel</div>
                    </div>
                    <div className="stat stat--profile">
                      <div className="stat">
                        {dataTemporaryAssignment?.meta?.filter_count || 0}
                      </div>
                      <div className="label">Temporary Assignment</div>
                    </div>
                    <div className="stat stat--profile">
                      <div className="stat">
                        {dataPendingAssignment?.meta?.filter_count || 0}
                      </div>
                      <div className="label">Pending Assignment</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer spacer--sm"></div>
          {/* TABS */}
          <div>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  role="button"
                  className={"nav-link " + (selectedTab === 0 ? "active" : "")}
                  onClick={() => setSelectedTab(0)}
                >
                  Profile
                </a>
              </li>
              <li className="nav-item">
                <a
                  role="button"
                  onClick={() => setSelectedTab(1)}
                  className={"nav-link  " + (selectedTab === 1 ? "active" : "")}
                >
                  Assigned Personnel
                </a>
              </li>
              <li className="nav-item">
                <a
                  role="button"
                  onClick={() => setSelectedTab(2)}
                  className={"nav-link " + (selectedTab === 2 ? "active" : "")}
                >
                  Comments
                </a>
              </li>
              <li className="nav-item">
                <a
                  role="button"
                  onClick={() => setSelectedTab(3)}
                  className={"nav-link " + (selectedTab === 3 ? "active" : "")}
                >
                  Logs
                </a>
              </li>
              <li className="nav-item">
                <a
                  role="button"
                  onClick={() => setSelectedTab(4)}
                  className={"nav-link " + (selectedTab === 4 ? "active" : "")}
                >
                  TPRs
                </a>
              </li>
            </ul>
          </div>
          {/* 1st TAB */}
          {selectedTab === 0 && (
            <div className="card h-auto">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-4">
                    <h3>Contact Information</h3>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group form-view-inline mb-0">
                      <label>Email</label>
                      <p>{data?.data.email}</p>
                    </div>
                    <div className="form-group form-view-inline mb-0">
                      <label>Mobile</label>
                      <p>{data?.data.mobile}</p>
                    </div>
                    <div className="form-group form-view-inline mb-0">
                      <label>Phone</label>
                      <p>{data?.data.phone}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="font-weight-bold">Addresses:</div>
                    <div>
                      {dataAddresses?.data.flatMap((address, index) => (
                        <React.Fragment key={index}>
                          {/* <label key={index}>Address {index + 1}</label> */}
                          <div className="form-group form-view-inline mb-0">
                            <label>{address.address_type.title}</label>
                            <p>{address.address}</p>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  {/* <div className="col-12">
                  <div className="spacer spacer--md spacer--border-sm"></div>
                </div>
                <div className="col-12 mb-4">
                  <h3>Extra Information</h3>
                  <p>{data?.data.text}</p>
                </div> */}

                  {/* SPACER */}
                  {dataVehicles?.data.length > 0 && (
                    <>
                      {" "}
                      <div className="col-12">
                        <div className="spacer spacer--md spacer--border-sm"></div>
                      </div>
                      {/* VEHICLE LIST  */}
                      <div className="col-12 mb-4">
                        <h3>Vehicle List</h3>
                      </div>
                      <div className="col-lg-12">
                        <table className="table has-action-buttons">
                          <thead>
                            <tr>
                              <th scope="col">Make</th>
                              <th scope="col">Model & Year</th>
                              <th scope="col">No. Plate</th>
                              <th scope="col">Limit (Lt)</th>
                              <th scope="col">Driver</th>
                              <th scope="col">Bulletproof</th>
                            </tr>
                          </thead>
                          {dataVehicles?.data.flatMap((vehicle, index) => (
                            <React.Fragment key={index}>
                              <tbody>
                                <tr key={index}>
                                  <td>{vehicle.vehicle?.make}</td>
                                  <td>
                                    {vehicle.vehicle?.model} -{" "}
                                    {vehicle.vehicle?.year}
                                  </td>
                                  <td>
                                    {vehicle.vehicle?.registration_number}
                                  </td>

                                  <td>
                                    {Number(vehicle.limit_in_ltrs) === 0
                                      ? "No Limit"
                                      : vehicle.limit_in_ltrs}
                                  </td>
                                  <td>{vehicle.driver}</td>
                                  <td>
                                    <input
                                      className="form-check-input m-0"
                                      disabled
                                      checked={vehicle.vehicle?.is_bulletproof}
                                      type="checkbox"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </React.Fragment>
                          ))}
                        </table>
                      </div>
                    </>
                  )}
                  {/* SPACER */}
                  <div className="col-12">
                    <div className="spacer spacer--md spacer--border-sm"></div>
                  </div>
                  {data?.data?.norms && (
                    <>
                      <div className="col-12 mb-4">
                        <h3>Norms</h3>
                      </div>
                      <div className="col-lg-12 ml-2">{data?.data?.norms}</div>

                      <div className="col-12">
                        <div className="spacer spacer--md spacer--border-sm"></div>
                      </div>
                    </>
                  )}

                  {/* LINKED PROTECTEES */}
                  <div className="col-12 mb-4">
                    <h3>Linked Protectees</h3>
                  </div>
                  <div className="col-lg-12">
                    {dataRelations?.data.flatMap((p, index) => (
                      <div
                        className="d-flex flex-direction-row align-items-center mb-4"
                        style={{ gap: 16 }}
                        key={index}
                      >
                        <div className="avatar  avatar--lg avatar--rounded mr-3">
                          <img
                            width={128}
                            src={API_URL + p.photo}
                            alt={`${p.name} profile`}
                          />
                        </div>
                        <div>
                          <h4>
                            <a href={PATHS.protectees + "/" + p.id}>{p.name}</a>
                          </h4>
                          <div>
                            <small>{p.relation}</small>
                          </div>
                          <p className="m-0">
                            <b>Assigned Personnels:</b>{" "}
                            {newRelations?.[p.id] || 0}
                          </p>
                          <p className="m-0">
                            <b>Category:</b> {p.designation.title}
                          </p>
                          <p className="m-0">
                            <b>Organisation:</b> {p.organisation.title}
                          </p>
                        </div>
                      </div>
                    ))}
                    {data?.data.relation_protectee && (
                      <div
                        className="d-flex flex-direction-row align-items-center mb-4"
                        style={{ gap: 16 }}
                      >
                        <div className="avatar  avatar--lg avatar--rounded mr-3">
                          <img
                            width={128}
                            src={API_URL + data?.data.relation_protectee.photo}
                            alt={data?.data.relation_protectee.name + " photo"}
                          />
                        </div>
                        <div>
                          <div>
                            <small>{data?.data.relation} of</small>
                          </div>
                          <h4>
                            <a
                              href={
                                PATHS.protectees +
                                "/" +
                                data?.data.relation_protectee.id
                              }
                            >
                              {data?.data.relation_protectee.name}
                            </a>
                          </h4>
                          <p className="m-0">
                            <b>Assigned Personnels:</b> {relationProtectee}
                          </p>
                          <p className="m-0">
                            <b>Category:</b>{" "}
                            {data?.data.relation_protectee.designation.title}
                          </p>
                          <p className="m-0">
                            <b>Organisation:</b>{" "}
                            {data?.data.relation_protectee.organisation.title}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* 2nd Tab */}
          {selectedTab === 1 && (
            <div className="card h-auto">
              {/* ASSIGNED PSOs */}
              {data?.data.area_security ? (
                <div className="card-body">
                  {data?.data.area_security_district
                    ? `This protectee is under security of ${data?.data.area_security_district?.title}`
                    : "No Data Uplaoded"}
                </div>
              ) : (
                <div className="card-body">
                  <h3>{dataAssignment?.data.length} Personnel's Assigned</h3>
                  <div className="d-flex justify-content-around py-4 mb-5">
                    {/* <div className="stat--protectee">
                  <div className="lg">{dataAssignment?.data.length}</div>
                  <div className="label">Total Assigned</div>
                </div> */}
                    {refinedData &&
                      Object.keys(refinedData).flatMap((key, index) => (
                        <div key={index} className="stat--protectee">
                          <div className="lg">{refinedData[key].length}</div>
                          <div className="label">{key}</div>
                        </div>
                      ))}
                  </div>
                  {/* SPACER */}
                  {refinedData &&
                    Object.keys(refinedData).flatMap((key, index) => (
                      <React.Fragment key={index}>
                        <h4>
                          {key} (x{refinedData[key].length})
                          {key === "House Guard" ? (
                            <img src={houseguard} alt="" width={25} />
                          ) : key === "Driver" ? (
                            <img src={driver} alt="" width={25} />
                          ) : key === "Escort Duty" ? (
                            <img src={escortduty} alt="" width={40} />
                          ) :
                           key === "Security Incharge" ? (
                            <img src={securityincharge} alt="" width={25} />
                          ):(
                            <img src={pso} alt="" width={25} />
                          )}
                        </h4>
                        <table className="table has-action-button">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Rank</th>
                              <th scope="col">Contact</th>
                              <th scope="col">Belt No./RMC</th>
                              <th scope="col">Unit</th>
                              <th scope="col">Weapon</th>
                              <th scope="col">Deployed as</th>
                              <th scope="col">Order No.</th>
                              <th scope="col">Order Date</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/* {console.log(refinedData)} */}
                            {refinedData[key].map((pa, i) =>
                              pa.protectee ? (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    {pa.protector?.name || "Not Available"}
                                  </td>
                                  <td>
                                    {pa.protector?.rank?.title ||
                                      "Not Available"}
                                  </td>
                                  <td>
                                    {pa.protector?.mobile_no || "Not Available"}
                                  </td>
                                  <td>
                                    {pa.protector?.belt_no || "Not Available"}
                                  </td>
                                  <td>
                                    {pa.protector?.unit?.title ||
                                      "Not Available"}
                                  </td>
                                  <td>
                                    {pa?.weapon_type?.title || "Not Available"}
                                  </td>
                                  <td>
                                    {pa.deployment_type?.title ||
                                      "Not Available"}
                                  </td>
                                  <td>{pa?.order_no || "Not Available"}</td>
                                  <td className="text-left">
                                    {pa?.order_date || "Not Available"}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td className="text-left" colSpan={5}>
                                    Unassigned
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <div className="spacer spacer--sm"></div>
                      </React.Fragment>
                    ))}
                </div>
              )}
              <div className="spacer spacer--md spacer--border"></div>
            </div>
          )}
          {/* 3rd Tab */}
          {selectedTab === 2 && (
            <div className="card h-auto">
              {/* COMMENTS / LOG */}
              <div className="card-body">
                <div className="row">
                  {/* ENTRY LISTING */}
                  <div className="col-xl-8">
                    <h3>All Comments</h3>
                    <p className="text-muted">List of all the comments</p>
                    <div className="">
                      <div className="log-entry-container">
                        {comments?.flatMap((comment, i) => (
                          <CommentItem
                            key={i}
                            name={comment.owner.first_name}
                            attachment={comment.attachment_url}
                            type={comment.type}
                            date={moment(comment.created_on)}
                            comment={comment.comment}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* NEW ENTRY */}
                  <div className="col-xl-4">
                    <div className="card card--log-entry">
                      <div className="card-body">
                        <h3>Add New Entry</h3>
                        {/* <p className="text-muted">Enter details below</p> */}

                        <div className="spacer spacer--sm"></div>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Type</label>
                          <input
                            type="text"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            className="form-control"
                            id=""
                            placeholder="Cyber"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">
                            Comments
                          </label>
                          <textarea
                            className="form-control"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            id=""
                            style={{ height: 128 }}
                            placeholder="Comments"
                          ></textarea>
                        </div>
                        {/* ATTACHMENTS */}
                        {attachment_url ? (
                          <div className="mt-5">
                            <h5 className="text-semibold text-faded">
                              Attachment
                            </h5>
                            <a
                              href={API_URL + attachment_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {attachment_url}
                            </a>
                          </div>
                        ) : (
                          <EFileUpload
                            onUpload={(url) => setAttachment_url(url)}
                          />
                        )}
                      </div>

                      <div className="card-footer text-right">
                        <button className="btn btn-light mr-2">Cancel</button>
                        <button
                          onClick={async () => {
                            const c = await post({
                              protectee: id,
                              type: type,
                              comment: comment,
                              attachment_url: attachment_url,
                            });
                            setComments([c.data, ...comments]);
                            setComment("");
                            setType("");
                            setAttachment_url(undefined);
                          }}
                          type="button"
                          className="btn btn-primary"
                        >
                          Save Entry
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === 3 && (
            <div className="card h-auto">
              {/* COMMENTS / LOG */}
              <div className="card-body">
                <div className="row">
                  {/* ENTRY LISTING */}
                  <div className="col-12">
                    <h3>All Logs</h3>
                    <p className="text-muted">List of all logs</p>
                    <div className="logs">
                      {[...dataLogs?.data].reverse().flatMap((c, i) => (
                        <div className="row log-item" key={i}>
                          <div className="col-md-6">
                            <b>{c.activity.action_by?.first_name}</b> {" -> "}
                            <b>{c.activity.action}d </b> item on{" "}
                            <b>{c.activity.action_on}</b> and changed {" -> "}
                          </div>
                          <div className="col-md-6">
                            {Object.keys(c.delta).flatMap((k, i) => (
                              <span>
                                <span className="text-capitalize">
                                  {k.split("_").join(" ")}
                                </span>{" "}
                                {" -> "}
                                <b>{c.delta[k]}</b>
                                <br />
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-12">
                    <hr />
                    <p className="text-muted">Assignment logs</p>
                    <div className="logs">
                      {dataLogsAssignment?.data.flatMap((c, i) => (
                        <div className="row log-item" key={i}>
                          <div className="col-md-6">
                            <b>{c.first_name}</b> {" -> "}
                            <b>{c.action}d </b> assignment on{" "}
                            <b>{c.action_on}</b> for <b>{c.name}</b> {" -> "}{" "}
                            and changed {" -> "}
                          </div>
                          <div className="col-md-6">
                            {Object.keys(JSON.parse(c.delta)).flatMap(
                              (k, i) => (
                                <span>
                                  <span className="text-capitalize">
                                    {k.split("_").join(" ")}
                                  </span>{" "}
                                  {" -> "}
                                  <b>{JSON.parse(c.delta)[k]}</b>
                                  <br />
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === 4 && (
            <div className="card h-auto">
              {/* COMMENTS / LOG */}
              <div className="card-body">
                <div className="row">
                  {/* ENTRY LISTING */}
                  <div className="col-12">
                    <h3>TPR List</h3>
                    <p className="text-muted">List of all TPRs</p>
                    <table className="table table-bordered">
                      {dataTPR?.data?.flatMap((tpr, index) => (
                        <tr key={index}>
                          <td className="text-right">{index + 1}</td>
                          <td>
                            Submitted On:{" "}
                            {moment(tpr.created_on).format("DD-MM-YYYY")}
                          </td>
                          <td>
                            {tpr.ssp_report && (
                              <a
                                href={API_URL + tpr.ssp_report}
                                target="_blank"
                                rel="noreferrer"
                              >
                                SSP Report
                              </a>
                            )}
                          </td>
                          <td>
                            {tpr.intelligence_report && (
                              <a
                                href={API_URL + tpr.intelligence_report}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Intelligence Report
                              </a>
                            )}
                          </td>
                          <td>
                            {tpr.ib_report && (
                              <a
                                href={API_URL + tpr.ib_report}
                                target="_blank"
                                rel="noreferrer"
                              >
                                IB Report
                              </a>
                            )}
                          </td>
                          <td>
                            {tpr.other_report && (
                              <a
                                href={API_URL + tpr.other_report}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {tpr.other_title || "Other Report"}
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {show && (
          <TPRModal
            info={{
              designation: data?.data.designation?.title,
              name: data?.data.name,
              organisation: data?.data.organisation?.title,
            }}
            onHide={() => setShow(false)}
            visible={show}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
