import { combineReducers, configureStore } from "@reduxjs/toolkit";
import uiReducer from "./ui";
import userReducer from "./user";
import routesReducer from "./routes";
import protecteeReducer from "./protectee";

function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

export function createReduxStore() {
  const combinedReducer = combineReducers({
    user: userReducer,
    ui: uiReducer,
    routes: routesReducer,
    protectee: protecteeReducer,
});
  const rootReducer = (state, action) => {
    if (action.type === "logout") {
      state = undefined;
      localStorage.clear();
    }
    return combinedReducer(state, action);
  };

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: loadFromLocalStorage(),
  });
  store.subscribe(() => saveToLocalStorage(store.getState()));
  return store;
}
