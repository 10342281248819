import moment from "moment";
import React, { ReactElement } from "react";
import useFetch from "use-http";
import { API_URL } from "../..";
import { PATHS } from "../../reducers/routes";
import ReactToPrint from "react-to-print";
import { Printer } from "react-bootstrap-icons";

interface Props {
  onClose: () => void;
  protecteeId: number;
}

const orderFormation = [
  "Police District",
  "Commando",
  "Armed",
  "SSG/SPU",
  "RTC",
  "PPA",
  "GRP",
  "PHG",
  "CPM",
  "Others",
];

const orderRank = [
  "DSP",
  "Inspector",
  "Inspector/LR",
  "SI",
  "SI/LR",
  "Inspector/CR",
  "ASI",
  "ASI/LR",
  "ASI/CR",
  "L / ASI",
  "L/ASI/LR",
  "Head Constable",
  "Head Constable/PR",
  "Head Constable/LR",
  "L/Head Constable",
  "C-2",
  "Senior Constable",
  "Constable",
  "L/Constable",
  "PHG",
];

const orderCenter = ["NSG", "CISF", "CRPF", "ITBP"];

export default function ProtecteeSummaryModal({
  onClose,
  protecteeId,
}: Props): ReactElement {
  const { data } = useFetch(
    `items/protectee/${protecteeId}?fields=*.*.*`,
    {},
    []
  );
  const componentRef = React.useRef() as React.MutableRefObject<any>;
  const [relationProtectee, setRelationProtectee] = React.useState(0);
  const { data: dataRelations } = useFetch(
    `items/protectee?fields=*.*&filter[relation_protectee]=${protecteeId}`,
    {},
    []
  );
  const { data: dataAssignment } = useFetch(
    `items/protector_assignment?fields=*.*&filter[protectee]=${protecteeId}&filter[protector.id][neq]=null&filter[status][nin]=withdrawn,unassigned&filter[is_temporary]=0`,
    {},
    []
  );
  const { data: dataPendingAssignment } = useFetch(
    `items/protector_assignment?meta=filter_count&filter[protectee]=${protecteeId}&filter[status]=assignment pending`,
    {},
    []
  );
  const { data: dataTemporaryAssignment } = useFetch(
    `items/protector_assignment?meta=filter_count&filter[protectee]=${protecteeId}&filter[status][nin]=unassigned,withdrawn&filter[is_temporary]=1`,
    {},
    []
  );
  const { data: dataAssignmentRelation, get: getAssignmentRelation } = useFetch(
    `items/protector_assignment`
  );
  const { data: dataAddresses } = useFetch(
    `items/address?fields=*.*&filter[protectee]=${protecteeId}`,
    {},
    []
  );
  const { data: dataVehicles } = useFetch(
    `items/vehicle_assignment?fields=*.*&filter[protectee]=${protecteeId}`,
    {},
    []
  );
  const { data: dataStats } = useFetch(
    `custom/stats/summaryStats?pId=${protecteeId}`,
    {},
    []
  );
  const { get: getTotalAssignments } = useFetch(`items/protector_assignment`);
  const [newRelations, setNewRelations] = React.useState<any>();
  React.useEffect(() => {
    if (dataRelations?.data) {
      const promises: any[] = [];
      dataRelations.data.forEach((data) => {
        promises.push(
          getTotalAssignments(
            `?fields=protectee&filter[protectee]=${data.id}&meta=filter_count&filter[status][nin]=withdrawn,unassigned`
          )
        );
      });
      Promise.all(promises).then((rs) => {
        const v: any = {};
        rs.forEach((r) => {
          if (r?.data[0]) {
            v[r?.data[0].protectee] = r.meta.filter_count;
          }
        });
        setNewRelations(v);
      });
    }
  }, [dataRelations, getTotalAssignments, setNewRelations]);
  React.useEffect(() => {
    if (data?.data.relation_protectee)
      getAssignmentRelation(
        `?meta=filter_count&filter[protectee]=${data?.data.relation_protectee.id}&filter[status][nin]=withdrawn`
      ).then((a) => {
        setRelationProtectee(
          a?.data.filter((x) => x.status !== "unassigned")?.length || 0
        );
      });

    // if (data?.data.relation_protectee)
    //   getAssignmentRelation(
    //     `?meta=filter_count&filter[protectee]=${data?.data.relation_protectee.id}`
    //   ).then((a) => {
    //     setRelationProtectee(a?.meta?.filter_count || 0);
    //   });
  }, [data, getAssignmentRelation]);
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        id="modelId"
        style={{ display: "block" }}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Protectee Summary</h3>
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-outline-primary m-auto">
                    <Printer className="mr-2" />
                    Print Summary
                  </button>
                )}
                content={() => componentRef.current!}
              />
              <button
                onClick={() => onClose()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" ref={componentRef}>
              <div className="">
                <div className="">
                  <div className="">
                    <div className="row d-flex align-items-center">
                      <div className="col-xl-6 col-md-12">
                        <div className="d-flex">
                          <div className="avatar avatar--md avatar--rounded mr-4">
                            <img
                              className="img-fluid"
                              src={API_URL + data?.data?.photo}
                              alt=""
                            />
                          </div>
                          <div>
                            <h2 className="">{data?.data?.name}</h2>
                            <h2 className="mb-4">
                              <span style={{ fontSize: "1rem" }}>Basis:</span>
                              <div className="badge badge-warning mx-1">
                                {data?.data?.category.title}
                              </div>
                              <div className="badge badge-warning mr-1">
                                {data?.data?.sub_category?.title}
                              </div>
                              {data?.data?.high_value_target && (
                                <div className="badge badge-danger">
                                  Highly Threatened Protectee
                                </div>
                              )}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-md-12">
                        <div className="stats-container d-flex">
                          <div className="stat stat--profile">
                            <div className="stat">
                              {dataAssignment?.data?.length}
                              {/* {dataStats?.data.formationStats.reduce(
                            (total, current) => total + Number(current.count),
                            0
                          )} */}
                            </div>
                            <div className="label">Assigned Personnel</div>
                          </div>
                          <div className="stat stat--profile">
                            <div className="stat">
                              {dataTemporaryAssignment?.meta?.filter_count || 0}
                            </div>
                            <div className="label">Temporary Assignment</div>
                          </div>
                          <div className="stat stat--profile">
                            <div className="stat">
                              {dataPendingAssignment?.meta?.filter_count || 0}
                            </div>
                            <div className="label">Pending Assignment</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="col">
                          <div className="form-group form-view-inline d-flex mb-0">
                            <label>Category</label>
                            <p>{data?.data?.designation?.title}</p>
                          </div>
                          <div className="form-group form-view-inline d-flex mb-0">
                            <label>Organisation</label>
                            {data?.data?.organisation?.title}
                          </div>
                          <div className="form-group form-view-inline d-flex mb-0">
                            <label>Other info</label>
                            {data?.data?.text}
                          </div>
                          <div
                            style={{ whiteSpace: "pre-wrap" }}
                            className="form-group form-view-inline d-flex mb-0"
                          >
                            <label>GO Incharge</label>
                            {data?.data?.district?.go_incharge}
                          </div>
                          <div className="form-group form-view-inline d-flex mb-0">
                            <label>District</label>
                            {data?.data?.district?.title}
                          </div>
                        </div>
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacer spacer--sm"></div>
              <div className="card h-auto">
                <div className="card-body">
                  <div className="row">
                    {data?.data.area_security ? (
                      <div className="col-12">
                        This protectee is under security of{" "}
                        {data?.data.area_security_district?.title}
                      </div>
                    ) : (
                      <div className="col-12">
                        <h3 className="mb-4">
                          Total Security Personnel:{" "}
                          {dataStats?.data.formationStats.reduce(
                            (total, current) => total + Number(current.count),
                            0
                          )}
                        </h3>
                        <div className="row">
                          <div className="col">
                            <div>
                              <small className="text-strong text-uppercase mb-3">
                                By Formation
                              </small>
                              <div>
                                {dataStats?.data.formationStats
                                  .sort(
                                    (a, b) =>
                                      orderFormation.indexOf(a.title) -
                                      orderFormation.indexOf(b.title)
                                  )
                                  .flatMap((s, i) => (
                                    <div key={i}>
                                      {s.title} - {s.count}
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="mt-2">
                              <small className="text-strong text-uppercase mb-3">
                                By CPMF
                              </small>
                              <div>
                                {dataStats?.data.centerStats
                                  .sort(
                                    (a, b) =>
                                      orderCenter.indexOf(a.title) -
                                      orderCenter.indexOf(b.title)
                                  )
                                  .flatMap((s, i) => (
                                    <div key={i}>
                                      {s.title} - {s.count}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <small className="text-strong text-uppercase mb-3">
                              Deployed As
                            </small>
                            <div>
                              {dataStats?.data.deploymentTypeStats.flatMap(
                                (s, i) => (
                                  <div key={i}>
                                    {s.title} - {s.count}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <small className="text-strong text-uppercase mb-3">
                              By Rank
                            </small>
                            <div>
                              {dataStats?.data.rankStats
                                .sort(
                                  (a, b) =>
                                    orderRank.indexOf(a.title) -
                                    orderRank.indexOf(b.title)
                                )
                                .flatMap((s, i) => (
                                  <div key={i}>
                                    {s.title} - {s.count}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* SPACER */}
                    {/* <div className="col-12">
                        <div className="spacer spacer--md spacer--border-sm"></div>
                      </div>

                      <div className="col-12">
                        <h3 className="mb-4 ">
                          Additional Deployment by Central Agencies
                        </h3>
                        <div className="row">
                          <div className="col">
                            <div>
                              NSG - 00 <br />
                              CISF - 00 <br />
                              CRPF - 00 <br />
                            </div>
                          </div>
                        </div>
                      </div> */}

                    {/* SPACER */}
                    <div className="col-12">
                      <div className="spacer spacer--md spacer--border-sm"></div>
                    </div>
                    <div className="col-12 mb-4"></div>

                    <div className="col-12 mb-4">
                      <h3>Contact Information</h3>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group form-view-inline mb-0">
                        <label>Email</label>
                        <p>{data?.data.email}</p>
                      </div>
                      <div className="form-group form-view-inline mb-0">
                        <label>Mobile</label>
                        <p>{data?.data.mobile}</p>
                      </div>
                      <div className="form-group form-view-inline mb-0">
                        <label>Phone</label>
                        <p>{data?.data.phone}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="font-weight-bold">Addresses:</div>
                      <div>
                        {dataAddresses?.data.flatMap((address, index) => (
                          <React.Fragment key={index}>
                            {/* <label key={index}>Address {index + 1}</label> */}
                            <div className="form-group form-view-inline mb-0">
                              <label>{address.address_type.title}</label>
                              <p>{address.address}</p>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    {/* <div className="col-12">
                  <div className="spacer spacer--md spacer--border-sm"></div>
                </div>
                <div className="col-12 mb-4">
                  <h3>Extra Information</h3>
                  <p>{data?.data.text}</p>
                </div> */}

                    {/* SPACER */}

                    {/* VEHICLE LIST  */}
                    {dataVehicles?.data.length > 0 && (
                      <>
                        <div className="col-12">
                          <div className="spacer spacer--md spacer--border-sm"></div>
                        </div>
                        <div className="col-12 mb-4">
                          <h3>Vehicle List</h3>
                        </div>
                        <div className="col-lg-12">
                          <table className="table has-action-buttons">
                            <thead>
                              <tr>
                                <th scope="col">Make</th>
                                <th scope="col">Model & Year</th>
                                <th scope="col">No. Plate</th>
                                <th scope="col">Limit (Lt)</th>
                                <th scope="col">Driver</th>
                                {/* <th scope="col">Bulletproof</th> */}
                              </tr>
                            </thead>
                            {dataVehicles?.data.flatMap((vehicle, index) => (
                              <React.Fragment key={index}>
                                <tbody>
                                  <tr key={index}>
                                    <td>{vehicle.vehicle?.make}</td>
                                    <td>
                                      {vehicle.vehicle?.model} -{" "}
                                      {vehicle.vehicle?.year}
                                    </td>
                                    <td>
                                      {vehicle.vehicle?.registration_number}
                                    </td>
                                    <td>
                                      {Number(vehicle.limit_in_ltrs) === 0
                                        ? "No Limit"
                                        : vehicle.limit_in_ltrs}
                                    </td>
                                    <td>{vehicle.driver}</td>
                                    {/* <td>
                                      <input
                                        className="form-check-input m-0"
                                        disabled
                                        checked={
                                          vehicle.vehicle?.is_bulletproof
                                        }
                                        type="checkbox"
                                      />
                                    </td> */}
                                  </tr>
                                </tbody>
                              </React.Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}
                    {/* SPACER */}
                    <div className="col-12">
                      <div className="spacer spacer--md spacer--border-sm"></div>
                    </div>

                    {/* LINKED PROTECTEES */}
                    <div className="col-12 mb-4">
                      <h3>Linked Protectees</h3>
                    </div>
                    <div className="col-lg-12">
                      {dataRelations?.data.flatMap((p, index) => (
                        <div
                          className="d-flex flex-direction-row align-items-center mb-4"
                          style={{ gap: 16 }}
                          key={index}
                        >
                          <div className="avatar  avatar--lg avatar--rounded mr-3">
                            <img width={128} src={API_URL + p.photo} />
                          </div>
                          <div>
                            <h4>
                              <a href={PATHS.protectees + "/" + p.id}>
                                {p.name}
                              </a>
                            </h4>
                            <div>
                              <small>{p.relation}</small>
                            </div>
                            <p className="m-0">
                              <b>Assigned Personnels:</b>{" "}
                              {newRelations?.[p.id] || 0}
                            </p>
                            <p className="m-0">
                              <b>Category:</b> {p.designation.title}
                            </p>
                            <p className="m-0">
                              <b>Organisation:</b> {p.organisation.title}
                            </p>
                          </div>
                        </div>
                      ))}
                      {data?.data.relation_protectee && (
                        <div
                          className="d-flex flex-direction-row align-items-center mb-4"
                          style={{ gap: 16 }}
                        >
                          <div className="avatar  avatar--lg avatar--rounded mr-3">
                            <img
                              width={128}
                              src={
                                API_URL + data?.data.relation_protectee.photo
                              }
                            />
                          </div>
                          <div>
                            <div>
                              <small>{data?.data.relation} of</small>
                            </div>
                            <h4>
                              <a
                                href={
                                  PATHS.protectees +
                                  "/" +
                                  data?.data.relation_protectee.id
                                }
                              >
                                {data?.data.relation_protectee.name}
                              </a>
                            </h4>
                            <p className="m-0">
                              <b>Assigned Personnels:</b> {relationProtectee}
                            </p>
                            <p className="m-0">
                              <b>Category:</b>{" "}
                              {data?.data.relation_protectee.designation.title}
                            </p>
                            <p className="m-0">
                              <b>Organisation:</b>{" "}
                              {data?.data.relation_protectee.organisation.title}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onClose()}
                data-dismiss="modal"
              >
                Close
              </button>
            </div> */}
        </div>
      </div>
    </div>
  );
}
