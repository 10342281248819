import React, { InputHTMLAttributes, ReactElement } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  name?: string
  isValid?: 'valid' | 'invalid' | 'unset'
  errorMsg?: string
}

// TODO: Add States

export default function EInput({
  label,
  isValid,
  name,
  errorMsg,
  ...props
}: Props): ReactElement {
  return (
    <div className='form-group'>
      <label
        className={
          '' +
          (isValid !== 'unset'
            ? isValid === 'invalid'
              ? 'text-danger'
              : 'text-success'
            : '')
        }
        htmlFor={name}
      >
        {label}
      </label>
      <input
        {...props}
        className={
          'form-control ' +
          (isValid !== 'unset'
            ? isValid === 'invalid'
              ? 'is-invalid'
              : 'is-valid'
            : '')
        }
        id={name}
      />
      {isValid !== 'unset' && isValid === 'invalid' && (
        <div className='invalid-feedback'>
          {errorMsg
            ?.split('\\n')
            .flatMap((it, i) => <div key={'x' + i}>{it}</div>) ||
            'Invalid ' + label}
        </div>
      )}
    </div>
  )
}
