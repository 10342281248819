import React, { ReactElement } from "react";

interface Props {
  defaultSelected?: any[];
  items: any[];
  labelKey: string;
  onChange: (selected: any[]) => void;
}

export default function MultipleCheckboxes({
  defaultSelected = [],
  items = [],
  labelKey,
  onChange = (selected) => console.log(selected),
}: Props): ReactElement {
  const [selected, setSelected] = React.useState<any[]>(defaultSelected);
  const onChangeEvent = React.useCallback(
    (e, item) => {
      const i = selected.findIndex((a) => a === item);
      const arr = [...selected];
      if (e.target.checked && i === -1) {
        arr.push(item);
        setSelected(arr);
      } else {
        arr.splice(i, 1);
        setSelected(arr);
      }
      onChange(arr);
    },
    [onChange, selected]
  );
  return (
    <div className="d-flex flex-row flex-wrap">
      {items.flatMap((item, index) => (
        <div className="col-lg-4" key={index}>
          <label className="form-check-label font-weight-normal">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={selected.findIndex((a) => a === item) !== -1}
              onChange={(e) => {
                onChangeEvent(e, item);
              }}
            />
            {item[labelKey]}
          </label>
        </div>
      ))}
    </div>
  );
}
