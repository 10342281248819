import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoute } from "./types";

export const PATHS = {
  dashboard: "/dashboard",
  protectee: "/protectee",
  protecteeEdit: "/protectee/:id",
  protecteeSummary: "/protectees/:id",
  protectees: "/protectees",
  protector: "/protector",
  approval: "/approval",
  withdrawal: "/withdrawal",
  reports: "/reports",
  reportView: "/reports/view",
  vehicles: "/vehicles",
};

export const ASSIGNMENT_PATHS = {
  protector: "/protector",
  protectorAssignment: "/protector-assignment",
  protecteeEdit: "/protectee/:id",
  protecteeSummary: "/protectees/:id",
  protectees: "/protectees",
  temporary: "/temporary-assignment",
  withdrawal: "/withdrawal-request",
  reports: "/reports",
};

const adminRoutes = [
  {
    slug: "dashboard",
    label: "Dashboard",
    route: PATHS.dashboard,
    active: true,
  },
  {
    slug: "protectees",
    label: "Add Protectee",
    route: PATHS.protectee,
    active: false,
  },
  {
    slug: "protectees-list",
    label: "Protectees",
    route: PATHS.protectees,
    active: false,
  },
  {
    slug: "protector",
    label: "Security Personnel",
    route: PATHS.protector,
    active: false,
  },
  {
    slug: "approval",
    label: "Temporary Assignment",
    route: PATHS.approval,
    active: false,
  },
  {
    slug: "withdrawal",
    label: "Bulk Withdrawal",
    route: PATHS.withdrawal,
    active: false,
  },
  {
    slug: "vehicles",
    label: "Vehicles",
    route: PATHS.vehicles,
    active: false,
  },
  {
    slug: "reports",
    label: "Reports & Analysis",
    route: PATHS.reports,
    active: false,
  },
];

const assignmentRoutes = [
  {
    slug: "protector",
    label: "Security Personnel",
    route: ASSIGNMENT_PATHS.protector,
    active: true,
  },
  {
    slug: "protectees-list",
    label: "Protectees",
    route: ASSIGNMENT_PATHS.protectees,
    active: false,
  },
  {
    slug: "withdrawal-request",
    label: "Bulk Withdrawal",
    route: ASSIGNMENT_PATHS.withdrawal,
    active: false,
  },
  {
    slug: "protector-assignment",
    label: "Personnel Assignment",
    route: ASSIGNMENT_PATHS.protectorAssignment,
    active: false,
  },
  {
    slug: "temporary-assignment",
    label: "Temporary Assignment",
    route: ASSIGNMENT_PATHS.temporary,
    active: false,
  },
  {
    slug: "reports",
    label: "Reports",
    route: ASSIGNMENT_PATHS.reports,
    active: false,
  },
];

export const routeSlice = createSlice({
  name: "routes",
  initialState: {
    routes: [] as IRoute[],
  },
  reducers: {
    setRoutes: (state, { payload }: PayloadAction<"admin" | "assignment">) => {
      if (payload === "admin") {
        state.routes = adminRoutes;
      } else if (payload === "assignment") {
        state.routes = assignmentRoutes;
      }
    },
    setActive: (state, action) => {
      if (action.payload?.slug) {
        state.routes.map((r) => {
          if (r.slug === action.payload.slug) {
            r.active = true;
          } else {
            r.active = false;
          }
          return r;
        });
      }
    },
  },
});

export const { setActive } = routeSlice.actions;

export default routeSlice.reducer;
