import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import AddEditModal from "../../../common/components/AddEditModal";
import ETable from "../../../common/components/ETable/ETable";

export default function AddressesTable(): ReactElement {
  const protectee = useSelector((state: any) => state.protectee);
  const { data: dataAddresses, get } = useFetch(
    `items/address?filter[protectee]=${
      protectee.id
    }&fields=*.*&time=${new Date().valueOf()}`,
    {
      cache: "no-cache",
    },
    []
  );
  const { post, patch, del } = useFetch(`items/address`, {
    cache: "no-cache",
  });
  const { data: dataAddressTypes } = useFetch(
    "items/address_type",
    {
      cache: "no-cache",
    },
    []
  );
  const [show, setShow] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>();
  return (
    <div>
      <ETable
        showPagesText={false}
        showFilterBar={false}
        items={dataAddresses?.data || []}
        actionButtons={[
          {
            title: "Edit",
            type: "primary",
            onClick: (item) => {
              setSelected(item);
              setShow(true);
            },
          },
          {
            title: "Delete",
            type: "danger",
            onClick: async (item: any) => {
              await del(`${item.id}`);
              get();
            },
          },
        ]}
        columns={[
          {
            key: "address_type.title",
            label: "Address Type",
          },
          {
            key: "address",
            label: "Address",
          },
        ]}
      />
      <div className="mt-2">
        <button
          onClick={() => {
            setShow(true);
          }}
          className="btn btn-link"
        >
          + Add New Address
        </button>
      </div>
      {show && (
        <AddEditModal
          fields={[
            {
              key: "address_type",
              label: "Address Type",
              type: "select",
              selectItems: {
                items: dataAddressTypes?.data || [],
                labelKey: "title",
              },
            },
            {
              key: "address",
              label: "Address",
              type: "text-area",
            },
          ]}
          title="Address"
          defaultItem={selected}
          onSubmit={async (item, isEdit) => {
            const d = {
              address_type: item.address_type.id,
              address: item.address,
            };
            if (isEdit) {
              await patch(item.id.toString(), d);
            } else {
              await post({ ...d, protectee: protectee.id });
            }
            get();
          }}
          onClose={() => setShow(false)}
        />
      )}
    </div>
  );
}
