import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category, SubCategory } from "../common/models/models";
import { IAddress, IVehicle } from "./types";

export const initialState = {
  id: undefined as unknown,
  isAdding: false,
  isAssigned: false,
  status: undefined as unknown,
  currentStep: 0,
  personal: {
    full_name: "",
    category: undefined as unknown,
    organisation: undefined as unknown,
    type: undefined as unknown,
    subType: undefined as unknown,
    review_after_in_months: 0,
    high_value_target: false,
    photo: "",
    relation_protectee: undefined as unknown,
    relation: undefined as unknown,
    text: undefined as unknown,
    norms: undefined as unknown,
    district: undefined as unknown,
  },
  security: {
    addresses: [
      {
        type: {
          id: 1,
          title: "Office",
        },
        address: "",
      },
    ] as IAddress[],
    vehicles: [] as IVehicle[],
    contact: {
      phone: "",
      email: "",
      mobile: "",
    },
    area_security: false,
    area_security_district: undefined as unknown,
    personnel_allocation: {} as unknown,
  },
};

export const protecteeSlice = createSlice({
  name: "protectee",
  initialState,
  reducers: {
    setIsAdding: (state, { payload }) => {
      state.isAdding = payload;
    },
    setIsAssigned: (state, { payload }) => {
      state.isAssigned = payload;
    },
    setInitialState: (state) => initialState,
    setFullState: (state, { payload }) => payload,
    setAddress: (
      state,
      action: PayloadAction<{ address: IAddress; index: number }>
    ) => {
      state.security.addresses[action.payload.index] = action.payload.address;
    },
    addAddress: (state) => {
      state.security.addresses.push({
        address: "",
        type: {
          id: 1,
          title: "Office",
        },
      });
    },
    setPersonnelAllocation: (state, { payload }) => {
      state.security.personnel_allocation = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setAreaSecurity: (state, { payload }) => {
      state.security.area_security = payload;
    },
    setAreaSecurityDistrict: (state, { payload }) => {
      state.security.area_security_district = payload;
    },
    setPersonalInfo: (
      state,
      action: PayloadAction<{
        full_name?: string;
        category?: SubCategory;
        organisation?: SubCategory;
        type?: Category;
        subType?: SubCategory;
        review_after_in_months?: number;
        high_value_target?: boolean;
        photo?: string;
        relation_protectee?: number;
        relation?: string;
        text?: string;
        norms?: string;
        district?: SubCategory;
      }>
    ) => {
      if (typeof action.payload.category !== "undefined")
        state.personal.category = action.payload.category;
      if (typeof action.payload.organisation !== "undefined")
        state.personal.organisation = action.payload.organisation;
      if (typeof action.payload.type !== "undefined")
        state.personal.type = action.payload.type;
      if (typeof action.payload.subType !== "undefined")
        state.personal.subType = action.payload.subType;
      if (typeof action.payload.district !== "undefined")
        state.personal.district = action.payload.district;
      if (typeof action.payload.full_name !== "undefined")
        state.personal.full_name = action.payload.full_name;
      if (typeof action.payload.photo !== "undefined")
        state.personal.photo = action.payload.photo;
      if (typeof action.payload.review_after_in_months !== "undefined")
        state.personal.review_after_in_months =
          action.payload.review_after_in_months;
      if (typeof action.payload.high_value_target !== "undefined")
        state.personal.high_value_target = action.payload.high_value_target;
      if (typeof action.payload.relation_protectee !== "undefined")
        state.personal.relation_protectee = action.payload.relation_protectee;
      if (typeof action.payload.relation !== "undefined")
        state.personal.relation = action.payload.relation;
      if (typeof action.payload.text !== "undefined")
        state.personal.text = action.payload.text;
      if (typeof action.payload.norms !== "undefined")
        state.personal.norms = action.payload.norms;
    },
    setContactInfo: (
      state,
      action: PayloadAction<{
        email?: string;
        phone?: string;
        mobile?: string;
      }>
    ) => {
      if (typeof action.payload.email !== "undefined")
        state.security.contact.email = action.payload.email;
      if (typeof action.payload.phone !== "undefined")
        state.security.contact.phone = action.payload.phone;
      if (typeof action.payload.mobile !== "undefined")
        state.security.contact.mobile = action.payload.mobile;
    },
    clearSubType: (state) => {
      state.personal.subType = undefined;
    },
    setId: (state, { payload }) => {
      state.id = payload;
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    },
    removeAddress: (state, action: PayloadAction<number>) => {
      state.security.addresses.splice(action.payload, 1);
    },
    assignVehicle: (state, action: PayloadAction<IVehicle>) => {
      state.security.vehicles.push(action.payload);
    },
    removeVehicle: (state, action: PayloadAction<number>) => {
      state.security.vehicles.splice(action.payload, 1);
    },
  },
});

export default protecteeSlice.reducer;
