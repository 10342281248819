import moment from "moment";
export const FieldMapping = {
  protector: "Security Personnel",
  designation: "category",
  category: "security category",
  dob: "Age",
};

export const FieldFunction = {
  dob: (value) => {
    return moment().subtract(value, "years").format("YYYY-MM-DD");
  },
};

const labelSequence = [
  'Position Based + Category',
  'Threat Based + Category',
  'Position Based',
  'Position Based + Threat',
  'Threat Based',
  'Court Order',
  'Payment Based'
];
export const sortByPriority = (obj) => {
  obj.count = labelSequence
    .map((e) => obj.count[obj.labels.indexOf(e)])
    .filter((x) => !isNaN(x));
  obj.tCount = labelSequence
    .map((e) => obj.tCount[obj.labels.indexOf(e)])
    .filter((x) => !isNaN(x));
  obj.labels = labelSequence.filter((e) => obj.labels.includes(e));
  return obj;
};

export const FieldReverse = {
  dob: true,
};
