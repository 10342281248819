import React, { ReactElement } from "react";
import { API_URL } from "../../..";
import EFileUpload from "../../../common/components/EFileUpload/EFileUpload";

interface Props {
  onClose: () => void;
  onSave: (item) => void;
}
export default function CreateTPRModal({
  onClose,
  onSave,
}: Props): ReactElement {
  const [ssp, setSsp] = React.useState<string>("");
  const [ir, setIr] = React.useState<string>("");
  const [ib, setIb] = React.useState<string>("");
  const [otherTitle, setOtherTitle] = React.useState<string>("");
  const [otherReport, setOtherReport] = React.useState<string>("");
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        style={{ display: "block" }}
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create TPR</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label>SSP Report</label>
              <EFileUpload
                onUpload={(url) => {
                  setSsp(url);
                }}
              />
              {ssp && (
                <a href={API_URL + ssp} target="_blank" rel="noreferrer">
                  View Report
                </a>
              )}
              <br />
              <br />
              <label>Intelligence Report</label>
              <EFileUpload
                onUpload={(url) => {
                  setIr(url);
                }}
              />
              {ir && (
                <a href={API_URL + ir} target="_blank" rel="noreferrer">
                  View Report
                </a>
              )}
              <br />
              <br />
              <label>IB Report</label>
              <EFileUpload
                onUpload={(url) => {
                  setIb(url);
                }}
              />
              {ib && (
                <a href={API_URL + ib} target="_blank" rel="noreferrer">
                  View Report
                </a>
              )}
              <br />
              <br />
              <label>Other Report</label>
              <input
                type="text"
                onChange={(e) => setOtherTitle(e.target.value)}
                className="form-control mb-2"
                placeholder="Title"
              />
              <EFileUpload
                onUpload={(url) => {
                  setOtherReport(url);
                }}
              />
              {otherReport && (
                <a
                  href={API_URL + otherReport}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Report
                </a>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-muted"
                onClick={() => {
                  onClose();
                }}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={() => {
                  onSave({
                    ssp,
                    ir,
                    ib,
                    otherTitle,
                    otherReport,
                  });
                }}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
