import React, { ReactElement } from "react";
import bg from "../../assets/images/bg-login.jpg";
import logo from "../../assets/images/logo.png";
import { EInput, useInputState, EButton } from "../../common/library";
import { useDispatch } from "react-redux";
import useFetch from "use-http";
import { userSlice } from "../../reducers/user";

function LoginPage(): ReactElement {
  const dispatch = useDispatch();

  const [email, setEmail, isEmailValid] = useInputState<string>("", (v) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    )
      ? "unset"
      : "invalid"
  );

  const [password, setPassword, isPasswordValid] = useInputState<string>(
    "",
    (v) => (v.length < 6 ? "invalid" : "unset")
  );

  const { post, response, loading, error } = useFetch("/auth/authenticate", {
    cache: "no-cache",
  });

  const { get } = useFetch("/items/user_detail");

  return (
    <div className="container-fluid h-100">
      <div className="row h-100 d-flex align-items-stretch">
        <div
          className="d-none d-md-flex flex-column align-items-center justify-content-center col-md-6 p-0 overflow-hidden"
          style={{
            height: "100vh",
            backgroundImage: `url("${bg}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <img src={logo} alt="logo" />
        </div>
        <div
          style={{ height: "100vh" }}
          className="col-md-6 d-flex justify-content-center align-items-center"
        >
          <form
            className="w-100  p-5"
            style={{ maxWidth: 520 }}
            onSubmit={async (e) => {
              e.preventDefault();
              await post({
                email,
                password,
              });
              if (response.ok) {
                const d = await get(
                  `?fields=*.*.*&filter[user]=${response.data.data.user.id}&single=1&access_token=${response.data.data.token}`
                );
                dispatch(userSlice.actions.setUserDetail(d.data));
                dispatch(userSlice.actions.setUser(response.data.data));
              }
            }}
          >
            <div className="d-md-none mb-5 text-center">
              <img src={logo} width="30%" alt="logo" />
            </div>
            <h1 className="">Punjab Security Allocation Portal</h1>
            <h2>PSAP</h2>
            <p className="mb-5 text-muted">
              Please enter your details to login
            </p>
            {error && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                {response?.data?.error?.message} Please try again.
              </div>
            )}
            <EInput
              name="email"
              label="Email"
              value={email}
              autoComplete="username"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              isValid={isEmailValid}
              placeholder="john@gmail.com"
            />
            <EInput
              name="password"
              label="Password"
              type="password"
              value={password}
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              isValid={isPasswordValid}
              placeholder="********"
              errorMsg="- at least 6 characters\n"
            />
            <EButton
              isLoading={loading}
              style={{ height: "calc(2em + 1.5rem + 1px)" }}
              className="btn btn-primary btn-block"
              disabled={
                isEmailValid === "invalid" ||
                isPasswordValid === "invalid" ||
                password?.length === 0
              }
            >
              Login
            </EButton>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
