import React, { ReactElement } from "react";
import {
  BellFill,
  PeopleFill,
  PersonBoundingBox,
  PersonDashFill,
  Plus,
  Search,
  Truck,
} from "react-bootstrap-icons";
import { Parser } from "json2csv";
import useFetch from "use-http";
import { Bar, Doughnut } from "react-chartjs-2";
import moment from "moment";
import { API_URL } from "../..";
import { PATHS } from "../../reducers/routes";
import GroupReportModal from "./group-report-modal";
import Empty from "../../common/components/Empty";
import { ESelect } from "../../common/library";
import ProtecteeSummaryModal from "./ProtecteeSummaryModal";
import { sortByPriority } from "../reports/components/mapping";
import { LoadingScreen } from "../../common/components/LoadingScreen";

export default function HomePage(): ReactElement {
  const { data: dataStats } = useFetch(`custom/stats`, {}, []);
  const { get, data: dataProtectees } = useFetch(`custom/stats/protectee`);
  const [monthReview, setMonthReview] = React.useState(6);
  const [showSummaryModal, setShowSummaryModal] = React.useState(false);
  const [selectedProtectee, setSelectedProtectee] = React.useState<any>();
  const { get: getProtecteesSub, data: dataProtecteesSub } = useFetch(
    `custom/stats/protectee/sub_category`
  );
  const { get: getProtectorsSub, data: dataProtectorsSub } = useFetch(
    `custom/stats/protector/sub_category`
  );
  const { data: dataProtecteesSubTotal } = useFetch(
    `custom/stats/protectee/sub_category?start_date=1970-01-01`,
    {},
    []
  );
  const { data: dataAllProtectee, get: getAllProtectee } = useFetch(
    `items/protectee`
  );
  React.useEffect(() => {
    getAllProtectee(
      "?fields=id,name,designation.title,organisation.title,photo"
    );
  }, [getAllProtectee]);
  const { data: dataProtecteesTotal } = useFetch(
    `custom/stats/protectee?start_date=1970-01-01`,
    {},
    []
  );
  const { data: dataProtectorsSubTotal } = useFetch(
    `custom/stats/protector/sub_category?start_date=1970-01-01`,
    {},
    []
  );
  const { data: dataProtectorsTotal } = useFetch(
    `custom/stats/protector?start_date=1970-01-01`,
    {},
    []
  );
  const { data: dataProtectorBreakdownByUnits } = useFetch(
    `custom/stats/protector/units`,
    {},
    []
  );
  const { data: dataProtectorBreakdownByFormation } = useFetch(
    `custom/stats/protector/formation`,
    {},
    []
  );
  const { data: dataUnapproved } = useFetch(
    `items/protector_assignment?meta=filter_count&filter[status]=assignment request`,
    {},
    []
  );
  const { get: getProtectors, data: dataProtectors } = useFetch(
    `custom/stats/protector`
  );
  const { get: getReviewProtectee, data: dataReviewProtectee } = useFetch(
    `items/protectee`,
    {
      cache: "no-cache",
    }
  );
  const { get: getDashboardReport } = useFetch(`custom/stats/dashboardReport`);
  const { get: getDashboardReportHTP } = useFetch(
    `custom/stats/dashboardReportHTP`
  );
  function save(filename, data) {
    var blob = new Blob([data], { type: "text/csv" });
    if (typeof window.navigator.msSaveOrOpenBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
  async function downloadReport(data) {
    const parser = new Parser();
    save("report.csv", parser.parse(data));
  }

  React.useEffect(() => {
    const date = moment()
      .subtract(6, "months")
      .subtract(15, "days")
      .format("YYYY-MM-DD");
    getReviewProtectee(
      `?filter[last_review_date][lte]=${date}&limit=5&fields=*.*`
    );
  }, [getReviewProtectee]);
  const [protecteeDate, setProtecteeDate] = React.useState<0 | 1 | 2>(0);
  const [protectorDate, setProtectorDate] = React.useState<0 | 1 | 2>(0);
  const [graphProtectorData, setGraphProtectorData] = React.useState<{
    labels: string[];
    count: number[];
    tCount: number[];
  }>();
  const [graphProtecteeData, setGraphProtecteeData] = React.useState<{
    labels: string[];
    count: number[];
    tCount: number[];
  }>();
  const [showReportModal, setShowReportModal] = React.useState<0 | 1 | 2>(0);
  React.useEffect(() => {
    let date = moment();
    if (protecteeDate === 0) {
      date.subtract(1, "weeks");
    } else if (protecteeDate === 1) {
      date.subtract(1, "months");
    } else {
      date.subtract(1, "year");
    }
    get(`?start_date=${date.format("YYYY-MM-DD")}`);
    getProtecteesSub(`?start_date=${date.format("YYYY-MM-DD")}`);
  }, [get, protecteeDate, getProtecteesSub]);
  React.useEffect(() => {
    let date = moment();
    if (protectorDate === 0) {
      date.subtract(1, "weeks");
    } else if (protectorDate === 1) {
      date.subtract(1, "months");
    } else {
      date.subtract(1, "year");
    }
    getProtectors(`?start_date=${date.format("YYYY-MM-DD")}`);
    getProtectorsSub(`?start_date=${date.format("YYYY-MM-DD")}`);
  }, [getProtectors, getProtectorsSub, protectorDate]);
  // console.log(dataStats?.data.protecteeStats);

  React.useEffect(() => {
    if (
      dataProtectees &&
      dataProtecteesSub &&
      dataProtecteesSubTotal &&
      dataProtecteesTotal
    ) {
      const g: {
        labels: string[];
        count: number[];
        tCount: number[];
      } = {
        labels: [],
        count: [],
        tCount: [],
      };
      dataProtecteesTotal.data.forEach((d) => {
        let tCount = d.count;
        let count =
          dataProtectees?.data.find((a) => a.title === d.title)?.count || 0;
        let subCountTotal =
          dataProtecteesSubTotal.data
            .filter((a) => a.title === d.title && a.sTitle.includes("Category"))
            ?.reduce((c, a) => {
              return (c = c + parseInt(a.count));
            }, 0) || 0;
        let subCount =
          dataProtecteesSub.data
            .filter((a) => a.title === d.title && a.sTitle.includes("Category"))
            ?.reduce((c, a) => {
              return (c = c + parseInt(a.count));
            }, 0) || 0;
        let other = dataProtecteesSub.data.filter(
          (a) => a.title === d.title && !a.sTitle.includes("Category")
        );
        let otherCount =
          other?.reduce((c, a) => {
            return (c = c + parseInt(a.count));
          }, 0) || 0;
        let otherCountTotal =
          dataProtecteesSubTotal.data
            .filter(
              (a) => a.title === d.title && !a.sTitle.includes("Category")
            )
            ?.reduce((c, a) => {
              return (c = c + parseInt(a.count));
            }, 0) || 0;
        g.labels.push(d.title);
        g.count.push(count - subCount - otherCount);
        g.tCount.push(tCount - subCountTotal - otherCountTotal);
        if (subCountTotal || subCount) {
          g.labels.push(`${d.title} + Category`);
          g.count.push(subCount);
          g.tCount.push(subCountTotal);
        }
        if (otherCount) {
          g.labels.push(`${d.title} + ${other[0]?.sTitle}`);
          g.count.push(otherCount);
          g.tCount.push(otherCountTotal);
        }
      });

      setGraphProtecteeData(sortByPriority(g));
    }
  }, [
    dataProtectees,
    dataProtecteesSub,
    setGraphProtecteeData,
    dataProtecteesTotal,
    dataProtecteesSubTotal,
  ]);
  React.useEffect(() => {
    if (
      dataProtectors &&
      dataProtectorsSub &&
      dataProtectorsSubTotal &&
      dataProtectorsTotal
    ) {
      const g: {
        labels: string[];
        count: number[];
        tCount: number[];
      } = {
        labels: [],
        count: [],
        tCount: [],
      };
      dataProtectorsTotal.data.forEach((d) => {
        let tCount = d.count;
        let count =
          dataProtectors?.data.find((a) => a.title === d.title)?.count || 0;
        let subCountTotal =
          dataProtectorsSubTotal.data
            .filter((a) => a.title === d.title && a.sTitle.includes("Category"))
            ?.reduce((c, a) => {
              return (c = c + parseInt(a.count));
            }, 0) || 0;
        let subCount =
          dataProtectorsSub.data
            .filter((a) => a.title === d.title && a.sTitle.includes("Category"))
            ?.reduce((c, a) => {
              return (c = c + parseInt(a.count));
            }, 0) || 0;
        let other = dataProtectorsSub.data.filter(
          (a) => a.title === d.title && !a.sTitle.includes("Category")
        );
        let otherCount =
          other?.reduce((c, a) => {
            return (c = c + parseInt(a.count));
          }, 0) || 0;
        let otherCountTotal =
          dataProtectorsSubTotal.data
            .filter(
              (a) => a.title === d.title && !a.sTitle.includes("Category")
            )
            ?.reduce((c, a) => {
              return (c = c + parseInt(a.count));
            }, 0) || 0;
        g.labels.push(d.title);
        g.count.push(count - subCount - otherCount);
        g.tCount.push(tCount - subCountTotal - otherCountTotal);
        if (subCountTotal || subCount) {
          g.labels.push(`${d.title} + Category`);
          g.count.push(subCount);
          g.tCount.push(subCountTotal);
        }
        if (otherCount) {
          g.labels.push(`${d.title} + ${other[0]?.sTitle}`);
          g.count.push(otherCount);
          g.tCount.push(otherCountTotal);
        }
      });
      console.log(g);
      setGraphProtectorData(sortByPriority(g));
    }
  }, [
    dataProtectors,
    dataProtectorsSub,
    setGraphProtectorData,
    dataProtectorsTotal,
    dataProtectorsSubTotal,
  ]);

  if (graphProtecteeData && graphProtectorData) {
    return (
      <React.Fragment>
        {/* DASHBOARD */}
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Dashboard</h1>
            {/* <p className="text-faded">Punjab Police Portal</p> */}
          </div>
          <div className="d-flex">
            <div className="form-group mb-0">
              <ESelect<any>
                label="Protectee"
                items={dataAllProtectee?.data || []}
                filterKey="name"
                onValuesChange={(items) => {
                  if (items.length > 0 && items[0]?.id) {
                    setSelectedProtectee(items[0]);
                  }
                }}
                defaultValues={selectedProtectee ? [selectedProtectee] : []}
                onQuery={async (q, setItems) => {
                  const d = await getAllProtectee(
                    `?fields=id,name,designation.title,organisation.title,photo&q=${q}`
                  );
                  setItems(d?.data || []);
                }}
                renderItem={(item) => (
                  <div>
                    <img
                      width={24}
                      className="border-radius-50 mr-2"
                      src={API_URL + item.photo}
                      alt={item.name}
                    />
                    {item.name} ({item.designation?.title}-{" "}
                    {item.organisation?.title})
                  </div>
                )}
              />
            </div>
            <button
              onClick={() => {
                setShowSummaryModal(true);
              }}
              className="btn btn-outline-secondary mr-2"
            >
              <Search />
            </button>
            <a className="btn btn-secondary" href={PATHS.protectee}>
              <Plus /> Add Protectee
            </a>
          </div>
        </div>
        <div className="main-body">
          {/* STATS */}
          <div className="form-row mb-4">
            <div className="col-md-6 col-xl-3 py-1">
              <div className="card card--stat1">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="stat">
                        {dataStats?.data.protecteeStats.total}{" "}
                        <div className="change positive">
                          {dataStats?.data.protecteeStats.increaseCount}
                          <br />
                          <small className="text-muted ml-2">this month</small>
                        </div>
                      </div>
                      <div className="label">Total Protectees</div>
                    </div>
                    <div className="icon">
                      <PersonBoundingBox />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 py-1">
              <div className="card card--stat1">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="stat">
                        {dataStats?.data.protectorStats.total}
                        <div className="change positive">
                          {dataStats?.data.protectorStats.increaseCount}
                          <br />
                          <small className="text-muted ml-2">this month</small>
                        </div>
                      </div>
                      <div className="label">Total Security Personnel</div>
                    </div>
                    <div className="icon">
                      <PeopleFill />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 py-1">
              <div className="card card--stat1">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="stat">
                        {dataUnapproved?.meta.filter_count || 0}
                        {/* <div className="change negative">
                        5%
                        <br />
                        <small className="text-muted ml-2">this month</small>
                      </div> */}
                      </div>
                      <div className="label">Unapproved Security Personnel</div>
                    </div>
                    <div className="icon">
                      <PersonDashFill />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 py-1">
              <div className="card card--stat1">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="stat">
                        {dataStats?.data.vehicleStats.total}
                        {/* <div className="change">-</div> */}
                      </div>
                      <div className="label">Total Vehicles</div>
                    </div>
                    <div className="icon">
                      <Truck />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* QUICK REPORTS */}
          <div className="jumbotron bg-dark p-4 mb-4">
            <div className="form-row d-flex align-items-center">
              <div className="col-lg-4 col-md-12">
                <h2 className="mb-0 text-white">Quick Reports</h2>
                <small className="mb-0 text-white text-faded">
                  Click on any of the reports
                </small>
              </div>
              <div className="col-lg-8 col-md-12">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item m-1">
                    <button
                      onClick={() => setShowReportModal(1)}
                      className="btn btn-light"
                    >
                      Group Assignment
                    </button>
                  </li>
                  {/* <li className="list-inline-item m-1">
                  <button
                    onClick={() => setShowReportModal(1)}
                    className="btn btn-light"
                  >
                    Group Review
                  </button>
                </li> */}
                  {/* <li className="list-inline-item m-1">
                  <button
                    onClick={() => setShowReportModal(2)}
                    className="btn btn-light"
                  >
                    HTP Review
                  </button>
                </li> */}
                  <li className="list-inline-item m-1">
                    <button
                      onClick={() => setShowReportModal(2)}
                      className="btn btn-light"
                    >
                      HTP Assignment
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* DASHBOARD GRID */}
          <div className="dashboard-grid">
            <div className="section-personnel-type">
              <div className="card">
                <div className="card-header">
                  <div>
                    <h5 className="title">Protectee Basis</h5>
                    <small className="text-muted text-semibold">
                      Protectee Basis
                    </small>
                  </div>
                  <div className="right-options">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={() => setProtecteeDate(0)}
                        type="button"
                        className={
                          "btn btn-light " +
                          (protecteeDate === 0 ? "active" : "")
                        }
                      >
                        This week
                      </button>
                      <button
                        onClick={() => setProtecteeDate(1)}
                        type="button"
                        className={
                          "btn btn-light " +
                          (protecteeDate === 1 ? "active" : "")
                        }
                      >
                        This month
                      </button>
                      <button
                        onClick={() => setProtecteeDate(2)}
                        type="button"
                        className={
                          "btn btn-light " +
                          (protecteeDate === 2 ? "active" : "")
                        }
                      >
                        This year
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body text-center">
                  <div className="row">
                    <div className="col-xl-6">
                      {graphProtecteeData && (
                        <Doughnut
                          width={400}
                          height={400}
                          options={{
                            responsive: false,
                          }}
                          data={{
                            labels: graphProtecteeData?.labels,
                            datasets: [
                              {
                                label: "Protectee Report",
                                data: graphProtecteeData?.tCount,
                                backgroundColor: [
                                  "#FF0000",
                                  "#FFFF00",
                                  "#000080",
                                  "#FFA500",
                                  "#800000",
                                  "#008000",
                                ],
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                    <div className="col-xl-6">
                      <table className="table has-text-right">
                        <thead>
                          <tr>
                            <th className="text-left">Type</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {graphProtecteeData?.labels.flatMap((label, i) => (
                            <tr key={i}>
                              <td className="text-left">{label}</td>
                              <td>
                                <small className="mr-2 text-success font-weight-bold">
                                  (+{graphProtecteeData?.count[i]})
                                </small>
                                {graphProtecteeData?.tCount[i]}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th className="text-left">Total</th>
                            <th>
                              {graphProtecteeData?.tCount?.length !== 0
                                ? graphProtecteeData?.tCount.reduce(
                                    (p, c) => Number(p) + Number(c)
                                  )
                                : 0}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="card">
              <div className="card-header">
                <div>
                  <h5 className="title">Breakdown by Type</h5>
                  <small className="text-muted text-semibold">
                    Security Personnel Type
                  </small>
                </div>
              </div>
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6">
                    {dataStats?.data && (
                      <Doughnut
                        width={400}
                        height={400}
                        options={{
                          responsive: true,
                        }}
                        data={{
                          labels: dataStats?.data.deploymentStats.map(
                            (d) => d.title
                          ),
                          datasets: [
                            {
                              label: "Protectee Report",
                              data: dataStats?.data.deploymentStats.map((d) =>
                                Number(d.count)
                              ),
                              backgroundColor: [
                                "#2E2B63",
                                "rgba(255, 206, 86, 1)",
                                "#044171",
                                "#004AFF",
                                "#FD1C3A",
                                "rgba(255, 159, 64, 1)",
                              ],
                            },
                          ],
                        }}
                      />
                    )}
                  </div>
                  <div className="col-xl-6">
                    <table className="table  has-text-right">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Type</th>
                          <th>Total Assigned</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataStats?.data.deploymentStats.flatMap((s, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{s.title}</td>
                            <td>{s.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
            {/* <div className="section-notification">
              <div className="card card--notification">
                <div className="card-header bg-transparent">
                  <div>
                    <h5 className="title">Notifications</h5>
                  </div>
                  <div className="right-options">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option>Show All</option>
                      <option>Security Review</option>
                      <option>Warnings</option>
                    </select>
                  </div>
                </div>
                <div
                  className="card-body no-padding scroll"
                  style={{ height: "200px" }}
                >
                  <ul className="list-group list-group-flush list__notification">
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-auto ">
                          <div className="d-flex align-items-center">
                            <div className="icon">
                              <BellFill />
                            </div>
                          </div>
                        </div>
                        <div className="col ml-n4">
                          <div className="type">Type Here</div>
                          <p className="mb-0">
                            Notification description about this form section
                            comes here
                          </p>
                        </div>
                        <small className="col-auto text-muted text-cap">
                          2hr
                        </small>
                      </div>
                    </li>
                    <li className="list-group-item danger">
                      <div className="row">
                        <div className="col-auto ">
                          <div className="d-flex align-items-center">
                            <div className="icon">
                              <BellFill />
                            </div>
                          </div>
                        </div>
                        <div className="col ml-n4">
                          <div className="type">Security Review</div>
                          <p className="mb-0">
                            Notification description about this form section
                            comes here
                          </p>
                        </div>
                        <small className="col-auto text-muted text-cap">
                          21 Sep
                        </small>
                      </div>
                    </li>
                    <li className="list-group-item warning">
                      <div className="row">
                        <div className="col-auto ">
                          <div className="d-flex align-items-center">
                            <div className="icon">
                              <BellFill />
                            </div>
                          </div>
                        </div>
                        <div className="col ml-n4">
                          <div className="type">Type Here</div>
                          <p className="mb-0">
                            Notification description about this form section
                            comes here
                          </p>
                        </div>
                        <small className="col-auto text-muted text-cap">
                          2hr
                        </small>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="section-assignment">
              <div className="card">
                <div className="card-header">
                  <div>
                    <h5 className="title">Breakdown by Units</h5>
                    <small className="text-muted text-semibold">
                      Total Security Personnel by Units
                    </small>
                  </div>
                </div>
                <div className="card-body text-center">
                  <div className="row">
                    <div className="col">
                      {dataProtectorBreakdownByUnits && (
                        <Bar
                          height={300}
                          options={{
                            maintainAspectRatio: false,
                          }}
                          data={{
                            labels: dataProtectorBreakdownByUnits?.data.map(
                              (d) => d.unit
                            ),
                            datasets: [
                              {
                                label: "Total Personnel",
                                data: dataProtectorBreakdownByUnits?.data.map(
                                  (d) => Number(d.count)
                                ),
                                backgroundColor: "#e03131",
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-assignment">
              <div className="card">
                <div className="card-header">
                  <div>
                    <h5 className="title">Breakdown by District</h5>
                    <small className="text-muted text-semibold">
                      Total Security Personnel by District
                    </small>
                  </div>
                </div>
                <div className="card-body text-center">
                  <div className="row">
                    <div className="col">
                      {dataProtectorBreakdownByUnits && (
                        <Bar
                          height={300}
                          options={{
                            maintainAspectRatio: false,
                          }}
                          data={{
                            labels: dataProtectorBreakdownByUnits?.dataPoliceDistrict.map(
                              (d) => d.unit
                            ),
                            datasets: [
                              {
                                label: "Total Personnel",
                                data: dataProtectorBreakdownByUnits?.dataPoliceDistrict.map(
                                  (d) => Number(d.count)
                                ),
                                backgroundColor: "#2E2B63",
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-assignment">
              <div className="card">
                <div className="card-header">
                  <div>
                    <h5 className="title">Breakdown by Formation</h5>
                    <small className="text-muted text-semibold">
                      Total Security Personnel by Formation
                    </small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row d-flex align-items-center">
                    <div className="col-xl-6">
                      {dataProtectorBreakdownByFormation?.data && (
                        <Doughnut
                          width={400}
                          height={400}
                          options={{
                            responsive: false,
                          }}
                          data={{
                            labels: dataProtectorBreakdownByFormation?.data.map(
                              (d) => d.title
                            ),
                            datasets: [
                              {
                                label: "Protectee Report",
                                data: dataProtectorBreakdownByFormation?.data.map(
                                  (d) => Number(d.count)
                                ),
                                backgroundColor: [
                                  "#2E2B63",
                                  "rgba(255, 206, 86, 1)",
                                  "#044171",
                                  "#004AFF",
                                  "#FD1C3A",
                                  "rgba(255, 159, 64, 1)",
                                ],
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                    <div className="col-xl-6">
                      <table className="table  has-text-right">
                        <thead>
                          <tr>
                            <th>Formation</th>
                            <th>Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataProtectorBreakdownByFormation?.data.flatMap(
                            (s, i) => (
                              <tr key={i}>
                                <td>{s.title}</td>
                                <td>{s.count}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-review">
              <div className="card">
                <div className="card-header">
                  <div>
                    <h5 className="title">Protectees Review</h5>
                    <small className="text-muted text-semibold">
                      Upcoming Reviews
                    </small>
                  </div>
                  <div className="right-options">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        const date = moment()
                          .subtract(monthReview, "months")
                          .subtract(15, "days")
                          .format("YYYY-MM-DD");
                        getReviewProtectee(
                          `?filter[last_review_date][lte]=${date}&limit=5&fields=*.*`
                        );
                      }}
                    >
                      <input
                        style={{ width: 32 }}
                        value={monthReview}
                        className="mr-2 text-center"
                        onChange={(e) =>
                          setMonthReview(Number(e.currentTarget.value))
                        }
                        type="text"
                      />
                      month review
                    </form>
                  </div>
                </div>
                <div className="card-body">
                  <table className="table has-text-right">
                    <thead className="">
                      <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Last Review Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataReviewProtectee?.data.flatMap((protectee, index) => (
                        <tr key={index}>
                          <td>
                            <div className="text-bold d-flex align-items-center">
                              <div className="avatar mr-2">
                                <img
                                  src={API_URL + protectee.photo}
                                  alt={protectee.name}
                                />
                              </div>
                              {protectee.name}
                            </div>
                          </td>
                          <td>{protectee.designation?.title}</td>
                          <td>
                            {moment(protectee?.last_review_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <Empty /> */}
                </div>
              </div>
            </div>
            <div className="section-recently-added">
              <div className="card">
                <div className="card-header">
                  <div>
                    <h5 className="title">Recently Added Protectees</h5>
                  </div>
                  <div className="right-options">
                    <button
                      onClick={() => (window.location.href = PATHS.protectees)}
                      className="btn btn-link"
                    >
                      View More
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <table className="table has-text-right">
                    <thead className="">
                      <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Assigned</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataStats?.data.recentProtectees?.flatMap(
                        (protectee, index) => (
                          <tr key={index}>
                            <td>
                              <div className="text-bold d-flex align-items-center">
                                <div className="avatar mr-2">
                                  <img
                                    src={API_URL + protectee.photo}
                                    alt={protectee.name}
                                  />
                                </div>
                                {protectee.name}
                              </div>
                            </td>
                            <td>{protectee.title}</td>
                            <td>{protectee.total}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="section-security-personnel">
              <div className="card">
                <div className="card-header">
                  <div>
                    <h5 className="title">Security Personnel Report</h5>
                  </div>
                  <div className="right-options">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={() => setProtectorDate(0)}
                        type="button"
                        className={
                          "btn btn-light " +
                          (protectorDate === 0 ? "active" : "")
                        }
                      >
                        This week
                      </button>
                      <button
                        onClick={() => setProtectorDate(1)}
                        type="button"
                        className={
                          "btn btn-light " +
                          (protectorDate === 1 ? "active" : "")
                        }
                      >
                        This month
                      </button>
                      <button
                        onClick={() => setProtectorDate(2)}
                        type="button"
                        className={
                          "btn btn-light " +
                          (protectorDate === 2 ? "active" : "")
                        }
                      >
                        This year
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body text-center">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      {graphProtectorData && (
                        <Doughnut
                          width={400}
                          height={400}
                          options={{
                            responsive: false,
                          }}
                          data={{
                            labels: graphProtectorData?.labels,
                            datasets: [
                              {
                                label: "Security Personnel Report",
                                data: graphProtectorData?.tCount,
                                backgroundColor: [
                                  "#FF0000",
                                  "#FFFF00",
                                  "#000080",
                                  "#FFA500",
                                  "#800000",
                                  "#008000",
                                ],
                              },
                            ],
                          }}
                        />
                      )}
                    </div>
                    <div className="col-xl-6">
                      <table className="table has-text-right">
                        <thead>
                          <tr>
                            <th className="text-left">Type</th>
                            <th>Total Assigned</th>
                          </tr>
                        </thead>
                        <tbody>
                          {graphProtectorData?.labels.flatMap((label, i) => (
                            <tr key={i}>
                              <td className="text-left">{label}</td>
                              <td>
                                <small className="mr-2 text-success font-weight-bold">
                                  (+{graphProtectorData?.count[i]})
                                </small>
                                {graphProtectorData?.tCount[i]}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th className="text-left">Total</th>
                            <th>
                              {graphProtectorData?.tCount?.length !== 0
                                ? graphProtectorData?.tCount.reduce(
                                    (p, c) => Number(p) + Number(c)
                                  )
                                : 0}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showReportModal !== 0 && (
          <GroupReportModal
            type={showReportModal}
            onGenerate={async (from, to, groupId) => {
              if (showReportModal === 1) {
                const response = await getDashboardReport(
                  `?groupId=${groupId}&from=${from}&to=${to}`
                );
                const refined: any[] = [];
                response.data.forEach((d) => {
                  const index = refined.findIndex((a) => a.id);
                  if (index === -1) {
                    refined.push({
                      Id: d.id,
                      Name: d.name,
                      Assigned:
                        response.data.filter(
                          (a) => a.id === d.id && a.status === "assigned"
                        )[0]?.count || 0,
                      Unapproved:
                        response.data.filter(
                          (a) =>
                            a.id === d.id && a.status === "assignment request"
                        )[0]?.count || 0,
                    });
                  }
                });
                await downloadReport(refined);
              } else {
                const response = await getDashboardReportHTP(
                  `?from=${from}&to=${to}`
                );
                const refined: any[] = [];
                response.data.forEach((d) => {
                  const index = refined.findIndex((a) => a.id);
                  if (index === -1) {
                    refined.push({
                      Id: d.id,
                      Name: d.name,
                      Assigned:
                        response.data.filter(
                          (a) => a.id === d.id && a.status === "assigned"
                        )[0]?.count || 0,
                      Unapproved:
                        response.data.filter(
                          (a) =>
                            a.id === d.id && a.status === "assignment request"
                        )[0]?.count || 0,
                    });
                  }
                });
                await downloadReport(refined);
              }
              setShowReportModal(0);
            }}
            onClose={() => setShowReportModal(0)}
          />
        )}
        {showSummaryModal && selectedProtectee && (
          <ProtecteeSummaryModal
            onClose={() => {
              setShowSummaryModal(false);
            }}
            protecteeId={selectedProtectee?.id}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
