import moment from "moment";
import React, { ReactElement } from "react";
import { PencilSquare, Plus, Trash, Upload } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import CommentAddModal from "./comment-add";
import CommentItem from "./comment-item";
import CreateTPRModal from "./CreateTPRModal";
import PreviousTPRModal from "./PreviousTPRModal";
import { TPRModal } from "./tpr-modal";

interface Props {}

export default function TPR({}: Props): ReactElement {
  const [visible, setVisible] = React.useState(false);
  const [showPrevModal, setShowPrevModal] = React.useState(false);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const protectee = useSelector((state: any) => state.protectee);
  const {post} = useFetch(`items/tpr_protectee`);
  const { data } = useFetch(
    `items/comment?filter[protectee]=${protectee.id}&sort=-created_on&fields=*.*`,
    {},
    []
  );
  const [commentAddShow, setCommentAddShow] = React.useState(false);
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-3">
            <h2>TPR</h2>
            <p className="text-muted">Please attach the appropriate TPR.</p>
            {/* <button
              onClick={() => setVisible(true)}
              className="btn btn-outline-primary mt-4"
            >
              Request TPR
            </button> */}
          </div>
          <div className="col-lg-4">
            <button
              type="button"
              onClick={() => setShowPrevModal(true)}
              className="btn btn-outline-primary btn-lg btn-block"
            >
              Previous TPRs
            </button>
          </div>
          <div className="col-lg-4 offset-lg-1">
            <button
              type="button"
              onClick={() => setShowCreateModal(true)}
              className="btn btn-primary btn-lg btn-block"
            >
              <Plus />
              Create New TPR
            </button>
          </div>
        </div>
        <div className="spacer spacer--md"></div>
        <div className="row">
          <div className="col-lg-3">
            <h2>Additional Comments</h2>
            <p className="text-muted">
              Any additional news or comments to be made for the here.
            </p>
            <button
              onClick={() => setCommentAddShow(true)}
              className="btn btn-outline-primary mt-4"
            >
              Add Comment
            </button>
          </div>
          <div className="col-lg-9">
            <div className="log-entry-container">
              {data?.data.flatMap((comment, i) => (
                <CommentItem
                  key={i}
                  type={comment.type}
                  date={moment(comment.created_on)}
                  comment={comment.comment}
                  name={comment.owner.first_name}
                  attachment={comment.attachment_url}
                />
              ))}
            </div>
          </div>
        </div>
        {commentAddShow && (
          <CommentAddModal onClose={() => setCommentAddShow(false)} />
        )}
        {showPrevModal && (
          <PreviousTPRModal
            onClose={() => setShowPrevModal(false)}
            protecteeId={protectee.id}
          />
        )}
        {showCreateModal && (
          <CreateTPRModal
            onClose={() => setShowCreateModal(false)}
            onSave={async (item) => {
              await post({
                protectee:  protectee.id,
                ssp_report: item.ssp,
                intelligence_report: item.ir,
                ib_report: item.ib,
                other_title: item.otherTitle,
                other_report: item.otherReport
              });
              setShowCreateModal(false);
            }}
          />
        )}
        <TPRModal
          info={{
            designation: protectee.personalInfo?.category?.title,
            name: protectee.personalInfo?.full_name,
            organisation: protectee.personalInfo?.organisation?.title,
          }}
          onHide={() => setVisible(false)}
          visible={visible}
        />
      </div>
    </div>
  );
}
