import React, { ReactElement } from "react";
import { API_URL } from "../../..";

interface Props {
  date: moment.Moment;
  type: string;
  comment: string;
  name: string;
  attachment?: string;
}

export default function CommentItem({
  date,
  type,
  name,
  attachment,
  comment,
}: Props): ReactElement {
  return (
    <div className="log-entry">
      <div className="date">
        <div className="inner">
          <h1 className="display-5 mb-0">{date.format("DD")}</h1>
          <p className="small text-bold text-uppercase mb-0">
            {date.format("MMM")} <br />
            <small className="text-muted">{date.format("YYYY")}</small>
          </p>
        </div>
      </div>
      <div className="content">
        <h4 className="mb-2">{type}</h4>
        <p>{comment}</p>
        <div>
          <small className="text-muted">
            <em>By: {name}</em>
          </small>
        </div>
        {attachment && (
          <>
            <small className="text-muted text-bold">Attachment</small>
            <div className="attachments">
              <div>
                <a href={API_URL + attachment} target="_blank" rel="noreferrer">
                  {attachment}
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
