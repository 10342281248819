import moment from "moment";
import React, { ReactElement } from "react";
import { Plus, PencilSquare, TrashFill } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import useFetch from "use-http";
import AddEditModal from "../../../common/components/AddEditModal";
import ETable from "../../../common/components/ETable/ETable";
import { Protector } from "../../../common/models/models";
import AddEditModalProtector from "../../protectors/AddEditModalProtector";
import { LoadingScreen } from "./../../../common/components/LoadingScreen";
import EditWeaponModalUnit from "./EditWeaponModalUnit";

export default function PersonnelPage(): ReactElement {
  const [show, setShow] = React.useState(false);
  const [showDeleteModel, setShowDeleteModel] = React.useState(false);
  const [delItemID, setDelItemID] = React.useState(String);

  const userDetail = useSelector((state: any) => state.user.userDetail);
  const [selected, setSelected] = React.useState<Protector | undefined>();
  const { data: uData, get } =
    useFetch<{ data: Protector[]; meta: any }>(`items/protector`);

  const [showWeaponModal, setShowWeaponModal] = React.useState<boolean>(false);

  const [data, setData] = React.useState<any>();
  const { data: dataFormations } = useFetch("items/formation", {}, []);
  const { data: dataRanks } = useFetch("items/rank_type?sort=priority", {}, []);
  const { data: dataHeights } = useFetch("items/height", {}, []);
  const { data: dataUnits, get: getUnits } = useFetch("items/unit");
  const { data: dataTrainings } = useFetch("items/training", {}, []);

  React.useEffect(() => {
    let url = `?fields=*.*&limit=10&meta=filter_count&filter[unit.formation]=${userDetail.formation.id}`;
    if (userDetail.unit) {
      url = `?fields=*.*&limit=10&meta=filter_count&filter[unit]=${userDetail.unit.id}`;
    }
    get(url);
  }, [get, userDetail]);
  React.useEffect(() => {
    if (uData) {
      setData(
        uData.data.flatMap((d) => {
          d.dob = moment(d.dob, "YYYY-MM-DD").format("DD-MM-YYYY");
          return d;
        })
      );
    }
  }, [uData, setData]);
  const { del, post, patch } = useFetch("items/protector");
  const { del: delTrainings, get: getTrainings } = useFetch(
    "items/protector_training"
  );

  if (dataFormations && dataRanks && dataHeights && dataTrainings) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Security Personnel</h1>
          </div>
          <div>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setSelected(undefined);
                setShow(true);
              }}
            >
              <Plus /> Add Personnel
            </button>
          </div>
        </div>
        <div className="main-body">
          {/* TABS */}
          <div className="card h-auto">
            <div className="card-body">
              <ETable<Protector>
                columns={[
                  { key: "id", label: "Id" },
                  { key: "name", label: "Name" },
                  { key: "mobile_no", label: "Mobile No" },
                  { key: "rank.title", label: "Rank" },
                  { key: "belt_no", label: "Belt No" },
                  { key: "unit.title", label: "Unit" },
                ]}
                customColumns={[
                  {
                    heading: "Status",
                    renderer: (item) => (
                      <span
                        className={
                          "badge text-uppercase badge-pill " +
                          (item.status === "assigned"
                            ? "badge-success"
                            : "badge-light")
                        }
                      >
                        {item.status}
                      </span>
                    ),
                  },
                ]}
                onChange={(query, sortKey, isSortAsc, page) => {
                  let url = `?fields=*.*&limit=10&meta=filter_count&filter[unit.formation]=${userDetail.formation.id}`;
                  if (userDetail.unit) {
                    url = `?fields=*.*&limit=10&meta=filter_count&filter[unit]=${userDetail.unit.id}`;
                  }

                  let v = `${url}&offset=${(page - 1) * 10}`;
                  if (query.length > 0) {
                    v += `&filter[name][like]=${query}&offset=${
                      (page - 1) * 10
                    }`;
                  }
                  if (sortKey) {
                    v += `&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                  }
                  get(v);
                }}
                totalPages={Math.ceil((uData?.meta?.filter_count || 10) / 10)}
                items={data || []}
                actionButtons={[
                  {
                    title: "Edit Weapon",
                    type: "primary",
                    onClick: (item) => {
                      setSelected(item);
                      setShowWeaponModal(true);
                    },
                  },
                  {
                    title: "",
                    icon: <PencilSquare size={16} />,
                    type: "",
                    onClick: (item) => {
                      setSelected(item);
                      getUnits(`?filter[formation]=${item.unit.formation}`);
                      setShow(true);
                    },
                  },
                  // {
                  //   title: "",
                  //   icon: <TrashFill size={16} />,
                  //   type: "danger",
                  //   onClick: async (item) => {
                  //     setShowDeleteModel(true);
                  //     setDelItemID(item?.id?.toString() || "");
                  //   },
                  // },
                ]}
              />
            </div>
          </div>
        </div>

        {showDeleteModel && (
          <>
            <div className="modal-backdrop"></div>
            <div
              className="modal"
              id="modelId"
              tabIndex={-1}
              role="dialog"
              style={{ display: "block" }}
              aria-labelledby="modelTitleId"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-scrollable modal-sm"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm Delete !!</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => setShowDeleteModel(false)}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to delete?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-muted"
                      onClick={() => setShowDeleteModel(false)}
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-muted"
                      onClick={async () => {
                        await del(delItemID);
                        window.location.reload();
                      }}
                      data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {show && (
          <AddEditModalProtector<Protector>
            defaultItem={selected}
            onClose={() => setShow(false)}
            title="Security Personnel"
            fields={[
              {
                key: "name",
                label: "Name",
                type: "text",
              },
              {
                key: "belt_no",
                label: "Id No. (RMC/Belt No)",
                type: "text",
              },
              {
                key: "mobile_no",
                label: "Mobile No",
                type: "text",
              },
              {
                key: "dob",
                label: "Date of Birth",
                type: "date",
                inputOptions: {
                  defaultValue: moment(selected?.dob, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  ),
                },
              },
              {
                key: "blood_group",
                label: "Blood Group",
                type: "text",
              },
              {
                key: "formation",
                label: "Formation",
                selectItems: {
                  items: userDetail.unit
                    ? [userDetail.unit.formation]
                    : userDetail.formation
                    ? [userDetail.formation]
                    : dataFormations.data || [],
                  labelKey: "title",
                },
                onChange: (v) => {
                  getUnits(`?filter[formation]=${v}`);
                },
                type: "select",
                isDummy: true,
              },
              {
                key: "unit",
                label: "Unit/District",
                selectItems: {
                  items: userDetail.unit
                    ? [userDetail.unit]
                    : dataUnits?.data || [],
                  labelKey: "title",
                },
                type: "custom-select",
                customSelectOptions: {
                  filterKey: "title",
                  renderItem: (item) => <p>{item.title}</p>,
                },
              },
              {
                key: "rank",
                label: "Rank",
                selectItems: {
                  items: dataRanks?.data || [],
                  labelKey: "title",
                },
                type: "select",
              },
              {
                key: "height",
                label: "Height",
                selectItems: {
                  items: dataHeights?.data || [],
                  labelKey: "title",
                },
                type: "select",
              },
              {
                key: "annual_medical",
                label: "Annual Medical",
                type: "checkbox",
              },
              {
                type: "multiple-checkboxes",
                key: "trainings",
                label: "Trainings",
                multipleCheckboxesOptions: {
                  labelKey: "title",
                  items: dataTrainings?.data || [],
                  defaultSelected:
                    selected?.trainings?.flatMap((a) =>
                      dataTrainings.data.find((t) => t.id === a.training)
                    ) || [],
                },
              },
            ]}
            onSubmit={async (protector, isEdit) => {
              protector.unit = protector.unit.id;
              protector.rank = protector.rank.id;
              if (isEdit) {
                const {
                  id,
                  name,
                  created_on,
                  rank,
                  belt_no,
                  unit,
                  height,
                  trainings,
                  dob,
                  ...p
                } = protector;
                const d = {
                  ...p,
                  name: name,
                  belt_no: belt_no,
                  dob: moment(dob).format("YYYY-MM-DD"),
                  rank: rank,
                  unit: unit?.id,
                  trainings: trainings?.flatMap((a) => {
                    return { training: { id: a.id } };
                  }),
                  height: height?.id,
                };
                const r = await getTrainings(
                  `?filter[protector]=${id}&fields=id`
                );
                const promises: Promise<any>[] = [];
                r?.data.forEach((a) => {
                  promises.push(delTrainings(a.id.toString()));
                });
                await Promise.all(promises);
                await patch(`${id}?fields=*.*.*.*`, d);
              } else {
                await post({
                  ...protector,
                  trainings: protector.trainings?.flatMap((a) => {
                    return { training: { id: a.id } };
                  }),
                });
              }
              window.location.reload();
            }}
          />
        )}
        {showWeaponModal && (
          <EditWeaponModalUnit
            item={selected}
            onClose={() => setShowWeaponModal(false)}
            onSave={async (item) => {
              window.location.reload();
            }}
          />
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
