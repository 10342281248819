import { ESelect } from "../../../common/library";
import React from "react";
import { X } from "react-bootstrap-icons";
import useFetch from "use-http";
import { API_URL } from "../../..";
import EFileUpload from "../../../common/components/EFileUpload/EFileUpload";

interface Props {
  visible: boolean;
  info: {
    name: string;
    designation: string;
    organisation: string;
  };
  onHide: () => void;
}

interface Template {
  name: string;
  template: string;
}

export const TPRModal = (props: Props) => {
  const { data: templates } = useFetch<{ data: Template[] }>(
    "items/template",
    {},
    []
  );
  const [attachments, setAttachments] = React.useState<string[]>([]);
  const { data: emails } = useFetch("items/email", {}, []);
  const [letter, setLetter] = React.useState("");
  const [selectedTemplate, setSelectedTemplate] = React.useState<Template>();
  const [content, setContent] = React.useState("");
  React.useEffect(() => {
    if (templates && selectedTemplate?.template) {
      setLetter(
        selectedTemplate.template
          .replaceAll("{{name}}", "Sir/Ma'am")
          .replaceAll("{{protectee_name}}", props.info.name)
          .replaceAll("{{protectee_designation}}", props.info.designation || "")
          .replaceAll(
            "{{protectee_organisation}}",
            props.info.organisation || ""
          )
          .replaceAll(
            "{{extra_content}}",
            content.length ? "\n" + content + "\n" : ""
          )
          .replaceAll(
            "{{attachments}}",
            attachments.length > 0
              ? `Attachments\n${attachments
                  .flatMap((a) => API_URL + a)
                  .join("\n")}`
              : ""
          )
      );
    }
  }, [selectedTemplate, props.info, templates, content, attachments]);
  return (
    <div
      style={{
        display: `${props.visible ? "block" : "none"}`,
        background: "#00000066",
      }}
      className="modal"
      tabIndex={-1}
    >
      <div
        data-backdrop="static"
        className="modal-dialog modal-xl modal-dialog-scrollable"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Request TPR</h5>
            <button
              onClick={() => props.onHide()}
              type="button"
              className="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ESelect<Template>
              label="Template"
              onValuesChange={(items) => {
                if (items.length) setSelectedTemplate(items[0]);
              }}
              renderItem={(item) => <div>{item.name}</div>}
              filterKey="name"
              items={templates?.data || []}
            />
            <hr />
            <h6>Preview</h6>
            <p style={{ whiteSpace: "pre-wrap" }}>{letter}</p>
            <hr />
            <div>
              {attachments.flatMap((url, i) => (
                <React.Fragment key={i}>
                  <a href={API_URL + url} rel="noreferrer" target="_blank">
                    {url}
                  </a>
                  <span
                    role="button"
                    className="ml-2 text-danger"
                    onClick={() => {
                      setAttachments([
                        ...attachments.slice(0, i),
                        ...attachments.slice(i + 1),
                      ]);
                      console.log(i);
                    }}
                  >
                    <X />
                  </span>
                  <br />
                </React.Fragment>
              ))}
            </div>
            <EFileUpload
              onUpload={(url) => {
                setAttachments([...attachments, url]);
              }}
            />
            <div className="form-group">
              <textarea
                value={content}
                style={{ height: "8em" }}
                placeholder="Type any extra comments here..."
                onChange={(e) => setContent(e.target.value)}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={4}
              ></textarea>
            </div>
            <h6>Select Contacts to send request</h6>
            <div style={{ maxHeight: "250px", overflowY: "auto" }}>
              {emails &&
                emails.data.flatMap((email) => (
                  <div
                    key={email.id}
                    className="form-check p-0 m-0 col-lg-6 form-check-inline"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={email.title}
                      value={email.email}
                    />
                    <label className="form-check-label" htmlFor={email.title}>
                      {email.title} - {email.email}
                    </label>
                  </div>
                ))}
            </div>
            <div className="form-group my-3">
              <h6>Specify any other emails (comma-separated)</h6>
              <input
                type="text"
                className="form-control"
                placeholder="john@doe.com,other@pbpolice.com"
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => props.onHide()}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Send Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
