import React, { ReactElement } from "react";

interface Props {
  title: string;
  subtitle: string;
  actionButtonText: string;
  onPress: () => void;
}

export default function Empty({
  title,
  subtitle,
  actionButtonText,
  onPress,
}: Props): ReactElement {
  return (
    <div className="error-msg">
      <h2 className="">{title}</h2>
      <p className="text-muted">{subtitle}</p>
      <button className="btn btn-secondary mt-4" onClick={() => onPress()}>
        {actionButtonText}
      </button>
    </div>
  );
}
