import React, { ReactElement, useState } from "react";
import { ESelect } from "../../common/library";
import MultipleCheckboxes from "../../common/components/MultipleCheckboxes";
import moment from "moment";

interface Props<T> {
  title?: string;
  defaultItem?: T;
  onSubmit: (item: T, isEdit: boolean) => Promise<any>;
  onClose: () => void;
  fields: {
    isDummy?: boolean;
    required?: boolean;
    type:
      | "number"
      | "checkbox"
      | "select"
      | "custom-select"
      | "multiple-checkboxes"
      | "text"
      | "email"
      | "date"
      | "text-area";
    key: string;
    multipleCheckboxesOptions?: {
      items: any[];
      labelKey: string;
      defaultSelected: any[];
    };
    customSelectOptions?: {
      onQuery?: (q, setItems) => Promise<void>;
      filterKey: string;
      renderItem: (item) => JSX.Element;
      isMulti?: boolean;
      defaultValues?: any[];
    };
    inputOptions?: {
      defaultValue?: string;
      disabled?: boolean;
    };
    onChange?: (value) => void;
    label: string;
    selectItems?: {
      labelKey: string;
      items: any[];
    };
  }[];
}

export default function AddEditModalProtector<T>({
  defaultItem,
  title,
  onSubmit,
  onClose,
  fields = [],
}: Props<T>): ReactElement {
  const [item, setItem] = React.useState<any>(defaultItem);
  React.useEffect(() => {}, [item]);
  // console.log(item);
  // console.log(fields.map((e) => e.key));
  const [error, setError] = useState(false);
  // const [error, setAgeBelowError] = useState(false);
  const [emptyFields, setEmptyFields] = useState<boolean[]>(
    [...Array(fields.length)].fill(true)
  );
  console.log(item);
  return (
    <>
      <div className="modal-backdrop"></div>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          let isFilled = fields.map((e) =>
            e.key !== "formation" &&
            e.key !== "annual_medical" &&
            e.key !== "trainings" &&
            e.key !== "height" &&
            e.key !== "blood_group"
              ? item
                ? item[e.key]
                  ? true
                  : false
                : false
              : true
          );
          setEmptyFields(isFilled);
          // console.log(isFilled);
          // console.log("isFilled", isFilled.includes(false));
          if (isFilled.length > 0 && isFilled.includes(false)) {
            setError(true);
          } else {
            setError(false);
            const def = moment(
              defaultItem && defaultItem["dob"],
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD");

            item.dob === (defaultItem && defaultItem["dob"])
              ? (item.dob = def)
              : console.log("Diff", item.dob);
            // item.dob = moment(item.dob).format("YYYY-MM-DD");
            console.log("item ", item);
            await onSubmit(item!, Boolean(defaultItem));
            onClose();
          }
        }}
      >
        <div
          className="modal"
          style={{ display: "block" }}
          tabIndex={-1}
          role="dialog"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">
                  {defaultItem ? "Edit" : "Add"} {title}
                </h3>
                <button
                  type="button"
                  onClick={() => onClose()}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body row">
                {fields.flatMap((field, index) => {
                  if (field.type === "custom-select") {
                    return (
                      <div key={index} className={`form-group col-lg-6`}>
                        <ESelect<any>
                          style={
                            emptyFields[index] === false
                              ? {
                                  border: "1px solid red",
                                }
                              : {}
                          }
                          filterKey={field.customSelectOptions!.filterKey}
                          items={field.selectItems!.items}
                          label={field.label}
                          renderItem={(item) =>
                            field.customSelectOptions!.renderItem(item)
                          }
                          defaultValues={
                            field?.customSelectOptions?.defaultValues
                              ? field?.customSelectOptions?.defaultValues
                              : defaultItem?.[field.key]
                              ? [defaultItem?.[field.key]]
                              : undefined
                          }
                          isMulti={field.customSelectOptions?.isMulti}
                          onQuery={field.customSelectOptions!.onQuery}
                          onValuesChange={(items) => {
                            if (field.customSelectOptions?.isMulti) {
                              if (items.length) {
                                if (field.onChange) field.onChange(items);
                                if (field.isDummy) {
                                  return;
                                }
                                setItem({
                                  ...item,
                                  [field.key]: items,
                                });
                              }
                            } else {
                              if (items.length && items[0]?.id) {
                                if (field.onChange) field.onChange(items);
                                if (field.isDummy) {
                                  return;
                                }
                                setItem({
                                  ...item,
                                  [field.key]: items[0],
                                });
                              }
                            }
                          }}
                        />
                        {emptyFields &&
                          emptyFields[index] === false &&
                          field.key === "unit" && (
                            <small className="text-danger p-0 m-0">
                              Please select formation first
                            </small>
                          )}
                      </div>
                    );
                  } else if (field.type === "select") {
                    return (
                      <div key={index} className="form-group col-lg-6">
                        <label htmlFor="type">{field.label}</label>
                        <select
                          //   required={field.required}
                          onChange={(e) => {
                            // if (field?.key === "formation") {
                            //   let temp = emptyFields;
                            //   temp[index] = true;
                            //   setEmptyFields(temp);
                            // }
                            if (field.onChange)
                              field.onChange(Number(e.target.value));
                            if (field.isDummy) {
                              return;
                            }
                            setItem({
                              ...item,
                              [field.key]: field.selectItems?.items.find(
                                (a) => a.id === Number(e.target.value)
                              ),
                            });
                          }}
                          defaultValue={
                            field?.key !== "formation"
                              ? defaultItem?.[field.key]?.id || "-1"
                              : defaultItem &&
                                (defaultItem["unit"]!.formation || "-1")
                          }
                          className={`form-control ${
                            emptyFields
                              ? (emptyFields[index] === false ||
                                  emptyFields[index + 1] === false) &&
                                "border border-danger"
                              : ""
                          }`}
                          name={field.key}
                          id={field.key}
                        >
                          <option value="-1" selected disabled>
                            Select {field.label}
                          </option>
                          {field.selectItems?.items.flatMap((item, i) => (
                            <option
                              selected={
                                defaultItem?.[field.key]
                                  ? defaultItem?.[field.key].id === item.id
                                  : false
                              }
                              key={i}
                              value={item.id}
                            >
                              {field.selectItems
                                ? item[field.selectItems.labelKey]
                                : item.id}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  } else if (field.type === "checkbox") {
                    return (
                      <div key={index} className="col-lg-6">
                        <div className="form-group  form-check">
                          <input
                            type="checkbox"
                            className={`form-check-input ${
                              emptyFields
                                ? emptyFields[index] === false &&
                                  "border border-danger"
                                : ""
                            }`}
                            id={field.key}
                            onChange={(e) => {
                              if (field.onChange)
                                field.onChange(e.target.value);
                              if (field.isDummy) {
                                return;
                              }
                              setItem({
                                ...item,
                                [field.key]: e.target.checked,
                              });
                            }}
                            defaultChecked={defaultItem?.[field.key]}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={field.key}
                          >
                            {field.label}
                          </label>
                        </div>
                      </div>
                    );
                  } else if (field.type === "text-area") {
                    return (
                      <div key={index} className="form-group col-lg-12">
                        <label htmlFor={field.key}>{field.label}</label>
                        <textarea
                          className={`form-control ${
                            emptyFields
                              ? emptyFields[index] === false &&
                                "border border-danger"
                              : ""
                          }`}
                          name={field.key}
                          onChange={(e) => {
                            if (field.onChange) field.onChange(e.target.value);
                            if (field.isDummy) {
                              return;
                            }
                            setItem({
                              ...item,
                              [field.key]: e.target.value,
                            });
                          }}
                          id={field.key}
                          style={{ height: 120 }}
                          defaultValue={defaultItem?.[field.key]}
                        ></textarea>
                      </div>
                    );
                  } else if (field.type === "multiple-checkboxes") {
                    return (
                      <div key={index} className="form-group form-check">
                        <label>{field.label}</label>
                        <MultipleCheckboxes
                          labelKey={
                            field.multipleCheckboxesOptions?.labelKey || "title"
                          }
                          items={field.multipleCheckboxesOptions?.items || []}
                          defaultSelected={
                            field.multipleCheckboxesOptions?.defaultSelected
                              ? field.multipleCheckboxesOptions?.defaultSelected
                              : defaultItem?.[field.key] || []
                          }
                          onChange={(selected) => {
                            console.log(selected);
                            setItem({
                              ...item,
                              [field.key]: selected,
                            });
                          }}
                        />
                      </div>
                    );
                  } else if (field.type === "date") {
                    return (
                      <div key={index} className="form-group  col-lg-6">
                        <label htmlFor={field.key}>{field.label}</label>
                        <input
                          type={field.type}
                          className={`form-control ${
                            emptyFields
                              ? emptyFields[index] === false &&
                                "border border-danger"
                              : ""
                          }`}
                          name={field.key}
                          id={field.key}
                          onChange={(e) => {
                            const { value } = e.target;
                            let today = moment();
                            if (today.diff(moment(value), "years") >= 18) {
                              if (field.onChange)
                                field.onChange(
                                  moment(e.target.value).format("YYYY-MM-DD")
                                );
                              if (field.isDummy) {
                                return;
                              }
                              setItem({
                                ...item,
                                [field.key]: moment(e.target.value).format(
                                  "YYYY-MM-DD"
                                ),
                              });
                              setEmptyFields((temp) => ({
                                ...temp,
                                [index]: true,
                              }));
                            } else {
                              if (field?.key === "dob") {
                                setEmptyFields((temp) => ({
                                  ...temp,
                                  [index]: false,
                                }));
                              }
                            }
                          }}
                          defaultValue={
                            field.inputOptions?.defaultValue
                              ? field.inputOptions?.defaultValue
                              : defaultItem?.[field.key]
                          }
                          disabled={field.inputOptions?.disabled}
                          placeholder={field.label}
                        />
                        {emptyFields && emptyFields[index] === false && (
                          <small className="text-danger">
                            Age must be above 18 years.
                          </small>
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="form-group  col-lg-6">
                        <label htmlFor={field.key}>{field.label}</label>
                        <input
                          type={field.type}
                          className={`form-control ${
                            emptyFields
                              ? emptyFields[index] === false &&
                                "border border-danger"
                              : ""
                          }`}
                          name={field.key}
                          id={field.key}
                          onChange={(e) => {
                            if (field.onChange) field.onChange(e.target.value);
                            if (field.isDummy) {
                              return;
                            }
                            setItem({
                              ...item,
                              [field.key]: e.target.value,
                            });
                          }}
                          defaultValue={
                            field.inputOptions?.defaultValue
                              ? field.inputOptions?.defaultValue
                              : defaultItem?.[field.key]
                          }
                          disabled={field.inputOptions?.disabled}
                          placeholder={field.label}
                        />
                      </div>
                    );
                  }
                })}
              </div>
              <div className="modal-footer">
                {error && (
                  <span className="text-danger w-50 align-self-center">
                    ** Please fill the form completely **
                  </span>
                )}
                <button
                  type="button"
                  className="btn btn-muted"
                  data-dismiss="modal"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
