import React, { PropsWithChildren, ReactElement } from "react";
import { List } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { routeSlice } from "../../reducers/routes";
import { uiSlice } from "../../reducers/ui";

interface Props {
  headerRight?: ReactElement;
  sideBar?: ReactElement;
}

export default function AppWrapper({
  headerRight,
  sideBar = <div></div>,
  children,
}: PropsWithChildren<Props>): ReactElement {
  const dispatch = useDispatch();
  const routes = useSelector((state:any)=>state.routes.routes)
  React.useEffect(() => {
    dispatch(routeSlice.actions.setActive(routes.find(r=>r.route===window.location.pathname)))
  }, [dispatch,routes]);
  const isCollapsed = useSelector((state: any) => state.ui.collapsed);
  return (
    <div className={`app-wrapper ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar">
        <div className="sidebar--inner">{sideBar}</div>
      </div>
      <div className="main-container">
        <div className="topbar">
          <div className="d-flex justify-content-between">
            <div className="left">
              <div
                onClick={() => {
                  dispatch(uiSlice.actions.setCollapse(!isCollapsed));
                }}
                className="item"
              >
                <List />
              </div>
              {/* <span className="ml-4 small text-faded text-strong">Punjab Police Portal</span> */}
            </div>
            <div className="right">{headerRight}</div>
          </div>
        </div>
        <div className="main-group">{children}</div>
      </div>
    </div>
  );
}
