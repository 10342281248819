import moment from "moment";
import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Key } from "react-bootstrap-icons";
import { IFilter, Protector } from "../../models/models";
import "./index.scss";

interface ActionButton<T> {
  title: string;
  onClick: (item: T) => void;
  type?: string;
  icon?: JSX.Element;
}

interface Props<T> {
  columns?: {
    label: string;
    key: string;
  }[];
  selectedby?: IFilter[];
  showPagesText?: boolean;
  items?: T[];
  status?: string[];
  filterUnAssign?: [{
    value: "",
    label: "",
  }],
  actionButtons: ActionButton<T>[] | ((item: T) => ActionButton<T>[]);
  totalPages?: number;
  showFilterBar?: boolean;
  onChange?: (
    query: string,
    sortKey: string,
    isSortAsc: boolean,
    page: number,
    selectKeyRow: string,
    status: string,
  ) => void;
  customColumns?: {
    heading: string;
    renderer: (item: T) => JSX.Element;
  }[];
  keepPageNum?: boolean;
}

export default function ETable<T>({
  columns = [],
  selectedby =[{
    value :"",
    label :""
  }] ,
  items = [],
  actionButtons = [],
  totalPages = 1,
  status = [],
  showFilterBar = true,
  showPagesText = true,
  customColumns = [],
  keepPageNum = false,
  onChange = (query, sortKey, isSortAsc, page, status, selectKeyRow) =>
    console.log(query + ":" + sortKey + ":" + isSortAsc.valueOf()),

}: Props<T>): ReactElement {
  const page = React.useRef(
    keepPageNum ? Number(localStorage.getItem("current_page")) || 1 : 1
  );
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("");
  const [selectKeyRow, setSelectKeyRow] = useState("");
  const [st, setSt] = useState("");
  const [jumpToPage, setJumpToPage] = useState<number>(1);

  useEffect(() => {
    const sortArr = sort.split("_");
    if (page.current <= totalPages && keepPageNum) {
      onChange(
        query,
        sortArr.slice(1).join("_"),
        sortArr[0] === "true",
        page.current,
        selectKeyRow,
        st !== "-1" ? st : "",
        
      );
    }
    if (!keepPageNum) {
      localStorage.removeItem("current_page");
    }
  }, []);
  useEffect(() => {
    keepPageNum && localStorage.setItem("current_page", `${page.current}`);
  }, [onChange]);

  return (
    <div className="e-table">
      {showFilterBar && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const sortArr = sort.split("_");
            page.current = 1;
            onChange(
              query,
              sortArr.slice(1).join("_"),
              sortArr[0] === "true",
              page.current,
              st !== "-1" ? st : "",
              selectKeyRow,
            );
          }}
          className="mb-2"
        >
          <div className="table-filters">
            <div>
              <input
                type="text"
                className="form-control search"
                aria-describedby="helpId"
                onChange={(e) => setQuery(e.currentTarget.value)}
                placeholder="Search here..."
              />
            </div>
            <div>
                <select
                  defaultValue={-1}
                  onChange={(e) => 
                    setSelectKeyRow(e.target.value)
                  }
                  className="form-control text-capitalize"
                >
                  <option value={-1} disabled>select</option>
                  {selectedby.map((each) => {
                    return (
                      <option value={each.value}>{each.label}</option>
                    )
                  })}
                </select>
            </div>
            <div>
              <select
                defaultValue={-1}
                onChange={(e) => setSort(e.currentTarget.value)}
                className="custom-select"
                name="sort"
                id="sort"
              >
                <option value={-1} disabled>
                  Sort By
                </option>
                {columns.flatMap((column, index) => {
                  return (
                    column.key.split(".").length === 1 && (
                      <React.Fragment key={index}>
                        <option value={`true_${column.key}`}>
                          {column.label} (Ascending)
                        </option>
                        <option value={`false_${column.key}`}>
                          {column.label} (Descending)
                        </option>
                      </React.Fragment>
                    )
                  );
                })}
              </select>
            </div>
            {status.length > 0 && (
              <div>
                <select
                  defaultValue={-1}
                  onChange={(e) => setSt(e.currentTarget.value)}
                  className="form-control text-capitalize"
                  name="st"
                  id="st"
                >
                  <option value={-1} disabled>
                    Status
                  </option>
                  {status.flatMap((s, index) => {
                    return (
                      <option className="text-capitalize" key={index} value={s}>
                        {s}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div>
              <button type="submit" className="btn btn-dark">
                Filter
              </button>
            </div>
          </div>
        </form>
      )}{" "}
      <table className="table has-action-buttons">
        <thead>
          <tr>
            {columns.flatMap((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
            {customColumns.flatMap((column, index) => {
              return <th key={"c_" + index}>{column.heading}</th>;
            })}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items?.flatMap((item, index) => {
            return (
              <tr key={index}>
                {columns.flatMap((column, i) => {
                  const keys = column.key.split(".");
                  let value = item[keys[0]];
                  if (keys.length > 1) {
                    for (let a = 1; a < keys.length; a++) {
                      if (value) value = value[keys[a]];
                    }
                  }
                  return <td key={i}>{value}</td>;
                })}
                {customColumns.flatMap((column, i) => (
                  <td key={"c" + i}>{column.renderer(item)}</td>
                ))}
                <td>
                  {(typeof actionButtons === "function"
                    ? actionButtons(item)
                    : actionButtons
                  ).flatMap((button, i) => (
                    <button
                      key={i}
                      type="button"
                      onClick={async (e) => {
                        e.preventDefault();
                        button.onClick(item);
                      }}
                      className={`btn btn-link mr-1 ${button.type ? "text-" + button.type : ""
                        }`}
                    >
                      {button.icon}
                      {button.title}
                    </button>
                  ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="row">
        {showPagesText && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (totalPages === 1) {
                return;
              }
              if (jumpToPage <= totalPages && jumpToPage > 0) {
                page.current = jumpToPage;
                const sortArr = sort.split("_");
                onChange(
                  query,
                  sortArr.slice(1).join("_"),
                  sortArr[0] === "true",
                  page.current,
                  st !== "-1" ? st : "",
                  selectKeyRow,
                );
              } else {
                alert("Page Number Out of Bound");
              }
            }}
            className="col-lg-6 mt-4 pt-3"
          >
            <button
              disabled={totalPages === 1}
              type="submit"
              className="btn btn-link mx-2"
            >
              Go To Page
            </button>
            <input
              type="number"
              style={{ width: "90px" }}
              max={totalPages}
              value={String(jumpToPage)}
              min={1}
              onChange={(e) => {
                setJumpToPage(Number(e.target.value));
              }}
            />
          </form>
        )}{" "}
        <div className=" col-lg-6 d-flex align-items-center justify-content-end mt-4 pt-3">
          <button
            type="button"
            onClick={() => {
              if (page.current !== 1) {
                page.current = page.current - 1;
                const sortArr = sort.split("_");
                onChange(
                  query,
                  sortArr.slice(1).join("_"),
                  sortArr[0] === "true",
                  page.current,
                  st !== "-1" ? st : "",
                  selectKeyRow,
                );
              }
            }}
            className="btn btn-link mx-2"
            disabled={page.current === 1}
          >
            Previous
          </button>
          {showPagesText && (
            <p className="mb-0">
              {page.current} of {totalPages}
            </p>
          )}
          <button
            disabled={page.current === totalPages}
            onClick={() => {
              if (page.current < totalPages) {
                page.current = page.current + 1;
                const sortArr = sort.split("_");
                onChange(
                  query,
                  sortArr.slice(1).join("_"),
                  sortArr[0] === "true",
                  page.current,
                  st !== "-1" ? st : "",
                  selectKeyRow,

                );
              }
            }}
            type="button"
            className="btn btn-link mx-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
