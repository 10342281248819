import React, { ReactElement } from "react";
import useFetch from "use-http";

interface Props {
  onClose: () => void;
  onGenerate: (selectedGroup: any) => void;
}

export default function UnitSelectModal({
  onClose,
  onGenerate,
}: Props): ReactElement {
  const { data: dataUnits } = useFetch(`items/unit`, {}, []);
  const [selectedUnit, setSelectedUnit] = React.useState<any>();
  return (
    <div>
      <div className="modal-backdrop"></div>
      <div
        className="modal"
        id="modelId"
        tabIndex={-1}
        role="dialog"
        style={{ display: "block" }}
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Unit</h5>
              <button
                type="button"
                onClick={() => onClose()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="group">Unit</label>
                <select
                  onChange={(e) => setSelectedUnit(e.target.value)}
                  className="form-control"
                  name="group"
                  id="group"
                >
                  {dataUnits?.data.flatMap((g, i) => (
                    <option key={i} value={g.id}>
                      {g.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onClose()}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => {
                  if (selectedUnit) {
                    onGenerate(selectedUnit);
                  }
                }}
                type="button"
                className="btn btn-primary"
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
