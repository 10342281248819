import React, { ReactElement } from "react";
import { CheckCircleFill, Circle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { protecteeSlice } from "../../../reducers/protectee";

interface Props {
  labels?: string[];
  contents?: ReactElement[];
  isComplete?: boolean;
  canSkip?: boolean;
}

export default function Steps({
  labels = [],
  contents = [],
  canSkip = false,
  isComplete = false,
}: Props): ReactElement {
  const currentIndex = useSelector((state:any)=>state.protectee.currentStep);
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div className="steps">
        {/* <div className="step complete"> */}
        {labels.flatMap((label, index) => (
          <div
            key={index}
            onClick={() => {
              if (canSkip) dispatch(protecteeSlice.actions.setCurrentStep(index));
            }}
            className={`step ${
              currentIndex === index
                ? "active"
                : currentIndex > index
                ? "complete"
                : ""
            } ${isComplete ? "complete" : ""}`}
          >
            <div className="icon">
              {currentIndex > index || isComplete ? (
                <CheckCircleFill />
              ) : (
                <Circle />
              )}
            </div>
            {label}
          </div>
        ))}
      </div>
      <section className="mt-5">{contents[currentIndex]}</section>
    </React.Fragment>
  );
}
