import React, { ReactElement } from "react";
import {
  PencilSquare,
  Plus,
  TrashFill,
  XCircleFill,
} from "react-bootstrap-icons";
import useFetch from "use-http";
import AddEditModal from "../../common/components/AddEditModal";
import ETable from "../../common/components/ETable/ETable";
import { IVehicle } from "../../reducers/types";
import { LoadingScreen } from "./../../common/components/LoadingScreen";

interface Props {}

export default function VehiclesPage({}: Props): ReactElement {
  const [show, setShow] = React.useState(false);
  const [showDeleteModel, setShowDeleteModel] = React.useState(false);
  const [delItemID, setDelItemID] = React.useState(String);
  const [selected, setSelected] = React.useState<IVehicle | undefined>();
  const { data, get } = useFetch<{ data: IVehicle[]; meta: any }>(
    `items/vehicle`
  );
  React.useEffect(() => {
    get("?fields=*.*&limit=10&meta=filter_count");
  }, [get]);

  const { del, post, patch } = useFetch("items/vehicle");

  if (data) {
    return (
      <React.Fragment>
        <div className="main-header d-flex justify-content-between">
          <div>
            <h1 className="mb-0">Vehicles</h1>
          </div>
          <div>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setSelected(undefined);
                setShow(true);
              }}
            >
              <Plus /> Add Vehicle
            </button>
          </div>
        </div>
        <div className="main-body">
          <div className="card h-auto">
            <div className="card-body">
              <ETable<IVehicle>
                columns={[
                  { key: "id", label: "Id" },
                  { key: "make", label: "Make" },
                  { key: "model", label: "Model" },
                  { key: "year", label: "Year" },
                  { key: "registration_number", label: "Registration No" },
                  //Identification No removed as requested by client
                  // { key: "identification_number", label: "Identification No" },
                ]}
                onChange={(query, sortKey, isSortAsc, page) => {
                  let v = `?fields=*.*&offset=${
                    (page - 1) * 10
                  }&limit=10&meta=filter_count`;
                  if (query.length > 0) {
                    v += `&filter[model][like]=${query}&offset=${
                      (page - 1) * 10
                    }`;
                  }
                  if (sortKey) {
                    v += `&sort=${isSortAsc ? "" : "-"}${sortKey}`;
                  }
                  get(v);
                }}
                customColumns={[
                  {
                    heading: "Is Bulletproof",
                    renderer: (item) => (
                      <div>
                        <input
                          disabled
                          type="checkbox"
                          checked={item.is_bulletproof}
                        />
                      </div>
                    ),
                  },
                  {
                    heading: "Status",
                    renderer: (item) => (
                      <span
                        className={`badge badge-pill ${
                          item.status === "assigned" ? "badge-success" : "badge-warning"
                        }`}
                      >
                        <span className="text-white text-uppercase">
                          {item.status}
                        </span>
                      </span>
                    ),
                  },
                ]}
                totalPages={Math.ceil((data?.meta?.filter_count || 10) / 10)}
                items={data?.data || []}
                actionButtons={[
                  {
                    title: "",
                    icon: <PencilSquare size={16} />,
                    type: "",
                    onClick: (item) => {
                      setSelected(item);
                      setShow(true);
                    },
                  },
                  {
                    title: "",
                    icon: <TrashFill size={16} />,
                    type: "danger",
                    onClick: async (item) => {
                      setShowDeleteModel(true);
                      setDelItemID(item?.id?.toString());
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {show && (
          <AddEditModal<IVehicle>
            defaultItem={selected}
            onClose={() => setShow(false)}
            title="Vehicle"
            fields={[
              {
                key: "make",
                label: "Make",
                type: "text",
              },
              {
                key: "model",
                label: "Model",
                type: "text",
              },
              {
                key: "year",
                label: "Year",
                type: "text",
              },
              {
                key: "registration_number",
                label: "Registration No",
                type: "text",
              },
              {
                key: "is_bulletproof",
                label: "Is Bulletproof",
                type: "checkbox",
              },
              //Identification No removed as requested by client
              // {
              //   key: "identification_number",
              //   label: "Identification No",
              //   type: "text",
              // },
            ]}
            onSubmit={async (vehicle, isEdit) => {
              if (isEdit) {
                const { id, ...v } = vehicle;
                await patch(id?.toString(), v);
              } else {
                await post(vehicle);
              }
              window.location.reload();
            }}
          />
        )}
        {/* Delete Confirmation Model */}
        {showDeleteModel && (
          <>
            <div className="modal-backdrop"></div>
            <div
              className="modal"
              id="modelId"
              tabIndex={-1}
              role="dialog"
              style={{ display: "block" }}
              aria-labelledby="modelTitleId"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-scrollable modal-sm"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Confirm Delete !!</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => setShowDeleteModel(false)}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to delete?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-muted"
                      onClick={() => setShowDeleteModel(false)}
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-muted"
                      onClick={async () => {
                        await del(delItemID);
                        window.location.reload();
                      }}
                      data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </React.Fragment>
    );
  } else {
    return <LoadingScreen />;
  }
}
