import { ESelect } from "../../../common/library";
import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "use-http";
import { API_URL } from "../../..";
import EImageUpload from "../../../common/components/EImageUpload/EImageUpload";
import { Category, SubCategory } from "../../../common/models/models";
import { protecteeSlice } from "../../../reducers/protectee";
import HelpIconModal from "../../../common/components/HelpIconModal";

export default function Personal(): ReactElement {
  const protectee = useSelector((state: any) => state.protectee);
  const { data: categories } = useFetch<{
    data: Category[];
  }>("/items/category", {}, []);
  const { data: subCategories, get: getSubCategories } = useFetch<{
    data: SubCategory[];
  }>("/items/sub_category");
  const { data: designations } = useFetch<{
    data: SubCategory[];
  }>("/items/designation", {}, []);
  const { data: designationFull } = useFetch<{
    data: any[];
  }>("/items/designation?fields=*.*", {}, []);
  const { data: organisations } = useFetch<{
    data: SubCategory[];
  }>("/items/organisation", {}, []);
  const { data: dataProtectee, get } = useFetch(`items/protectee`);
  React.useEffect(() => {
    get("?fields=id,name,designation.title,organisation.title,photo");
  }, [get]);
  const { data: dataDistricts } = useFetch(
    `/items/unit?filter[formation.title]=District Police`,
    {},
    []
  );
  function getCategoryTable(ds?: any[]) {
    if (!ds || ds.length === 0) return;
    const obj = {};
    const ele: JSX.Element[] = [];
    ds.forEach((d) => {
      if (obj[d.designation_group.title])
        obj[d.designation_group.title].push(d.title);
      else obj[d.designation_group.title] = [d.title];
    });
    Object.keys(obj).forEach((k, index) => {
      ele.push(
        <table key={index} className="table  mb-5 col-md-4 table-bordered">
          <tr>
            <th>{k}</th>
          </tr>
          {obj[k].flatMap((a, i) => (
            <tr key={i}>
              <td>{a}</td>
            </tr>
          ))}
        </table>
      );
    });
    return <div className="row">{ele}</div>;
  }
  const dispatch = useDispatch();
  return (
    <div className="card">
      <div className="card-body">
        {/* Security TYPE */}
        <div className="row">
          <div className="col-lg-3">
            <h2>Security Type</h2>
            <p className="text-muted">
              Please select the security type being applied for the protectee.
            </p>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <ESelect<any>
                    label="Protection Category"
                    defaultValues={
                      protectee?.personal?.type ? [protectee.personal.type] : []
                    }
                    items={categories?.data || []}
                    filterKey="title"
                    onValuesChange={(items) => {
                      if (items.length > 0 && items[0]?.id) {
                        dispatch(
                          protecteeSlice.actions.setPersonalInfo({
                            type: items[0],
                          })
                        );
                        getSubCategories(`?filter[category]=${items[0]?.id}`);
                        dispatch(
                          protecteeSlice.actions.setPersonalInfo({
                            review_after_in_months: items[0]?.id === 2 ? 6 : 0,
                          })
                        );
                        if (items[0] !== protectee.personal.type)
                          dispatch(protecteeSlice.actions.clearSubType());
                      }
                    }}
                    renderItem={(item) => <div>{item.title}</div>}
                    style={
                      !protectee.personal.type
                        ? { border: "1px solid red" }
                        : {}
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6">
                {subCategories && subCategories?.data?.length > 0 && (
                  <div className="form-group">
                    <ESelect<any>
                      label="Extra Security"
                      defaultValues={
                        protectee.personal.subType
                          ? [protectee.personal.subType]
                          : []
                      }
                      onValuesChange={(items) => {
                        if (items.length > 0 && items[0]?.id) {
                          dispatch(
                            protecteeSlice.actions.setPersonalInfo({
                              subType: items[0],
                            })
                          );
                          dispatch(
                            protecteeSlice.actions.setPersonalInfo({
                              review_after_in_months:
                                items[0]?.id === 1 ? 6 : 0,
                            })
                          );
                          if (
                            ["X", "Y", "Z"].includes(items[0].title.charAt(0))
                          )
                            dispatch(
                              protecteeSlice.actions.setPersonalInfo({
                                high_value_target: true,
                              })
                            );
                        }
                      }}
                      items={subCategories?.data || []}
                      filterKey="title"
                      renderItem={(item) => <div>{item.title}</div>}
                    />
                  </div>
                )}{" "}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                {/* <div className="form-check">
                  <input
                    checked={protectee.personal.review_after_in_months !== 0}
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setPersonalInfo({
                          review_after_in_months: e.target.checked ? 6 : 0,
                        })
                      )
                    }
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    Security review after 6 months
                  </label>
                </div> */}
              </div>
              <div className="col-lg-6">
                <div className="form-check">
                  <input
                    checked={protectee.personal.high_value_target}
                    onChange={(e) => {
                      if (
                        ["X", "Y", "Z"].includes(
                          protectee.personal.subType?.title.charAt(0)
                        )
                      )
                        dispatch(
                          protecteeSlice.actions.setPersonalInfo({
                            high_value_target: true,
                          })
                        );
                      else
                        dispatch(
                          protecteeSlice.actions.setPersonalInfo({
                            high_value_target: e.target.checked,
                          })
                        );
                    }}
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label className="form-check-label">
                    Highly Threatened Protectee
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="spacer spacer--md"></div>
        {/* GENERAL INFO */}
        <div className="row">
          <div className="col-lg-3">
            <h2>General Information</h2>
            <p className="text-muted">
              Enter personal information of the protectee. Profile picture is
              optional.
            </p>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Full Name</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setPersonalInfo({
                          full_name: e.target.value,
                        })
                      )
                    }
                    value={protectee.personal.full_name}
                    className={`form-control ${
                      !protectee.personal.full_name && "border border-danger"
                    }`}
                    placeholder="Full Name"
                  />
                </div>
                <div className="form-group">
                  <ESelect<SubCategory>
                    label="Category"
                    items={designations?.data || []}
                    onValuesChange={(items) => {
                      if (items.length && items[0]?.id)
                        dispatch(
                          protecteeSlice.actions.setPersonalInfo({
                            category: items[0],
                          })
                        );
                    }}
                    defaultValues={
                      protectee?.personal?.category
                        ? [protectee.personal.category]
                        : []
                    }
                    filterKey="title"
                    labelHelp={
                      <HelpIconModal
                        title="Category Help"
                        content={getCategoryTable(designationFull?.data)}
                      />
                    }
                    renderItem={(item) => <div>{item.title}</div>}
                    style={
                      !protectee.personal.category
                        ? { border: "1px solid red" }
                        : {}
                    }
                  />
                </div>
                <div className="form-group">
                  <ESelect<SubCategory>
                    label="Organisation"
                    items={organisations?.data || []}
                    onValuesChange={(items) => {
                      if (items.length && items[0]?.id)
                        dispatch(
                          protecteeSlice.actions.setPersonalInfo({
                            organisation: items[0],
                          })
                        );
                    }}
                    defaultValues={
                      protectee?.personal?.organisation
                        ? [protectee.personal.organisation]
                        : []
                    }
                    labelHelp={
                      <HelpIconModal
                        title="Organisation Help"
                        content={
                          <table className="table col-md-4 table-bordered">
                            {organisations?.data.flatMap((org, index) => (
                              <tr key={index}>
                                <td>{org.title}</td>
                              </tr>
                            ))}
                          </table>
                        }
                      />
                    }
                    filterKey="title"
                    renderItem={(item) => <div>{item.title}</div>}
                    style={
                      !protectee.personal.organisation
                        ? { border: "1px solid red" }
                        : {}
                    }
                  />
                </div>
                <div className="form-group">
                  <ESelect<SubCategory>
                    label="District"
                    items={dataDistricts?.data || []}
                    onValuesChange={(items) => {
                      if (items.length && items[0]?.id)
                        dispatch(
                          protecteeSlice.actions.setPersonalInfo({
                            district: items[0],
                          })
                        );
                    }}
                    defaultValues={
                      protectee?.personal?.district
                        ? [protectee.personal.district]
                        : []
                    }
                    filterKey="title"
                    renderItem={(item) => <div>{item.title}</div>}
                    style={
                      !protectee.personal.district
                        ? { border: "1px solid red" }
                        : {}
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Extra Information</label>
                  <textarea
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setPersonalInfo({
                          text: e.target.value,
                        })
                      )
                    }
                    value={protectee.personal.text || ""}
                    className="form-control"
                    style={{ height: 128 }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>Norms</label>
                  <textarea
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setPersonalInfo({
                          norms: e.target.value,
                        })
                      )
                    }
                    value={protectee.personal.norms || ""}
                    className="form-control"
                    style={{ height: 80, resize: "none" }}
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1">
                <EImageUpload
                  defaultImage={
                    protectee?.personal?.photo
                      ? API_URL + protectee?.personal?.photo
                      : undefined
                  }
                  onUpload={(url) =>
                    dispatch(
                      protecteeSlice.actions.setPersonalInfo({
                        photo: url,
                      })
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="spacer spacer--md"></div>
        {/* LINK PROTECTEE */}
        <div className="row">
          <div className="col-lg-3">
            <h2>Link to Protectee</h2>
            <p className="text-muted">
              Kindly select relation to a present proctectee. If applicable.
            </p>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-6">
                <ESelect<any>
                  label="Protectee"
                  items={dataProtectee?.data || []}
                  filterKey="name"
                  onValuesChange={(items) => {
                    if (items.length > 0 && items[0]?.id) {
                      dispatch(
                        protecteeSlice.actions.setPersonalInfo({
                          relation_protectee: items[0],
                        })
                      );
                    }
                  }}
                  onQuery={async (q, setItems) => {
                    const d = await get(
                      `?fields=id,name,designation.title,organisation.title,photo&q=${q}`
                    );
                    setItems(d?.data || []);
                  }}
                  defaultValues={
                    protectee?.personal?.relation_protectee
                      ? [protectee?.personal?.relation_protectee]
                      : []
                  }
                  renderItem={(item) => (
                    <div>
                      <img
                        width={24}
                        className="border-radius-50 mr-2"
                        src={API_URL + item.photo}
                        alt={item.name}
                      />
                      {item.name} ({item.designation?.title}-{" "}
                      {item.organisation?.title})
                    </div>
                  )}
                />
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Relation</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setPersonalInfo({
                          relation: e.target.value,
                        })
                      )
                    }
                    value={protectee.personal.relation || ""}
                    className="form-control"
                    placeholder="Relation with the protectee selected"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">
                  Currently Assigned
                </label>
                <p>
                  House Guards: 3 <br />
                  Drivers: 1 <br />
                  PSOs: 1
                </p>
              </div>
            </div> */}
              {/* <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Status</label>
                <input
                  type=""
                  className="form-control"
                  
                  placeholder="Dropdown"
                />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
