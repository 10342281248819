import { ESelect } from "../../../common/library";
import React, { ReactElement } from "react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "use-http";
import { SubCategory } from "../../../common/models/models";
import { protecteeSlice } from "../../../reducers/protectee";
import { IVehicle } from "../../../reducers/types";
import AddressesTable from "./AddressesTable";
import VehiclesTable from "./VehiclesTable";

interface Props {}

export default function Security({}: Props): ReactElement {
  const { get, data: vehicles } = useFetch("items/vehicle");
  const { data: dataDeploymentTypes } = useFetch(
    "items/deployment_type",
    {},
    []
  );

  const { data: dataDistricts } = useFetch(
    `/items/unit?filter[formation.title]=Police District`,
    {},
    []
  );

  const protectee = useSelector((state: any) => state.protectee);
  const securityInfo = useSelector((state: any) => state.protectee.security);
  const dispatch = useDispatch();
  React.useEffect(() => {
    get();
  }, [get]);
  return (
    <div className="card">
      <div className="card-body">
        {/* CONTACT INFO */}
        <div className="row">
          <div className="col-lg-3">
            <h2>Contact Information</h2>
            <p className="text-muted">
              You can add multiple addresses by clicking on '+ Add Another
              Address'
            </p>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">
                    Office Number
                  </label>
                  <input
                    defaultValue={securityInfo?.contact?.phone}
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setContactInfo({
                          phone: e.target.value,
                        })
                      )
                    }
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Mobile</label>
                  <input
                    type="text"
                    defaultValue={securityInfo?.contact?.mobile}
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setContactInfo({
                          mobile: e.target.value,
                        })
                      )
                    }
                    className="form-control"
                    placeholder="+91 0000 000 000"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    defaultValue={securityInfo?.contact?.email}
                    onChange={(e) =>
                      dispatch(
                        protecteeSlice.actions.setContactInfo({
                          email: e.target.value,
                        })
                      )
                    }
                    className="form-control"
                    placeholder="email@email.com"
                  />
                </div>
              </div>
            </div>
            <AddressesTable />
            {/* {protectee.security.addresses.flatMap((address, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-lg-6">
                    <div className="mt-4 mb-2">
                      <small className="text-bold text-uppercase text-faded">
                        Address {index + 1}
                      </small>
                    </div>
                    <div className="form-group">
                      <ESelect<SubCategory>
                        label="Address Type"
                        items={dataAddressTypes?.data || []}
                        onValuesChange={(items) => {
                          if (items.length > 0 && items[0] !== undefined)
                            dispatch(
                              protecteeSlice.actions.setAddress({
                                address: {
                                  type: items[0],
                                  address: address.address,
                                },
                                index,
                              })
                            );
                        }}
                        defaultValues={[address.address_type]}
                        filterKey="title"
                        renderItem={(item) => <div>{item.title}</div>}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">Address</label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={(e) => {
                          dispatch(
                            protecteeSlice.actions.setAddress({
                              address: {
                                type: address.type,
                                address: e.target.value,
                              },
                              index,
                            })
                          );
                        }}
                        defaultValue={address.address}
                        placeholder="123 Main Street, Mohali"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="mt-2">
              <button
                onClick={() => {
                  dispatch(protecteeSlice.actions.addAddress());
                }}
                className="btn btn-link"
              >
                + Add New Address
              </button>
            </div>*/}
          </div>
        </div>
        <div className="spacer spacer--md"></div>
        {/* VEHICLE INFO */}
        <div className="row">
          <div className="col-lg-3">
            <h2>Vehicle Information</h2>
            <p className="text-muted">
              Kindly select any vehicles which are to be allocated to the
              protectee.
            </p>
          </div>
          <div className="col-lg-9">
            <VehiclesTable />
            {/* <div className="mt-2">
            <button className="btn btn-link">+ Add New Vehicle</button>
          </div> */}
          </div>
        </div>
        <div className="spacer spacer--md"></div>
        {!protectee.isAssigned && (
          <div className="row">
            <div className="col-lg-3">
              <h2>Personnel Allocation</h2>
              <p className="text-muted">
                Kindly input the allocations of each deployment.
              </p>
            </div>
            <div className="col-lg-9">
              <div className="row mb-4">
                <div className="col-lg-4">
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="area_security"
                        id="area_security"
                        defaultChecked={protectee.security.area_security}
                        onChange={(e) => {
                          dispatch(
                            protecteeSlice.actions.setAreaSecurity(
                              e.target.checked
                            )
                          );
                        }}
                      />
                      Area Security
                    </label>
                  </div>
                </div>
                {protectee.security.area_security && (
                  <div className="col-lg-6">
                    <ESelect
                      filterKey="title"
                      items={dataDistricts?.data || []}
                      label="District"
                      defaultValues={
                        protectee.security.area_security_district
                          ? [protectee.security.area_security_district]
                          : []
                      }
                      renderItem={(item) => <div>{item.title}</div>}
                      onValuesChange={(items) => {
                        if (items[0]?.id) {
                          dispatch(
                            protecteeSlice.actions.setAreaSecurityDistrict(
                              items[0]
                            )
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              {!protectee.security.area_security && (
                <div className="row">
                  {dataDeploymentTypes?.data.flatMap((type, index) => (
                    <div
                      key={index}
                      className={`text-center col-${
                        12 / dataDeploymentTypes?.data.length
                      }`}
                    >
                      <h4>{type.title}</h4>
                      <input
                        className="form-control mt-4"
                        disabled={protectee.status !== "personal"}
                        onChange={(e) =>
                          dispatch(
                            protecteeSlice.actions.setPersonnelAllocation({
                              ...securityInfo.personnel_allocation,
                              [type.id]: Number(e.target.value),
                            })
                          )
                        }
                        value={
                          securityInfo.personnel_allocation?.[type.id] || 0
                        }
                        style={{
                          width: "100%",
                          height: 64,
                          margin: "auto",
                          fontSize: 24,
                          textAlign: "center",
                        }}
                        type="text"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
